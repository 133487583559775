import {
	Directive,
	forwardRef
} from '@angular/core';
import {
	NG_VALIDATORS,
	AbstractControl,
	ValidationErrors
} from '@angular/forms';

import { validateIPv4Multicast } from 'rev-shared/util/IPAddressValidation';

@Directive({
	selector: '[vbMulticastIpAddress]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbMulticastIpAddressDirective),
		multi: true
	}]
})
export class VbMulticastIpAddressDirective {

	public validate(control: AbstractControl): ValidationErrors {
		if (!control) {
			return null;
		}

		return validateIPv4Multicast(control.value) ? null : { vbMulticastIpAddress: true };
	}
}
