import { ApplicationRef, Injectable } from '@angular/core';
import { Device, debug } from 'mediasoup-client';
import { Consumer, Producer } from 'mediasoup-client/lib/types';
import createIoPromise from 'socket.io-promise';
import io from 'socket.io-client';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { promiseFromTimeout, retryUntilSuccess } from 'rev-shared/util/PromiseUtil';
import { getBgImgUrl } from 'rev-shared/webcast/webcastView/producer/Producer.Service';

import { IListenerConnection } from './Contract';
import { ListenerPresenters } from './ListenerPresenters';
import { replaceTrack } from './mediaSoupUtil';

debug.enable('mediasoup*');

@Injectable({
	providedIn: 'root'
})
export class WebRtcListenerConnectionService {
	private connection: IListenerConnection;
	public presenters: ListenerPresenters;
	private peerId: string;

	constructor(
		private readonly http: HttpClient,
		private readonly UserContext: UserContextService,
		private readonly ApplicationRef: ApplicationRef
	) { }

	public initializeConnection(id: string, listenerUrl: string, muted?: boolean) : Promise<void> {
		console.log('ListenerConnection init', id, listenerUrl);
		this.peerId = this.UserContext.getUser().id;

		return this.getToken(id)
			.then(({ token }) => {
				this.connection = {
					device: new Device(),
					listenerUrl,
					token
				};
				return this.startSocket();
			})
			.then(() => this.joinEvent({ muted }))
			.then(() => this.startTransport())
			.then(() => this.startReceiveTransport())
			.then(() => this.presenters?.initialize());
	}

	public stop(stopRecording: boolean): void {
		if(!this.connection) {
			return;
		}

		this.presenters?.stop();

		Promise.resolve(stopRecording && this.stopRecording())
			.catch(e => console.error('Stop recording, error: ', e))
			.finally(() => {
				this.connection.transport?.close();
				this.connection.socket?.disconnect();
				this.connection = null;
				this.peerId = null;
			});
	}

	private joinEvent(opts?: { muted: boolean }): Promise<any> {
		return this.sendListenerCommand('JOIN', { ...opts })
			.then(({ rtpCapabilities, presenters }) => {
				if(presenters) {
					this.presenters = new ListenerPresenters(presenters,
						this.UserContext.getUser().id,
						this,
						this.ApplicationRef);
				}
				this.connection.device.load({ routerRtpCapabilities: rtpCapabilities });
			});
	}

	private startTransport(): Promise<any> {
		return this.sendListenerCommand('START_TRANSPORT', { appData: { producing: true } })
			.then(options => {
				const transport = this.connection.transport = this.connection.device.createSendTransport(options);

				transport.on('connect', ({ dtlsParameters }, cb, err) => {
					this.sendListenerCommand('CONNECT_TRANSPORT', {
						dtlsParameters,
						transportId: transport.id
					})
						.then(cb, err);
				});

				transport.on('produce', (produceParameters, cb, err) => {
					this.sendListenerCommand('CREATE_PRODUCER', {
						produceParameters
					})
						.then(cb, err);
				});

				transport.on('connectionstatechange', e => {
					console.log('transport connection state changed.', e);
				});

				return transport;
			});
	}

	public startReceiveTransport(): Promise<any> {
		return this.sendListenerCommand('START_TRANSPORT', { appData: { consuming: true } })
			.then(options => {
				const transport = this.connection.receiveTransport = this.connection.device.createRecvTransport(options);

				transport.on('connect', ({ dtlsParameters }, cb, err) => {
					this.sendListenerCommand('CONNECT_TRANSPORT', {
						dtlsParameters,
						transportId: transport.id
					})
						.then(cb, err);
				});

				transport.on('connectionstatechange', e => {
					console.log('Recieve transport connection state changed.', e);
				});

				return transport;
			});
	}

	private startSocket(): Promise<void> {
		const { listenerUrl, token } = this.connection;
		return retryUntilSuccess(
			() => this.connectSocket(listenerUrl, token),
			60,
			null,
			() => !!this.connection)
			.then(socket => {
				this.connection = {
					...this.connection,
					token,
					socket,
					device: new Device(),
					streamProducers: []
				};
			});
	}

	private connectSocket(url: string, token: string): Promise<SocketIOClient.Socket> {
		console.log('Connect Socket: ', url);
		if(!url || ! token) {
			return Promise.reject('Cannot connect socket');
		}

		const socket = io(url, {
			path: '/server',
			transports: ['websocket'],
			query: { peerId: this.peerId },
			auth: { token: `bearer ${token}` },
			reconnection: false
		});

		let unauthorized = false;

		const errHandler = (e: any) => {
			if (e.message?.includes('401')) {
				unauthorized = true;
			}
		};
		socket.on('connect_error', errHandler);

		let retries = 2;

		const retryConnect: () => Promise<any> = () => {
			if(unauthorized) {
				return Promise.reject('websocket connection unauthorized');
			}
			if(socket.connected) {
				socket.off('connect_error', errHandler);
				socket.on('connect_error', e => console.error('listener socket connect_error', e));
				socket.io.reconnection(true);
				console.log('Connect Socket complete');
				return Promise.resolve(socket);
			}
			if(retries--) {
				return promiseFromTimeout(5000).then(poll);
			}

			return Promise.reject('websocket connect timeout');
		};

		const poll = () => retryConnect()
			.catch(e =>{
				try {
					console.error('Connect Socket error: ', e);
					socket.disconnect();
				} catch(_) {/**/}
				return Promise.reject(e);
			});

		return poll();
	}

	private sendListenerCommand(type: string, data?: any): Promise<any> {
		if(!this.connection?.socket?.connected) {
			return Promise.reject('Listener socket not connected');
		}

		console.log('Sending Listener Command: ', type, data);
		return createIoPromise(this.connection.socket)({
			type,
			peerId: this.peerId,
			...data
		})
			.then((response: any) => response.success ?
				response.data :
				Promise.reject(response.error || 'ListenerError'));
	}

	public produceVideo(stream: MediaStream, appData: any): Promise<Producer> {
		const transport = this.connection.transport;
		const track = stream.getVideoTracks()[0];
		if(!track) {
			return Promise.reject('produceVideo, not a video stream');
		}
		if(track.readyState === 'ended') {
			return Promise.reject('produceVideo(): Track Ended');
		}

		return transport.produce({
			appData,
			track,
			encodings: [
				{ maxBitrate: 2000000 },
			],
			codecOptions: {
				videoGoogleStartBitrate: 100000,
			}
		})
			.then(producer => {
				this.connection.streamProducers.push(producer);
				return producer;
			});
	}

	public produceAudio(track: MediaStreamTrack, appData: any = undefined): Promise<Producer> {
		const transport = this.connection.transport;
		return transport.produce({ track, appData })
			.then(producer => {
				this.connection.streamProducers.push(producer);
				return producer;
			});
	}

	public createConsumer(producerPeerId: string, producerId: string): Promise<Consumer> {
		return this.sendListenerCommand('CREATE_CONSUMER', {
			peerId: this.peerId,
			producerId,
			producerPeerId,
			consumerRtpCapabilities: this.connection.device.rtpCapabilities
		})
			.then(consumerResponse => {
				console.log('CREATE_CONSUMER', consumerResponse);
				return this.connection.receiveTransport.consume(consumerResponse);
			});

	}

	public stopProducer(producer: Producer): Promise<any> {
		if(!producer) {
			return Promise.resolve();
		}

		producer.close();
		return this.sendListenerCommand('STOP_PRODUCER', { producerId: producer.id });
	}

	public resumeConsumer(consumerId: string): Promise<any> {
		return this.sendListenerCommand('RESUME_CONSUMER', { consumerId });
	}

	public startRecording(opts?: any): Promise<any> {
		return this.sendListenerCommand('START_RECORDING', { opts });
	}

	public stopRecording(): Promise<any> {
		return this.sendListenerCommand('STOP_RECORDING');
	}

	public startBroadcasting(): Promise<any> {
		return this.sendListenerCommand('START_BROADCASTING');
	}

	public stopBroadcasting(): Promise<any> {
		return this.sendListenerCommand('STOP_BROADCASTING');
	}

	public sendLive(opts: any): Promise<any> {
		return this.sendListenerCommand('SEND_LIVE', { opts });
	}

	public updatePreviewStreams(producerIds: string[]): Promise<any> {
		return this.sendListenerCommand('UPDATE_PREVIEW_STREAMS', { producerIds });
	}

	public toggleMutePresenter(mute: boolean): Promise<any> {
		return this.sendListenerCommand(mute ? 'MUTE' : 'UNMUTE');
	}

	public replaceTrack(p: Producer, track: MediaStreamTrack, streamType: string): Promise<void> {
		console.log('replacing track', streamType);
		return replaceTrack(p, track)
			.then(replaced => replaced && streamType !== 'audio' && this.sendListenerCommand('REQUEST_KEYFRAME', { streamType }));
	}

	public stopContent(contentProducer: Producer): Promise<any> {
		this.connection.streamProducers = this.connection.streamProducers.filter(p => p !== contentProducer);
		//todo: replace track if changing screen
		return this.sendListenerCommand('STOP_CONTENT')
			.then(() => contentProducer?.close());
	}

	public isConnected(): boolean {
		return this.connection?.socket?.connected;
	}

	public uploadBackgroundImage(backgroundId: string): Promise<any> {
		const headers = {
			Authorization: `bearer ${this.connection.token}`
		};

		return fetch(getBgImgUrl(backgroundId)).then(response => {
			return response.blob();
		}).then(blob => {
			const formData = new FormData();
			formData.append('peerId', this.peerId);
			formData.append('image', blob, backgroundId);
			return lastValueFrom(this.http.post(`${this.connection.listenerUrl}upload/bg-image`, formData, { headers }));
		});
	}

	private getToken(id: string): Promise<any>{
		return lastValueFrom(this.http.get(`/scheduled-events/${id}/listener-auth-token`));
	}

	public onSocket(event: string, fn: (any) => void): void {
		this.connection.socket.on(event, data => setTimeout(() => fn(data)));
	}
}
