import { NgModule } from '@angular/core';
import { NgxTrimDirective } from './ngx-trim.directive';

@NgModule({
  imports: [],
  declarations: [NgxTrimDirective],
  exports: [NgxTrimDirective],
})
export class NgxTrimDirectiveModule {
}
