import {
	Component,
	Input,
	forwardRef
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator } from '@angular/forms';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { getDimensions } from 'rev-shared/util/ImageUtils';

import { IImageComponentMetadata, IImageData } from './ImageSelector.Contract';

import styles from './image-selector.module.less';

let nextId = 1;

@Component({
	selector: 'image-selector',
	templateUrl: './ImageSelector.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => ImageSelectorComponent),
		multi: true
	}, {
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => ImageSelectorComponent),
		multi: true
	}]
})
export class ImageSelectorComponent extends BaseControlValueAccessor<IImageData> implements Validator {
	@Input() public defaultImage: {name: string; url: string};
	@Input() public imageComponentMetadata: IImageComponentMetadata;
	@Input() public label: string;

	public readonly styles = styles;
	public readonly labelId = `image-selector-${nextId++}`;

	private processing: boolean;
	public control: FormControl;

	public get labelClass(): string {
		return this.label ? `${styles.requiredCt}` : `${styles.requiredCt} ${styles.requiredIndicator}`;
	}

	public writeValue(val: IImageData): void {
		super.writeValue(!val?.url ? null : {
			name: val.name || 'image',
			url: val.url
		});
	}

	public validate(ctrl: FormControl): any {
		if(!(ctrl as any).dummy) {
			this.control = ctrl; //Form group directive calls validator with dummy ctrl
		}

		const imgData: IImageData = ctrl.value;
		const fileWrapper = imgData?.fileWrapper;

		const err = {
			required: this.imageComponentMetadata.requiredValidation && !imgData?.url,
			fileType: fileWrapper && !fileWrapper.isImageFile,
			size: fileWrapper && !this.isSizeValid(fileWrapper)
		};
		return (err.required || err.fileType || err.size) ? err : null;
	}

	public clearSelectedImage(): void {
		this.updateModelValue(null);
	}

	public setDefaultImage(): void {
		this.updateModelValue(null);
		this.value = {
			name: this.defaultImage.name,
			url: this.defaultImage.url
		};
	}

	public setImageFile(file: FileWrapper): void {
		this.processing = true;
		this.getImageData(file)
			.then(imageData => this.updateModelValue(imageData))
			.catch(e => console.log('ImageSelector error: ', e))
			.finally(() => this.processing = false);
	}

	private getImageData(file: FileWrapper): Promise<IImageData> {
		const result: IImageData = {
			fileWrapper: file,
			name: 'image'
		};

		if(!file?.isImageFile || !this.isSizeValid(file)){
			return Promise.resolve(result);
		}

		return file.getImageUrl()
			.then(url => getDimensions(url)
				.then(dimensions => ({
					...result,
					...dimensions,
					url
				})));
	}

	private isSizeValid(file: FileWrapper): boolean {
		return !this.imageComponentMetadata.sizeValidation ||
			file.size <= this.imageComponentMetadata.maxSize;
	}

	public get isFileDisabled(): boolean {
		return this.processing || this.isDisabled;
	}

	public get url(): string {
		return this.value ? this.value.url : this.defaultImage?.url;
	}

	public get isDefaultImageSelected(): boolean {
		return this.value?.url === this.defaultImage?.url;
	}
}
