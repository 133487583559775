import { Directive, HostBinding, Input } from '@angular/core';

import styles from './VbUiBtnNgx.Directive.module.less';

@Directive({
	selector: '[vbUiBtnSecondaryNgx]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiBtnSecondaryNgxDirective {
	@HostBinding('class.btnSecondaryInactive')
	@Input() public inactive: boolean; //inactive is not disabled.

	public readonly hostClass = `${styles.vbUiBtn} ${styles.btnSecondary} theme-primary-font-border-fade-30 theme-primary theme-primary-txt-important`;
}
