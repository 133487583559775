import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'alert-dialog',
	templateUrl: './AlertDialogAngular.Component.html'
})

export class AlertDialogAngularComponent {
	@Input() public title: string;
	@Input() public message: string;
	@Input() public okButtonText: string;

	public result: Promise<void>;

	constructor(
		private BsModalRef: BsModalRef,
	) {}

	public hide(): void {
		this.BsModalRef.hide();
	}
}
