export const CsrfTokenCookie = 'csrfToken';
export const CsrfTokenHeader = 'Csrf-Token';
export const CsrtTokenQueryParam = 'csrfToken';
export const TokenQueryParam = 'tk';

import { LegacyCookieSuffix, getCookie } from 'rev-shared/util/CookieUtil';

export function getXsrfCookieName(): string {
	const useFallback = !getCookie(CsrfTokenCookie);

	return useFallback ?
		CsrfTokenCookie + LegacyCookieSuffix :
		CsrfTokenCookie;
}
