
import { AttendeeJoinMethod, EventAccessControl } from '../EventAccessControl';
import { WebcastQuestionOption, WebcastStatus } from './WebcastStatus';
import { WebcastVideoSource } from './WebcastVideoSource';
import { DeviceType } from 'rev-portal/admin/devices/deviceManagement/DeviceContract';
import { IPreProduction } from './Webcast.Contract';
import { IVideoField } from 'rev-shared/metadata/Metadata.Contract';

export class BaseWebcast {
	public id: string;
	public accountId: string;
	public status: WebcastStatus;

	public title: string;
	public shortcutName: string;
	public description: string;
	public startDate: Date;
	public endDate: Date;
	public timezoneId: string;
	public lobbyTimeMinutes: number;
	public categoryIds: string[];
	public tags: string[];
	public customFields: IVideoField[] = [];

	public accessControl: EventAccessControl;
	public attendeeJoinMethod: AttendeeJoinMethod;
	public password: string;
	public estimatedAttendees: number;
	public unlisted: boolean;
	public videoSource: WebcastVideoSource;
	public presentationProfileId: string;
	public presentationProfileDeviceId: string;
	public presentationProfileDeviceActive: boolean;
	public deviceType: DeviceType;
	public vcSipAddress: string;
	public vcMicrosoftTeamsMeetingUrl: string;
	public webexTeam: any;
	public automatedWebcast: boolean;
	public autoplay: boolean;
	public closedCaptionsEnabled: boolean;
	public autoAssociateVod: boolean;
	public recordingUploaderUserId: string;
	public redirectVod: boolean;

	public eventAdminIds: string[];
	public moderatorIds: string[];
	public userIdSlideControl: string;
	public groupIds: string[];
	public userIds: string[];

	public chatEnabled: boolean;
	public pollsEnabled: boolean;
	public userPollsResponse: string;
	public questionAndAnswerEnabled: boolean;
	public questionOption: WebcastQuestionOption;

	public thumbnailUri: string;
	public imageId: string;
	public isBackgroundFill: boolean;

	public presentationFile?: { name: string };
	public presentationFileDownloadAllowed: boolean;

	public preProduction: IPreProduction;
	public vodId: string;
}
