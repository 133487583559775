import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { VbTagsInputWhitelistCfg } from 'rev-shared/ui/ngTagsInput/Ngx/VbTagsInput.Component';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { SparkService } from './Spark.Service';

@Component({
	selector: 'video-share-spark',
	templateUrl: './VideoShareSpark.Component.html'
})
export class VideoShareSparkComponent implements OnDestroy, OnInit {
	@Input() public title: string;
	@Input() public videoUrl: string;

	private sparkOnAuthenticateUnsubscribe: IUnsubscribe;
	public rooms: any[];
	public whitelistCfg: VbTagsInputWhitelistCfg;
	public selectedRooms: any[];
	public sparkAuthenticationRequired: boolean;
	public message: string;
	public status: any;

	constructor(
		private SparkService: SparkService,
		private modalInstance: BsModalRef
	) { }

	public ngOnInit(): void {
		this.selectedRooms = [];

		this.loadRooms();
	}

	public ngOnDestroy(): void {
		if (this.sparkOnAuthenticateUnsubscribe) {
			this.sparkOnAuthenticateUnsubscribe();
		}
	}

	private loadRooms(isRetry: boolean = false): Promise<any> {
		this.status = { processing: true };
		this.sparkAuthenticationRequired = false;

		return (isRetry ?
			retryUntilSuccess(() => Promise.resolve(this.SparkService.getRooms())) :
			Promise.resolve(this.SparkService.getRooms())
		)
			.then(rooms => {
				this.status = { active: true };
				this.rooms = rooms;
				this.whitelistCfg = {
					items: this.rooms,
					itemIdKey: 'id',
					itemTitleKey: 'title'
				};
			})
			.catch(err => {
				if (err.authenticationRequired && !isRetry) {
					this.status = { active: true };
					this.sparkAuthenticationRequired = true;

					this.sparkOnAuthenticateUnsubscribe = this.SparkService.onAuthenticate(() => this.loadRooms(true));
				} else {
					this.status = {
						error: true,
						loadRoomsError: true
					};
				}
			});
	}

	public submit(): Promise<any> {
		this.status = { processing: true };

		return this.SparkService.shareVideo({
			roomIds: this.selectedRooms.map(room => room.id),
			title: this.title,
			videoUrl: this.videoUrl,
			message: this.message
		})
			.then(() => this.close())
			.catch(() => {
				this.status = {
					error: true,
					saveError: true
				};
			});
	}

	public close(): void {
		this.modalInstance.hide();
	}

}
