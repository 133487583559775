import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { distinct, filter, map, debounceTime } from 'rxjs/operators';

import { noop, sortBy } from 'rev-shared/util';

import { VbUiCheckboxState } from './VbUiCheckbox.Component';

import styles from './VbUiCheckboxGroup.Component.module.less';

export interface IVbUiCheckboxGroupOption {
	id: string;
	label: string;
}

interface IVbUiCheckboxGroupOptionInternal extends IVbUiCheckboxGroupOption {
	isChecked: boolean;
	isHidden: boolean;
}

/**
 * A configuration-driven grouping of VbUiCheckbox components.
 * Optionally may show a top-level tri-state checkbox for the group.
 * Also an optional expander for surfacing of the group's checkboxes.
 * Supports filtering of the displayed checkboxes by string input matching against their labels. Matches are highlighted.
 */
@Component({
	selector: 'vb-ui-checkbox-group',
	templateUrl: './VbUiCheckboxGroup.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbUiCheckboxGroupComponent),
			multi: true
		}
	]
})
export class VbUiCheckboxGroupComponent implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy {
	@Input() public filterMinLength: number = 1;
	@Input() public filterText: string;
	@Input() public groupLabel: string;
	@Input() public isDefaultExpanded: boolean;
	@Input() public disableOptionsSort: boolean = false;
	@Input() public options: IVbUiCheckboxGroupOption[];
	@Input() public showExpander: boolean;
	@Input() public showGroupCheckbox: boolean;

	@ViewChild(NgForm) private form: NgForm;

	public isAllOptionsHidden: boolean;
	public isCollapsed: boolean;
	public isDisabled: boolean;
	public internalModel: IVbUiCheckboxGroupOptionInternal[];
	public readonly styles = styles;

	private onChangeCallback: (value: any) => void = noop;
	private onTouchedCallback: () => void = noop;
	private selectedIds: string[];
	private sortedOptions: IVbUiCheckboxGroupOption[];
	private formValueChangesSubscription: Subscription;

	public ngAfterViewInit(): void {
		this.isCollapsed = !this.isDefaultExpanded;

		this.formValueChangesSubscription = this.form.valueChanges
			.pipe(
				filter(values => Object.keys(values).length === this.options.length), // filter out changes during render
				map(values => this.getSelectedValues(values)),
				distinct(),
				debounceTime(100)
			)
			.subscribe(value => this.onChangeInternal(value));
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.options) {
			this.onOptionsChange();
		}

		if (changes.filterText) {
			this.onFilterTextChange();
		}
	}

	public ngOnDestroy(): void {
		this.formValueChangesSubscription?.unsubscribe();
	}

	public onTouched(): void {
		this.onTouchedCallback();
	}

	public get highlightMatch(): string {
		return this.filterText?.length >= this.filterMinLength ?
			this.filterText :
			'';
	}

	public get isGroupChecked(): VbUiCheckboxState {
		const optionsLength: number = this.options?.length;
		const selectedIdsLength: number = this.selectedIds?.length;

		if (selectedIdsLength && selectedIdsLength < optionsLength) {
			return VbUiCheckboxState.MIXED;
		}

		return selectedIdsLength === optionsLength ?
			VbUiCheckboxState.CHECKED :
			VbUiCheckboxState.UNCHECKED;
	}

	public set isGroupChecked(state: VbUiCheckboxState) {
		state === VbUiCheckboxState.CHECKED ?
			this.checkAll() :
			this.uncheckAll();
	}

	public onChangeInternal(values: string[]): void {
		if (this.checkForModelChange(values)) {
			this.selectedIds = values;

			this.onChangeCallback(values);
		}
	}

	public onExpanderClick(): void {
		this.isCollapsed = !this.isCollapsed;
	}

	public registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public writeValue(value: any): void {
		if (!this.checkForModelChange(value)) {
			return;
		}

		this.selectedIds = Array.isArray(value) ?
			value :
			[];

		this.updatedInternalModel();
	}

	private getSelectedValues(formValues: { [key: string]: boolean }) {
		return Object.entries(formValues)
			.filter(([, value]) => value)
			.map(([key]) => key);
	}

	private checkAll(): void {
		const allIds: string[] = this.options.map(option => option.id);

		this.internalModel.forEach(option => option.isChecked = true);

		this.onChangeInternal(allIds);
	}

	private checkForModelChange(values: string[]): boolean {
		if (!this.selectedIds) {
			return true;
		}

		const selectedIdsSet = new Set(this.selectedIds);

		return values.length !== this.selectedIds.length ||
			values.some(value => !selectedIdsSet.has(value));
	}

	private isOptionHidden(option: IVbUiCheckboxGroupOption): boolean {
		return this.filterText?.length >= this.filterMinLength &&
			!option.label.match(new RegExp(this.filterText, 'i'));
	}

	private onFilterTextChange(): void {
		if (!this.internalModel) {
			return;
		}

		let isAllOptionsHidden: boolean = true;

		this.internalModel.forEach(option => {
			option.isHidden = this.isOptionHidden(option);

			isAllOptionsHidden = isAllOptionsHidden && option.isHidden;
		});

		this.isAllOptionsHidden = isAllOptionsHidden;

		if (!isAllOptionsHidden) {
			this.isCollapsed = false;
		}
	}

	private onOptionsChange(): void {
		this.sortedOptions = this.disableOptionsSort ? this.options : sortBy(this.options, 'label');

		this.updatedInternalModel();
	}

	private uncheckAll(): void {
		this.internalModel.forEach(option => option.isChecked = false);
		this.onChangeInternal([]);
	}

	private updatedInternalModel(): void {
		this.internalModel = (this.sortedOptions || [])
			.map(option => ({
				...option,
				isChecked: this.selectedIds?.includes(option.id) ?? false,
				isHidden: false
			}));
	}
}
