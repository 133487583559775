export class Deferred<T = any> {
	public promise: Promise<T>;
	public reject: (reason?: unknown) => void;
	public resolve: (value?: unknown) => void;

	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.reject = reject;
			this.resolve = resolve;
		});
	}
}
