<form [attr.aria-label]="'UI_InlineEdit' | translate" layout="row" layout-wrap="false" name="form" #form="ngForm" (submit)="submit()">

	<div vbUiRadioBtnGroup>
		<button type="button" name="trueBtn" [(ngModel)]="internalValue" [vbUiRadioBtn]="true">
			{{ trueLabel || ('UI_Enabled' | translate) }}
		</button>
		<button type="button" name="falseBtn" [(ngModel)]="internalValue" [vbUiRadioBtn]="false">
			{{ falseLabel || ('UI_Disabled' | translate) }}
		</button>
	</div>

	<button class="margin-left-5" [title]="'Apply' | translate" [attr.aria-label]="'Apply' | translate" type="submit" [disabled]="form.invalid" vbUiBtnPrimaryNgx>
		<span class="glyphicons ok_2"></span>
	</button>

	<button class="margin-left-5" [title]="'Discard' | translate" [attr.aria-label]="'Discard' | translate" type="button" (click)="cancel()" vbUiBtnSecondaryNgx>
		<span class="glyphicons remove_2"></span>
	</button>
</form>
