import { distinctUntilChanged, map } from 'rxjs/operators';
import { WebcastVideoSource } from '../WebcastVideoSource';

import { WebcastAttendeeType } from './WebcastAttendeeType';
import { WebcastModel } from './WebcastModel';

interface IWebcastUserCfg {
	id: string;
	canEditEvents: boolean;
	canJoinPreProduction: boolean;
	canJoinMainEvent: boolean;
	canViewReports: boolean;
	isAccountAdmin: boolean;
	disableAdmin: boolean;
	allowRemoveAttendees: boolean;
}

export class WebcastUser {
	public id: string;
	public sessionId: string;
	private adminDisabled: boolean;
	private moderatorDisabled: boolean;
	private canEditDisabled: boolean;

	public readonly canViewReports: boolean;
	public readonly removeAttendees: boolean;
	private readonly _hasPermissionJoinPreProduction: boolean;
	private readonly _hasPermissionJoinMainEvent: boolean;
	private readonly _hasPermissionEditAccountEvents: boolean;
	private readonly _isAccountAdmin: boolean;

	private _isRemovedFromWebcast: boolean = false;
	private _sessionTerminated: boolean;

	constructor(
		private webcast: WebcastModel,
		cfg: IWebcastUserCfg,
	) {
		this.id = cfg.id;
		this.canViewReports = cfg.canViewReports;
		this._hasPermissionJoinMainEvent = cfg.canJoinMainEvent;
		this.adminDisabled = cfg.disableAdmin;

		if(!cfg.disableAdmin) {
			this._hasPermissionJoinPreProduction = cfg.canJoinPreProduction;
			this._hasPermissionEditAccountEvents = cfg.canEditEvents;
			this._isAccountAdmin = cfg.isAccountAdmin;
			this.removeAttendees = cfg.allowRemoveAttendees;
		}
	}

	public get attendeeType(): WebcastAttendeeType {
		if (this.isAssignedEventAdmin) {
			return WebcastAttendeeType.Attendee;
		}

		if (this._isAccountAdmin) {
			return WebcastAttendeeType.AccountAdmin;
		}

		if (this.isEventModerator) {
			return WebcastAttendeeType.Moderator;
		}

		return WebcastAttendeeType.Attendee;
	}

	public get canControlEventStatus(): boolean {
		return !this.webcast.isAutomated && this.isEventAdmin;
	}

	public get canJoin(): boolean {
		return this.webcast.isReadyToStart && this.allowJoin;
	}

	public get allowJoin(): boolean {
		return !this.isRemovedFromWebcast && (
			this.canEdit ||
			this._hasPermissionJoinMainEvent
		);
	}

	public get canJoinPreProduction(): boolean {
		return this.webcast.isReadyToStartPreProduction && this.allowJoinPreProduction;
	}

	public get allowJoinPreProduction() {
		return this.webcast.preProduction && (
			this.canEdit ||
			this.isEventModerator ||
			this._hasPermissionJoinPreProduction
		) &&
		!this._isRemovedFromWebcast;
	}

	public get canEdit(): boolean {
		return !this.canEditDisabled && (this.isEventAdmin || this._hasPermissionEditAccountEvents);
	}

	public set canEdit(val: boolean) {
		this.canEditDisabled = !val;
	}


	public get isAssignedEventAdmin(): boolean {
		return this.webcast.eventAdminIds.includes(this.id);
	}

	public isEventAdmin$ = this.webcast.webcast$.pipe(
		map(() => this.isEventAdmin),
		distinctUntilChanged()
	);

	public get isEventAdmin(): boolean {
		return !this.adminDisabled && (this._isAccountAdmin || this.isAssignedEventAdmin);
	}

	public set isEventAdmin(val: boolean) {
		this.adminDisabled = !val;
	}

	public isEventModerator$ = this.webcast.webcast$.pipe(
		map(() => this.isEventModerator),
		distinctUntilChanged()
	);

	public get isEventModerator(): boolean{
		return !this.moderatorDisabled &&
			(this.webcast.moderatorIds.includes(this.id) ||
			this.webcast.presenterId === this.id ||
			this.webcast.presenterIds?.includes(this.id));
	}

	public set isEventModerator(val: boolean) {
		this.moderatorDisabled = !val;
	}

	public isEventAdminOrModerator$ = this.webcast.webcast$.pipe(
		map(() => this.isEventAdminOrModerator),
		distinctUntilChanged()
	);

	public get isEventAdminOrModerator(): boolean {
		return this.isEventAdmin || this.isEventModerator;
	}

	public get canViewAttendees(): boolean {
		return this.isEventAdminOrModerator;
	}

	public get canInviteOthers(): boolean {
		return (this.isEventAdminOrModerator || this._hasPermissionEditAccountEvents);
	}

	public get canManageQuestions(): boolean {
		return this.isEventAdminOrModerator;
	}

	public get canDownloadChat(): boolean {
		return (this.isEventAdminOrModerator || this._hasPermissionEditAccountEvents) && this.webcast.isChatEnabled();
	}

	public get canDownloadQuestions(): boolean {
		return (this.isEventAdminOrModerator || this._hasPermissionEditAccountEvents) && this.webcast.isQuestionAndAnswerEnabled();
	}

	public get canDownloadPolls(): boolean {
		return (this.isEventAdminOrModerator || this._hasPermissionEditAccountEvents) && this.webcast.isPollsEnabled();
	}

	public get canDownloadAttendees(): boolean {
		return (this.isEventAdminOrModerator || this._hasPermissionEditAccountEvents);
	}

	public get canManagePolls(): boolean {
		return this.isEventAdminOrModerator;
	}

	public get canControlPresentationSlides(): boolean {
		if (this.webcast.userIdSlideControl){
			return this.isEventModerator && this.webcast.userIdSlideControl === this.id;
		}

		return this.isEventAdmin;
	}

	public get isRemovedFromWebcast(): boolean {
		return this._isRemovedFromWebcast;
	}

	public set isRemovedFromWebcast(isRemoved: boolean) {
		this._isRemovedFromWebcast = isRemoved;
	}

	public get isSessionTerminated(): boolean {
		return this._sessionTerminated;
	}

	public set isSessionTerminated(terminated: boolean) {
		this._sessionTerminated = terminated;
	}

	public get canViewDownloadButton(): boolean {
		return this.canDownloadChat || this.canDownloadQuestions || this.canDownloadPolls || this.canDownloadAttendees;
	}

	public get isSinglePresenter(): boolean {
		return this.webcast.presenterId === this.id;
	}

	public get isPresenter(): boolean {
		return this.webcast.videoSource === WebcastVideoSource.PRODUCER && (this.isAssignedEventAdmin || this.webcast.presenterIds?.includes(this.id));
	}
}
