import { Directive, Input, OnInit } from '@angular/core';

import styles from './VbUiBtnNgx.Directive.module.less';

@Directive({
	selector: '[vbUiBtnPrimaryNgx]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiBtnPrimaryNgxDirective implements OnInit{
	@Input() public ignoreBtnPrimaryNgx:boolean;

	public hostClass;

	public ngOnInit() {
		if (this.ignoreBtnPrimaryNgx) {
			return;
		   }

		 this.hostClass = `${styles.vbUiBtn} ${styles.btnPrimary} theme-accent theme-accent-font-border`;
	}

}
