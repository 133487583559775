import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClearOnChangeDirective } from './VbClearOnChange.AngularDirective';
import { ProhibitPublicAccessValidatorDirective } from './ProhibitPublicAccessValidator.Directive';
import { VbColorHexDirective } from './VbColorHex.AngularDirective';
import { VbEmailDirective } from './VbEmail.AngularDirective';
import { VbHostNameDirective } from './VbHostName.AngularDirective';
import { VbIntegerDirective } from './VbInteger.AngularDirective';
import { VbIpAddressDirective } from './VbIpAddressAngular.Directive';
import { VbLongDirective } from './VbLong.AngularDirective';
import { VbMacAddressDirective } from './VbMacAddressAngular.Directive';
import { VbMaxDirective } from 'rev-shared/util/directives/form/validation/VbMax.AngularDirective';
import { VbMaxLengthDirective } from './VbMaxLength.AngularDirective';
import { VbMediumDirective } from './VbMediumAngular.Directive';
import { VbMinDirective } from './VbMinAngular.Directive';
import { VbMulticastIpAddressDirective } from './VbMulticastIpAddress.Directive';
import { VbPortRangeInputDirective } from './VbPortRangeInput.Directive';
import { VbRequiredDirective } from './VbRequired.Directive';
import { VbShortDirective } from './VbShort.AngularDirective';
import { VbUrlDirective } from './VbUrlAngular.Directive';
import { VbValidationConditionsDirective } from './VbValidationConditions.Directive';

const exports = [
	ClearOnChangeDirective,
	ProhibitPublicAccessValidatorDirective,
	VbColorHexDirective,
	VbEmailDirective,
	VbHostNameDirective,
	VbIntegerDirective,
	VbIpAddressDirective,
	VbLongDirective,
	VbMacAddressDirective,
	VbMaxDirective,
	VbMaxLengthDirective,
	VbMediumDirective,
	VbMinDirective,
	VbMulticastIpAddressDirective,
	VbPortRangeInputDirective,
	VbRequiredDirective,
	VbShortDirective,
	VbUrlDirective,
	VbValidationConditionsDirective
];

@NgModule({
	declarations: exports,
	exports,
	imports: [
		CommonModule,
		FormsModule
	]
})
export class ValidationModule {

}
