import { Injectable } from '@angular/core';

const REV_CREATE_EXTERNAL_DOWNLOAD_URL: string = 'http://vbrick.com/revcreate/index.html';

@Injectable({
	providedIn: 'root'
})
export class RevCreateService {
	private _isSupportedPlatform: boolean;

	constructor() {
		const userAgent = window.navigator.userAgent;
		this._isSupportedPlatform = userAgent.includes('Windows') || (userAgent.includes('Mac') && !userAgent.includes('Mobile'));
	}

	public get isSupportedPlatform(): boolean {
		return this._isSupportedPlatform;
	}

	public openExternalDownloadPage(): void {
		window.open(REV_CREATE_EXTERNAL_DOWNLOAD_URL);
	}
}
