import {
	Directive
} from '@angular/core';

import styles from './vb-ui-text-area.module.less';

@Directive({
	selector: '[vbUiTextArea]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbTextAreaDirective {
	public hostClass: string = `${styles.textArea} 'theme-accent-border-focus'`;
}
