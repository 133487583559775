
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';

import { RecordingStatus } from './RecordingStatus';
import { WebcastModel } from './WebcastModel';
import { WebcastService } from '../Webcast.Service';
import { WebcastStatus } from '../WebcastStatus';
import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';

const RECORDING_STATUSES: WebcastStatus[] = [
	WebcastStatus.Recording,
	WebcastStatus.RecordingStarting,
	WebcastStatus.RecordingStopping
];

export class BroadcastStatus {
	public isActive: boolean;
	public hasBeenActive: boolean;
	public isStopping: boolean;
	public isStarting: boolean;

	constructor(
		private webcast: WebcastModel,
		private PushBus: PushBus,
		private WebcastService: WebcastService,
		private ListenerConnection: WebRtcListenerConnectionService
	) {
		const webcastStatus = this.webcast.status;
		this.isActive = this.hasBeenActive =
			webcastStatus === WebcastStatus.Broadcasting ||
			this.isWebcastRecording(webcastStatus);
	}

	public subscribePush(): IUnsubscribe {
		const setActive = () => this.setActive(true);
		const setInactive = () => this.setActive(false);

		return this.PushBus.subscribe(this.webcast.id, {
			BroadcastStarted: setActive,
			BroadcastStopped: setInactive,
			BroadcastStopping: () => this.setStopping(),
			ScheduledEventStopped: setInactive,
			WebcastEnded: () => {
				this.hasBeenActive = false;
				this.setActive(false);
			}
		});
	}

	public startBroadcast(): Promise<void> {
		if(!this.isActive && !this.isStopping) {
			return this.WebcastService.startBroadcasting(this.webcast)
				.then(() => this.webcast.isProducerEvent && this.ListenerConnection.startBroadcasting());
		}
		return Promise.reject();
	}

	public stopBroadcast(): Promise<void> {
		if(this.isActive && !this.isStopping) {
			this.isStopping = true;

			return this.ensureNotRecording()
				.then(() => this.WebcastService.stopBroadcasting(this.webcast))
				.then(() => this.webcast.isProducerEvent && this.ListenerConnection.stopBroadcasting())
				.finally(() => {
					this.webcast.update();
				});
		}
		return Promise.reject();
	}

	private ensureNotRecording(): Promise<void> {
		const recording = this.webcast.recording as RecordingStatus;

		return recording.isActive ?
			recording.stopRecording(true) :
			Promise.resolve(undefined);
	}

	private isWebcastRecording(webcastStatus: WebcastStatus): boolean {
		return RECORDING_STATUSES.includes(webcastStatus);
	}

	private setActive(active: boolean): void {
		this.isActive = active;
		this.hasBeenActive = this.hasBeenActive || this.isActive;
		this.isStopping = false;
		this.webcast.update();
	}

	private setStopping(): void {
		this.isStopping = true;
	}
}
