import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IInsightOptions } from './ngx/Insight.Contract';
import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';
import { InsightTemplate } from './Constants';

import { getInsightStrings } from './InsightStrings';

@Component({
	selector: 'vb-insight',
	templateUrl: './VbInsightAngular.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbInsightAngularComponent),
		multi: true
	}]
})
export class VbInsightAngularComponent extends BaseControlValueAccessor<any[]> implements ControlValueAccessor {
	@Input() public name: string;
	@Input() public label: string;
	@Input() public insightOptions: IInsightOptions;
	@Input() public assignedTemplate: string;
	@Input() public optionTemplate: string;

	public readonly InsightTemplate = InsightTemplate;

	constructor(
		private TranslateService: TranslateService
	) {
		super();
	}

	public onModelChange(): void {
		this.updateModelValue(this.value);
	}

	public ngOnInit(): void {
		this.insightOptions.strings = getInsightStrings(this.TranslateService);
	}

}
