import { Injectable } from '@angular/core';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { PushService } from 'rev-shared/push/PushService';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { HttpClient } from '@angular/common/http';
import { ITranscriptionFile } from './ITranscriptionFile';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

const UploadStatusRouteScope = 'UploadStatus';

@Injectable({
	providedIn: 'root'
})
export class UploadService {
	constructor(
		private http: HttpClient,
		private PushService: PushService,
		private PushBus: PushBus
	) {}

	public uploadImage(context: string, file: FileWrapper): Promise<string> {
		return this.getImageUploadUri(context)
			.then(result => this.uploadFile(file, result, 'ImageStoringFinished', 'ImageStoringFailed'));
	}

	private getImageUploadUri(context: string): Promise<any> {
		return this.PushService.dispatchCommand('media:AddImageToAccount', { context }, 'ImageCreated')
			.then(result => ({
				id: result.message.imageId,
				uploadUri: result.message.uploadUri
			}));
	}

	public replaceTranscription(videoId: string, languageId: string, srt: string): Promise<any> {
		const filename = `${languageId}.srt`;

		return this.PushService.dispatchCommand('media:ReplaceTranscriptionFile', {
			videoId,
			languageId
		}, 'TranscriptionFileReplaced')
			.then(result =>
				this.uploadData(srt, {
					id: videoId,
					uploadUri: result.message.transcriptionFileUploadUri,
					filename
				}, 'TranscriptionFileStoringFinished', 'TranscriptionFileStoringFailed')
			);
	}

	public uploadFile(
		file: FileWrapper,
		uploadDetails: { id: string; uploadUri: string},
		finishedEvent: string,
		failedEvent: string
	): Promise<string> {
		file.setOptions({
			url: uploadDetails.uploadUri
		});

		return this.upload(
			() => file.submit(),
			uploadDetails,
			UploadStatusRouteScope,
			finishedEvent,
			failedEvent);
	}

	public uploadData(
		data: string,
		uploadDetails: { id: string; filename: string, uploadUri: string },
		finishedEvent: string,
		failedEvent: string
	): Promise<string> {
		const formData = new FormData();
		formData.append('file', new Blob([data], { type: 'application/octet-stream' }), uploadDetails.filename);

		return this.upload(
			() => lastValueFrom(this.http.post(uploadDetails.uploadUri, formData)),
			uploadDetails,
			'Media.VideoSettings',
			finishedEvent,
			failedEvent);
	}


	public upload(
		uploadFn: () => Promise<any>,
		uploadDetails: { id: string; uploadUri: string },
		routeScope: string,
		finishedEvent: string,
		failedEvent: string
	): Promise<string> {
		const storingFinished = this.PushBus.awaitMessage({
			route: uploadDetails.id,
			routeScope,
			events: finishedEvent,
			rejectEvents: failedEvent
		});

		return storingFinished.subscribed
			.then(uploadFn)
			.then(() => storingFinished)
			.then(() => uploadDetails.id);
	}
}
