import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';

import styles from './VbUiDataGridTooltip.module.less';

export const DUMMY_TOOLTIP = '@DummyTooltip';

/**
 * The way Tooltip component works -
 * It will show header name as tooltip out of box.
 * If more info needed to be shown in header then pass the value using headerTooltip property in vb-ui-data-grid-column
 * In case of more info in header, it will also show a '?' icon as an indication that more info exits.
 * If tooltip for row value needed to be show then use [tooltipField] in vb-ui-data-grid-column
 */
@Component({
	selector: 'vb-ui-data-grid-tooltip',
	template: `
		<div [ngClass]="styles.tooltipInner">
			<div *ngIf="isHeader">
				<div>
					{{ headerName }}
				</div>
				<div *ngIf="hasHeaderTooltipValue" [ngClass]="styles.headerTooltipValue">
					{{ tooltipText }}
				</div>
			</div>
			<div *ngIf="!isHeader">
				{{ tooltipText }}
			</div>
		</div>
	`,
	host: {
		'[class]': 'styles.root'
	}
})
export class VbUiDataGridTooltipComponent implements ITooltipAngularComp {
	public hasHeaderTooltipValue: boolean;
	public headerName: string;
	public isHeader: boolean;
	public tooltipText: string;

	public readonly styles = styles;

	public agInit(params: ITooltipParams): void {
		this.isHeader = params.rowIndex === undefined;
		this.headerName = params.colDef.headerName;
		this.tooltipText = params.value;
		this.hasHeaderTooltipValue = params.value !== DUMMY_TOOLTIP;
	}
}
