import { fromEvent, Observable, from } from 'rxjs';

import { FileUtil } from 'rev-shared/util/FileUtil';
import { getDataUri } from 'rev-shared/util/ImageUtils';
import { CsrfTokenHeader } from 'rev-shared/security/Tokens';
import { tap, finalize, map } from 'rxjs/operators';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

interface IFileUploadData extends HTMLInputElement {
	submit: () => JQueryXHR;
	fileInput: JQuery<HTMLInputElement>;
}

interface IImageDimensions {
	height: number;
	width: number;
}

interface IFileOptions {
	url: string;
}

// Create a helper object to deal with the uploading file
export class FileWrapper {
	private fileSubmission: JQueryXHR;
	public readonly prettyName: string;
	public readonly inputName: string;
	public readonly extension: string;
	private readonly $form: JQuery<any>;

	public submit$: Observable<any> = new Observable(subscriber => {
		const onProgress = (_, progress) => subscriber.next({
			...progress,
			isProgress: true
		});

		this.$form.on('fileuploadprogress', onProgress);

		return from<Promise<any>>(this.submit()).pipe(
			map(() => ({
				isComplete: true
			})),
			finalize(() => this.$form.off('fileuploadprogress', onProgress))
		)
			.subscribe(subscriber);
	});

	constructor(
		private UserContext: UserContextService,
		private file: IFileUploadData,
		$form?: any,
		private nativeFile?: File
	) {
		this.$form = jQuery($form);
		if(!file) {
			this.file = stubFile(this.nativeFile, this.$form, UserContext);
		}
		else {
			this.nativeFile = this.file.files[0];
		}

		this.inputName = this.file.fileInput?.attr('name');

		const { name, extension } = FileUtil.parseFileName(this.nativeFile.name) || {};
		this.prettyName = name;
		this.extension = extension;
	}

	public get isImageFile(): boolean {
		return FileUtil.isImageFile(this.extension);
	}

	public get isVideoFile(): boolean {
		return FileUtil.isVideoFile(this.extension);
	}

	public get isZipFile(): boolean {
		return FileUtil.isZipFile(this.extension);
	}

	public get name(): string {
		return this.nativeFile.name;
	}

	public get size(): number {
		return this.nativeFile.size;
	}

	public abort(): void {
		this.fileSubmission?.abort();
	}

	public getImageUrl(): Promise<string> {
		if(!this.nativeFile.type.match(/^image/)) {
			return Promise.reject();
		}

		return this.getDataUrl();
	}

	public getDataUrl(): Promise<string> {
		return getDataUri(this.nativeFile);
	}

	public setOptions(options: IFileOptions): void {
		Object.assign(this.file, options);
	}

	public submit(url?: string): Promise<void> {
		if (this.fileSubmission) {
			throw new Error('File upload in progress');
		}

		if(url) {
			this.setOptions({ url });
		}

		this.fileSubmission = this.file.submit();
		return Promise.resolve(this.fileSubmission);
	}
}

function stubFile(file: File, $el: any, userContext: UserContextService): IFileUploadData {
	return {
		files: [file],
		submit() {
			return $el.fileupload('send', {
				files: [file],
				url: this.url,
				beforeSend: xhr => {
					xhr.setRequestHeader(CsrfTokenHeader, userContext.getCsrfToken());
				}
			});
		}
	} as any;
}
