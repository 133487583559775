import { NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CollapsableSectionComponent } from './CollapsableSection.Component';


const entryComponents = [
	CollapsableSectionComponent
];

@NgModule({
	declarations: entryComponents,
	imports: [
		CommonModule,
		TranslateModule,
		TooltipModule,
		CollapseModule
	],
	exports: [
		CollapsableSectionComponent
	],
	entryComponents
})
export class CollapsableSectionAngularModule {}
