import { Component, Input } from '@angular/core';

import styles from './vb-ui-page-header.module.less';

@Component({
	selector: 'vb-page-header',
	templateUrl: './VbPageHeader.Component.html'
})
export class VbPageHeaderComponent {
	@Input() public headerText: string;

	public readonly styles = styles;
}
