import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ICommandPromise, PushService } from 'rev-shared/push/PushService';
import { getCookie, unsetCookie } from 'rev-shared/util/CookieUtil';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	providedIn: 'root'
})
export class UserAuthenticationService {
	constructor(
		private http: HttpClient,
		private PushService: PushService
	) {}

	public acceptUserAgreement(userId: string, ssoLogin: boolean): Promise<any> {
		return lastValueFrom(this.http.post('/auth/accept-agreement', { userId, ssoLogin }));
	}

	/**
	 * Authenticate user with the user's credentials. returns a promise that will resolve with the users auth token.
	 * @param username
	 * @param password
	 */
	public authenticateUser(username: string, password: string): Promise<any> {
		return lastValueFrom(this.http.post('/auth', { username, password }))
			.catch((result: HttpErrorResponse) => {
				console.log('auth error: ', result);
				const response: any = result.error || {};

				if (response.invalidLicense) {
					return Promise.reject('InvalidLicense');
				}

				if (response.lockedOut) {
					return Promise.reject('LockedOut');
				}

				if (response.notActive) {
					return Promise.reject('NotActive');
				}

				if(response.userAgreementRequired) {
					return Promise.reject(response);
				}

				return Promise.reject('LogOnFailed');
			});
	}

	public checkSessionHealth(): Promise<any> {
		return lastValueFrom(this.http.get('/session'));
	}

	public testSetCookie(): Promise<any> {
		return lastValueFrom(this.http.get('/cookie-fix'))
			.then(() => {
				if(!getCookie('CookieFix', true)) {
					console.log('Unable to read back cookies');
					return Promise.reject();
				}
				unsetCookie('CookieFix', true);
			});
	}

	public confirmUser(userConfirmation: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('network:ConfirmUser', {
			userId: userConfirmation.userId,
			token: userConfirmation.token,
			password: userConfirmation.password,
			securityQuestion: userConfirmation.securityQuestion,
			securityAnswer: userConfirmation.securityAnswer
		}, ['AcquireUserLicenseFailed', 'UserConfirmed']);
	}

	/**
	 * Logs out the currently active user
	 * returns promise object
	 */
	public doLogout(userId: string): ICommandPromise<void> {
		return this.PushService.dispatchCommand('network:LogOff', {
			userId
		});
	}

	/**
	 * Session keep alive api.
	 * @param userId
	 */
	public extendSessionTimeout(userId: string): ICommandPromise<void> {
		return this.PushService.dispatchCommand('network:ExtendSessionTimeout', {
			userId
		});
	}

	public getAuthorization(): Promise<any> {
		return lastValueFrom(this.http.get<any>('/authorization'))
			.then(result => result.authorizationKeys);
	}

	public getUserAgreement(): Promise<any> {
		return lastValueFrom(this.http.get<any>('/user-agreement'))
			.then(result => result.userAgreement);
	}

	public getUserConfirmation(token: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/network/users/confirm/${token}`))
			.then(data => {
				return {
					userId: data.userId,
					accountId: data.accountId,
					username: data.username
				};
			});
	}

	public getUserPasswordReset(token: string): Promise<any> {
		return lastValueFrom(this.http.get(`/network/users/reset/${token}`));
	}

	public requestPasswordReset(username: string): ICommandPromise<void> {
		return this.PushService.dispatchCommand('network:RequestPasswordReset', { username });
	}

	public resetPassword(passwordReset: any): Promise<any> {
		return this.PushService.dispatchCommand('network:ResetPassword', {
			token: passwordReset.token,
			userId: passwordReset.userId,
			securityAnswer: passwordReset.securityAnswer,
			password: passwordReset.password
		})
			.then(result => {
				if (result.type === 'PasswordResetFailed') {
					return Promise.reject('PasswordResetFailed');
				}

				return result;
			});
	}
}
