import { Component, Input } from '@angular/core';
import { SpinnerState } from './VbLoadingSpinner.Component';

//A Large spinner, centered vertically and horizontally
@Component({
	selector: 'vb-centered-spinner',
	template: `<vb-loading-spinner
		[block]="true"
		[size]="'large'"
		[iconClass]="iconClass"
		[msg]="msg"
		[msgDisplayBelowSpinner]="msgDisplayBelowSpinner"
		[opt]="opt"
		[state]="state"
	>
	</vb-loading-spinner>`
})
export class VbCenteredSpinnerComponent {
	@Input() public iconClass: string;
	@Input() public msg: string;
	@Input() public msgDisplayBelowSpinner: boolean;
	@Input() public opt: string;
	@Input() public state: SpinnerState;
}
