import { Injectable } from '@angular/core';
import { Producer } from 'mediasoup-client/lib/types';

import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';
import { AudioProducer } from 'rev-shared/webrtc/AudioProducer';
import { isSafari as isSafariFn } from 'rev-shared/util/UserAgentUtil';

const isSafari = isSafariFn();

@Injectable({
	providedIn: 'root'
})
export class ListenerProducerService {

	public cameraStream: MediaStream;
	public screenCaptureStream: MediaStream;
	public micStream: MediaStream;

	private initialized: boolean;
	private cameraProducer: Producer;
	private captureProducer: Producer;
	public recordingStarted: boolean;

	private audioProducer: AudioProducer;

	constructor(
		private WebRtcListener: WebRtcListenerConnectionService
	) { }


	public init(id: string, listenerUrl: string, mixAudioTracks?: boolean, isMuted?: boolean): Promise<any> {
		console.log('SelfProducedService.init()', id, listenerUrl);
		return this.WebRtcListener.initializeConnection(id, listenerUrl, isMuted)
			.then(() => {
				console.log('selfProducedService init complete');
				this.initialized = true;
				this.audioProducer = new AudioProducer(mixAudioTracks, this.WebRtcListener);
			});
	}

	public stop(stopRecording: boolean): void {
		this.WebRtcListener.stop(stopRecording);
		this.audioProducer?.close();
		this.audioProducer = null;
		this.initialized = false;
		this.cameraProducer = null;
		this.captureProducer = null;
		this.recordingStarted = false;
		this.cameraStream = null;
		this.screenCaptureStream = null;
		this.micStream = null;
	}

	public setCameraStream(cameraStream: MediaStream, closeProducer?: boolean): Promise<void> {
		if(!this.initialized) {
			this.cameraStream = null;
			return Promise.resolve();
		}

		this.cameraStream = cameraStream;
		if(!cameraStream) {
			return !closeProducer ? Promise.resolve() :
				this.WebRtcListener.stopProducer(this.cameraProducer)
					.catch(e => console.error('setCameraStream error', e))
					.finally(() => {
						this.cameraProducer = null;
					});
		}

		return this.produceVideoStream(cameraStream,
			{ isSafari },
			() => this.cameraProducer,
			producer => this.cameraProducer = producer);
	}

	public setDisplayCaptureStream(stream: MediaStream, closeProducer?: boolean): Promise<void> {
		if(!this.initialized) {
			this.screenCaptureStream = null;
			return Promise.resolve();
		}

		this.screenCaptureStream = stream;
		if(!stream) {
			return !closeProducer ? Promise.resolve() :
				this.WebRtcListener.stopProducer(this.captureProducer)
					.catch(e => console.error('setDisplayCaptureStream error', e))
					.finally(() => {
						this.captureProducer = null;
					});
		}

		stream.getAudioTracks().forEach(track =>
			track.addEventListener('ended', () => this.updateAudioProducer()));

		console.log('Set Display capture stream');
		return this.produceVideoStream(stream,
			{ kind: 'content' },
			() => this.captureProducer,
			producer => this.captureProducer = producer)
			.then(() => this.updateAudioProducer());
	}

	public setMicStream(stream?: MediaStream, micEnabled: boolean = true): Promise<void> {
		if(!this.initialized) {
			this.micStream = null;
			return Promise.resolve();
		}

		this.micStream = stream;
		const track = stream?.getAudioTracks()[0];
		if (!micEnabled && track) {
			track.enabled = false;
		}
		track?.addEventListener('ended', () => this.updateAudioProducer());
		return this.updateAudioProducer();
	}

	public setPlaceholderVideo(stream: MediaStream): Promise<void> {
		if(!this.initialized) {
			return Promise.resolve();
		}
		const appData = { isSafari, placeHolderStream: true };
		return this.produceVideoStream(stream, appData,
			() => this.cameraProducer,
			producer => this.cameraProducer = producer);
	}

	public stopContent(): Promise<void> {
		if (this.captureProducer) {
			return this.WebRtcListener.stopContent(this.captureProducer)
				.then(() => {
					this.captureProducer = null;
					this.screenCaptureStream = null;
				})
				.catch(e => {
					console.error('Error stopping content: ', e);
				});
		}
		return Promise.resolve();
	}

	private produceVideoStream(stream: MediaStream, appData: any,
		getProducer: () => Producer,
		setProducer: (p: Producer) => void): Promise<void> {

		if(!this.initialized) {
			return Promise.reject('ProduceVideoStream, not initialized');
		}

		const tracks = stream.getVideoTracks();
		if(!tracks.length) {
			return Promise.reject('produceVideoStream, stream has no video track');
		}

		const producer = getProducer();
		if(producer && (!appData.isSafari || appData.kind === 'content')) {
			console.log('produceVideoStream, replacing track');
			return this.WebRtcListener.replaceTrack(producer, tracks[0], appData.kind === 'content' ? 'content' : 'speaker');
		}

		console.log('produceVideoStream, starting producer');
		return this.WebRtcListener.produceVideo(stream, appData)
			.then(setProducer);
	}

	public updateAudioProducer(): Promise<void> {
		if(!this.initialized) {
			return Promise.resolve();
		}

		return this.audioProducer.update(this.micStream, this.screenCaptureStream);
	}

	public toggleMic(enabled: boolean): void {
		if (this.micStream) {
			this.micStream.getAudioTracks()[0].enabled = enabled;
		}
	}

	public tryStartRecordingSelfProduced(): Promise<void> {
		if(!this.audioProducer ||
			!this.cameraProducer ||
			this.recordingStarted) {
			return Promise.resolve();
		}

		this.recordingStarted = true;
		return this.WebRtcListener.startRecording();
	}

}
