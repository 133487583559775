import { Directive, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({})
export abstract class VbAuthorizationBaseAngular implements OnChanges {
	private currentTemplateRef: TemplateRef<HTMLElement>;

	public abstract elseTemplateRef: TemplateRef<HTMLElement>;

	constructor(
		private templateRef: TemplateRef<HTMLElement>,
		private viewContainer: ViewContainerRef
	) {}

	public ngOnChanges(): void {
		this.updateView();
	}

	public abstract checkPermission(): boolean;

	private updateView(): void {
		const nextTemplateRef: TemplateRef<HTMLElement> = this.checkPermission() ?
			this.templateRef :
			this.elseTemplateRef;

		if (this.currentTemplateRef !== nextTemplateRef) {
			this.viewContainer.clear();
			this.viewContainer.createEmbeddedView(nextTemplateRef);

			this.currentTemplateRef = nextTemplateRef;
		}
	}
}
