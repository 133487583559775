import { Device } from 'mediasoup-client';
import { Consumer, Producer, Transport } from 'mediasoup-client/lib/types';

export enum StreamType {
	Speaker = 'speaker',
	Content = 'content',
	Audio = 'audio'
}

export interface IListenerConnection {
	listenerUrl: string;
	token?: string;
	device?: Device; //move
	socket?: SocketIOClient.Socket;
	streamProducers?: Producer[];
	transport?: Transport;
	receiveTransport?: Transport;
}

export interface IPresenter {
	id: string;
	name: string;
	streams: IInboundStream[];
	muted: boolean;
	imgUri: string;

	cameraStream?: MediaStream;
	captureStream?: MediaStream;
}

export interface IInboundStream {
	producer: { id, kind, streamType };
	consumer?: Consumer;
}

export interface Rect {
	x: number;
	y: number;
	w: number;
	h: number;
}

export type IPresenterStreams = Record<StreamType, IPresenterAVStream>;

export interface IPresenterAVStream {
	audio: IInboundStream;
	video: IInboundStream;
}
