import { isString } from 'rev-shared/util';
import { PlaybackTokenType } from './PlaybackTokenType';
import { isIe } from 'rev-shared/util/UserAgentUtil';

const AVAILABLE_TOKENS = [PlaybackTokenType.CloudFrontToken, PlaybackTokenType.VBToken];

export const PLAYBACK_TOKEN_END_POINT: string = '/auth/playback-url-token';

export function isPlaybackTokenAvailable(src: string): boolean {
	return !!getPlaybackToken(src);
}

export function getPlaybackToken(src: string): string {
	if(!isString(src)) {
		return;
	}
	return AVAILABLE_TOKENS.find(token => src.includes(token));
}

export function useWithCredentials(src: string): boolean {
	return isIe() || !src.includes(PlaybackTokenType.CloudFrontToken);
}
