import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';
import { WebexTeamsModule } from 'rev-portal/scheduledEvents/webex-teams/WebexTeams.Module';
import { ZoomAngularModule } from 'rev-portal/scheduledEvents/zoom/ZoomAngularModule';

import { RecordingMenuComponent } from './RecordingMenu.Component';
import { RecordingTimerComponent } from './RecordingTimer.Component';
import { StatusSpinnersAngularModule } from 'rev-shared/statusSpinners/StatusSpinnersAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { RecordingProcessComponent } from './RecordingProcess.Component';

const components = [
	RecordingMenuComponent,
	RecordingTimerComponent,
	RecordingProcessComponent,
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DateAngularModule,
		FormDirectivesAngularModule,
		FormGroupAngularModule,
		FormsModule,
		ReactiveFormsModule,
		SpinnerAngularModule,
		StatusSpinnersAngularModule,
		TranslateModule,
		TypeaheadModule,
		ValidationModule,
		VbTextAreaModule,
		WebexTeamsModule,
		ZoomAngularModule
	]
})
export class RecordingAngularModule {}
