// make sure that this matches the enum server-side
export enum LicenseType {
	BY_USERS = 'ByUsers',
	BY_HOURS = 'ByHours',
	ACTIVE_USERS = 'ActiveUsers',
	DISTRIBUTION_HOURS = 'DistributionHours'
}

export enum ModuleType {
	FullRev = 'FullRev',
	Distribution = 'Distribution'
}

export enum FeatureName {
	USER_SEAT = 'Rev_Active_User_Seat',
	ACTIVE_USER_SEAT = 'Rev_Monthly_Active_User_Seat',
	VIEWING_HOURS = 'Rev_Viewing_Hours',
	DISTRIBUTION_HOURS = 'Rev_Distribution_Hours',
	AI_HOURS = 'Rev_AI_Hours'
}
