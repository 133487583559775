import { isArray } from 'rev-shared/util';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { SearchConstants } from './SearchConstants';

export class SearchQueryBuilder{
	private clauses: string[] = [];

	public buildQuery(): string{
		return this.clauses.join(' AND ');
	}

	//This must be the last clause added to the query, otherwise does not work
	public query(query: string): SearchQueryBuilder {
		query = query || '';
		return this.appendIf(query, '(' + query.replace(/:/g, '') + ')');
	}

	public parsedQuery(query: string): SearchQueryBuilder {
		return this.append(query);
	}

	//User entered query for any field
	public anyMatch(query: string): SearchQueryBuilder {
		return this.fuzzyMatch('All', query);
	}

	//User entered search string for specific field
	public fuzzyMatch(field: string, query: string): SearchQueryBuilder {
		if (!query) {
			return this;
		}

		const andNotRx = /\"|AND |NOT /;

		return this.appendIf(query, field + ':' +
			// if the user put in quotes or ampersands, or if there are already
			//  boolean operators in the query, send it as is
			(query.match(andNotRx) ?
				('(' + query.replace(/ /g, '%20') + ')' ) :
				('*' + query.replace(/ /g, '*%20*') + '*')));
	}

	//options:
	//	useCalendarDates:  if true, from date will be set to beginning of day, to date will be set to end of day
	public dateRange(
		field: string,
		from: Date,
		to: Date,
		options: { useCalendarDates: boolean }): SearchQueryBuilder {

		if(options.useCalendarDates){
			from = from && DateUtil.getStartOfDay(from);
			to = to && DateUtil.getEndOfDay(to);
		}

		const fromStr = from && from.toISOString();
		const toStr = to && to.toISOString();

		return this.range(field, fromStr, toStr, SearchConstants.minDateTime, SearchConstants.maxDateTime);
	}

	public range(field: string, from: string, to: string, min?: string, max?: string): SearchQueryBuilder {
		const hasFrom = from != null || null;
		const hasTo = to != null || null;

		return this.appendIf(hasFrom || hasTo,
			field + ':[' + (hasFrom ? from : min) +
			'+TO+' + (hasTo ? to : max) + ']');
	}

	public text(field: string, query: string): SearchQueryBuilder {
		if(!query){
			return this;
		}

		return this.append(field + ':' + query.replace(/ /g, '%20'));
	}

	public value(field: string, value: string, noQuotes?: boolean): SearchQueryBuilder {
		const quote = noQuotes ? '' : '"';

		return this.appendIf(value, field + ':' + quote + value + quote);
	}

	public values(field: string, values: string|string[]): SearchQueryBuilder {
		if(values && !isArray(values)){
			return this.value(field, values);
		}

		values = values && values.length && (values as string[]).join('%20OR%20');
		if (values) {
			return this.appendIf(values, field + ':(' + values + ')');
		}

		return this;

	}

	//force a multi-value field to be empty (Uncategorized for example)
	public noValues(field: string, noValues: boolean): SearchQueryBuilder {
		//Order matters, only seems to work if first clause;

		if(noValues){
			this.clauses.unshift('-' + field + ':*');
		}

		return this;
	}

	private appendIf(condition: any, clause: string): SearchQueryBuilder {
		if(condition != null && condition !== ''){
			this.append(clause);
		}

		return this;
	}

	private append(clause: string): SearchQueryBuilder {
		if(clause != null && clause !== ''){
			this.clauses.push(clause);
		}

		return this;
	}
}
