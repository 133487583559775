import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { DateParsersService } from './DateParsers.Service';

@Injectable()
export class TimezoneService {

	constructor(
		private DateParsers: DateParsersService,
		private http: HttpClient,
		@Inject(LOCALE_ID) private localeId: string
	) {}

	/**
	 * Returns the offset from UTC for each date in the given timezone.
	 * @param timezoneId UTC timezone Id
	 * @param dates Dates to retrieve the offset for
	 * @param isUTCDate If true, send the date an ISO-8601 string in UTC. Otherwise, the date is sent without a timezone, treated as a date within the given timezone.
	 */
	public getUTCOffset(timezoneId: string, dates: Date[], isUTCDate: boolean = false): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/shared/utc-offset/${timezoneId}`, {
			params: {
				timezoneId,
				dates: (dates || []).map(date => {
					if (isUTCDate) {
						return date.toISOString();
					}

					// Send the dates without a timezone. DateTime will parse the date an Unspecified Kind value.
					return formatDate(date, 'yyyy-MM-dd hh:mm:ss', this.localeId);
				}).join('|')
			}
		}))
			.then(result => (result.offsets.$values || []).map(offset => this.DateParsers.parseTimespan(offset)));
	}
}
