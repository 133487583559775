import { Directive } from '@angular/core';

import styles from './vb-ui-btn-primary.module.less';

@Directive({
	selector: '[vbUiBtnPrimary]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiBtnPrimaryAngularDirective {
	public readonly hostClass: string = `${styles.vbBtnPrimary} theme-accent theme-accent-border`;
}
