import { Component, ContentChildren, Input, QueryList } from '@angular/core';

import { AgGridColumn } from '@ag-grid-community/angular';
import { ColDef, ColDefUtil } from '@ag-grid-community/core';

interface IVbUiDataGridColumn {
	columnPickerGroup?: string;
	sortingOrderReversed?: boolean;
}

export interface IVbUiDataGridColDef extends ColDef, IVbUiDataGridColumn {}

/**
 * Extends the AgGridColumn so that we may provided custom configuration.
 */
@Component({
	selector: 'vb-ui-data-grid-column',
	template: ''
})
export class VbUiDataGridColumnComponent extends AgGridColumn implements IVbUiDataGridColumn {
	@Input() public columnPickerGroup: string; // id of the VbUiDataGridColumnPickerGroup this column should be associated with in the VbUiDataGridColumnPicker
	@Input() public sortingOrderReversed: boolean;

	@ContentChildren(VbUiDataGridColumnComponent) public childColumns: QueryList<VbUiDataGridColumnComponent>;
}

// Silence ag-grid column property validation warnings
ColDefUtil.FRAMEWORK_PROPERTIES.push('columnPickerGroup', 'sortingOrderReversed');
