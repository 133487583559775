import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { VbCenteredSpinnerComponent } from './VbCenteredSpinner.Component';
import { VbLoadingSpinnerComponent } from './VbLoadingSpinner.Component';

const components = [
	VbCenteredSpinnerComponent,
	VbLoadingSpinnerComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		CommonModule
	]
})
export class SpinnerAngularModule {}
