import { throttle as _throttle } from 'underscore';
import { Inject, Injectable } from '@angular/core';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { SignalRHubsConnection, SignalRHubsConnectionState } from 'rev-shared/push/SignalRHubsConnection';
import { SignalRHubsConnectionToken } from 'rev-shared/push/SignalRHubsConnectionToken';

import { UserAuthenticationService } from './UserAuthentication.Service';
import { UserContextService } from './UserContext.Service';

export interface ISessionKeepalive {
	begin(): ISessionKeepalive;
	beginWhenConnected(): ISessionKeepalive;
	end(): ISessionKeepalive;
}

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	private readonly sessionKeepAliveInterval: number = 5 * 60000;

	private extendSessionTimeoutImpl: () => void;

	constructor(
		private LoginRedirectService: LoginRedirectService,
		@Inject(SignalRHubsConnectionToken) private SignalRHubsConnection: SignalRHubsConnection,
		private UserAuthenticationService: UserAuthenticationService,
		private UserContext: UserContextService
	) {
		this.init();
	}

	public createKeepalive(): ISessionKeepalive {
		const sessionSvc: SessionService = this;
		let timer: number;

		const keepaliveReturn: ISessionKeepalive = {
			begin(): ISessionKeepalive {
				if (!sessionSvc.UserContext.isUserLoggedIn()) {
					return;
				}

				keepAlive();

				function keepAlive() {
					timer = window.setTimeout(keepAlive, sessionSvc.sessionKeepAliveInterval);
					sessionSvc.extendSessionTimeoutImpl();
				}

				return this;
			},

			beginWhenConnected(): ISessionKeepalive {
				// if connected to SignalR, then go ahead and begin the keepAlive
				if (sessionSvc.SignalRHubsConnection.getConnectionStatus() === SignalRHubsConnectionState.Connected) {
					this.begin();
				} else { // otherwise, wait for the connection and then begin
					sessionSvc.SignalRHubsConnection.on('stateChanged', onSignalRStateChange);
				}

				return this;
			},

			end(): ISessionKeepalive {
				window.clearTimeout(timer);
				timer = null;
				sessionSvc.SignalRHubsConnection.off('stateChanged', onSignalRStateChange);

				return this;
			}
		};

		function onSignalRStateChange(change: any): void {
			if (change.newState === SignalRHubsConnectionState.Connected) {
				sessionSvc.SignalRHubsConnection.off('stateChanged', onSignalRStateChange);

				keepaliveReturn.begin();
			}
		}

		return keepaliveReturn;
	}

	public tryExtendTimeout(): void {
		if (!this.UserContext.isUserLoggedIn() || this.UserContext.registeredGuest) {
			return;
		}

		this.extendSessionTimeoutImpl();
	}

	private init(): void {
		this.extendSessionTimeoutImpl = _throttle(() => {
			if (this.UserContext.isUserAuthenticated()) {
				this.UserAuthenticationService.extendSessionTimeout(this.UserContext.getUser().id)
					.catch((err: any) => {
						if(err.hasIssue('CommandDenied')) {
							this.LoginRedirectService.redirectToLogout();
						}

						console.log('Cannot do command: ', err);
					});
			}
		}, this.sessionKeepAliveInterval);
	}
}
