import { Component } from '@angular/core';

import type { AgEvent, GridApi, ICellRendererParams, RowNode } from '@ag-grid-community/core';
import type { ICellRendererAngularComp } from '@ag-grid-community/angular';

import styles from './ButtonGroupCellRenderer.module.less';

export interface IButtonGroupCellRendererButtonDef {
	action: string;
	onlyIconDisplay?: boolean;
	iconClass?: string;
	label: string;
}

export interface IButtonGroupCellRendererDef {
	buttons: IButtonGroupCellRendererButtonDef[];
	verticalLayout?: boolean;
}

export interface ButtonGroupCellClickEvent extends AgEvent {
	action: string;
	rowNode: RowNode;
}

interface IButtonGroupCellRendererParams extends ICellRendererParams, IButtonGroupCellRendererDef {}

export const BUTTON_GROUP_CELL_CLICK: string = 'buttonGroupCellClick';

@Component({
	selector: 'button-group-cell-renderer',
	templateUrl: 'ButtonGroupCellRenderer.Component.html',
	host: {
		'[class]': '(verticalLayout ? styles.verticalLayout : "")'
	}
})
export class ButtonGroupCellRendererComponent implements ICellRendererAngularComp {
	public buttons: IButtonGroupCellRendererButtonDef[];
	public verticalLayout: boolean;

	private gridApi: GridApi;
	private rowNode: RowNode;
	public readonly styles = styles;

	public agInit(params: IButtonGroupCellRendererParams): void {
		this.updateCell(params);
	}

	public onClick(buttonDef: IButtonGroupCellRendererButtonDef): void {
		this.gridApi.dispatchEvent({
			action: buttonDef.action,
			rowNode: this.rowNode,
			type: BUTTON_GROUP_CELL_CLICK
		} as ButtonGroupCellClickEvent);
	}

	public refresh(params: IButtonGroupCellRendererParams): boolean {
		this.updateCell(params);

		return true; // nothing to do on refresh, so signal success
	}

	private updateCell(params: IButtonGroupCellRendererParams): void {
		this.buttons = params.buttons;
		this.gridApi = params.api;
		this.rowNode = params.node;
		this.verticalLayout = params.verticalLayout;
	}
}
