import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AgEvent, GridApi, ICellRendererParams, RowNode } from '@ag-grid-community/core';

import styles from './ActionMenuButtonCellRenderer.Component.module.less';

interface IActionMenuItem {
	action: string;
	label?: string;
	labelKey?: string;
}

interface IActionButton {
	label: string;
}

interface IActionMenubuttonCellRendererParams extends ICellRendererParams, IActionMenuButtonCellRendererDef {}

export interface IActionMenuButtonCellRendererDef {
	actionButton?: IActionButton;
	getMenuItems: (rowNode?: any) => IActionMenuItem[];
	menuWrapperCssClass?: string;
}

export interface ActionMenuClickEvent extends AgEvent {
	action: string;
	rowNode: RowNode;
}

export const ACTION_MENU_CLICK: string = 'actionMenuClick';

@Component({
	selector: 'action-menu-button-cell-renderer',
	templateUrl: './ActionMenuButtonCellRenderer.Component.html'
})
export class ActionMenuButtonCellRendererComponent implements ICellRendererAngularComp {
	public actionBtnInfo: IActionButton;
	public menuItemsInfo: IActionMenuItem[];
	public menuWrapperCssClass: string;

	public readonly styles = styles;

	private gridApi: GridApi;
	private rowNode: RowNode;

	public agInit(params: IActionMenubuttonCellRendererParams): void {
		this.updateCell(params);
	}

	public onClick(menuItem: IActionMenuItem): void {
		this.gridApi.dispatchEvent({
			action: menuItem.action,
			rowNode: this.rowNode,
			type: ACTION_MENU_CLICK
		} as ActionMenuClickEvent);
	}

	public refresh(params: IActionMenubuttonCellRendererParams): boolean {
		this.updateCell(params);

		return true; // nothing to do on refresh, so signal success
	}

	private updateCell(params: IActionMenubuttonCellRendererParams): void {
		this.actionBtnInfo = params.actionButton;
		this.gridApi = params.api;
		this.menuItemsInfo = params.getMenuItems(params.data);
		this.rowNode = params.node;
		this.menuWrapperCssClass = params.menuWrapperCssClass;
	}
}
