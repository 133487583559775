<vb-title [title]="'Title_LogOn' | translate"></vb-title>

<vb-auth-layout>

	<div [hidden]="!status.processing">
		<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
		</vb-loading-spinner>
	</div>

	<div [hidden]="!status.loading">
		<vb-loading-spinner [msg]="'Login_LogoffMessage' | translate" [size]="'large'">
		</vb-loading-spinner>
	</div>

	<div [hidden]="!status.active">

		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>

		<div [ngClass]="authLayoutStyles.heading">
			<h2>{{ 'SignIn' | translate }}</h2>
		</div>

		<form #loginForm="ngForm" [ngClass]="authLayoutStyles.authForm" name="loginForm" (submit)="submit()">

			<div [ngClass]="authLayoutStyles.authMsg" *ngIf="status.statusMessage">{{ status.statusMessage }}</div>

			<div class="margin-top-20">

				<div class="margin-top-20">
					<label for="username">{{ 'Username' | translate }}</label>
					<input type="text" id="username" name="username" class="form-control theme-header-bg theme-header-txt" [attr.aria-label]="'Username' | translate" [placeholder]="'Username_Placeholder' | translate" [(ngModel)]="formData.username" vbAutoFocus required>
				</div>
				<div class="margin-top-20">
					<label for="password">{{ 'Password' | translate }}</label>
					<input type="password" id="password" name="password" class="form-control theme-header-bg theme-header-txt" [attr.aria-label]="'Password' | translate" [placeholder]="'Password_YourPlaceholder' | translate" [(ngModel)]="formData.password" required>
				</div>

				<div class="margin-top-30">
					<button type="submit" class="btn btn-primary theme-accent theme-accent-txt" [ngClass]="authLayoutStyles.authBtn">
						{{ 'SignIn' | translate }}
					</button>
				</div>

				<div class="margin-top-20 pull-right" *ngIf="allowPasswordReset()">
					<a class="theme-header-txt" [uiSref]="'forgot-password'">{{ 'ForgotPasswordNoQuestion' | translate }}</a>
				</div>
			</div>

		</form>

	</div>

</vb-auth-layout>
