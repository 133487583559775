import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Observer, Subscription, of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { throttle } from 'underscore';
import { TypeaheadDirective } from 'ngx-bootstrap/typeahead';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { isString } from 'rev-shared/util';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { BaseInlineEditAngularComponent } from './BaseInlineEditAngularComponent';
import { ButtonType } from './TypeaheadButtonType';
import { ITypeaheadCfg } from './ITypeaheadCfg';

import styles from './InlineEdit.module.less';

const TypeaheadDelayMs = 200;
const TypeaheadMinLength = 1;

@Component({
	selector: 'vb-ui-typeahead-angular',
	templateUrl: './VbUiTypeaheadAngular.Component.html'
})
export class VbUiTypeaheadAngularComponent extends BaseInlineEditAngularComponent<any> implements AfterViewInit, OnInit, OnDestroy, OnChanges {
	@Input() public required: boolean;
	@Input() public typeaheadCfg: ITypeaheadCfg;
	@ViewChild('typeahead') public typeahead: TypeaheadDirective;

	public readonly TypeaheadDelayMs = TypeaheadDelayMs;
	public readonly styles = styles;

	public typeaheadLoading: boolean;
	public typeaheadNoResults: boolean;
	public typeaheadObservable$: Observable<any>;
	public isSearchType: boolean;
	public isOkCancelType: boolean;
	public typeaheadMinLength: number;

	public themeStyleOverrides: IRules;
	private themeServiceSub: Subscription;

	private throttledSubmit = throttle(() => this.submit(), 1000);

	constructor(
		private ThemeService: ThemeService,
	) {
		super();
	}

	public ngOnInit(): void {
		this.typeaheadObservable$ = this.getTypeaheadObservable();
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings));
		this.isSearchType = this.typeaheadCfg?.btnCfg?.type === ButtonType.Search;
		this.isOkCancelType = this.typeaheadCfg?.btnCfg?.type === ButtonType.OkCancel;
		this.typeaheadMinLength = this.typeaheadCfg.minLength || TypeaheadMinLength;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.value) {
			this.assignInternalValue();
		}
	}

	public ngAfterViewInit(): void {
		this.assignInternalValue();
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	private getTypeaheadObservable(): Observable<string[]> {
		return new Observable(
			(observer: Observer<string>) => {
				observer.next(this.internalValue);
			}
		).pipe(
			switchMap((query: string) => {
				if (query) {
					return from<Observable<any[]>>(this.typeaheadCfg.loadResults(query) as any).pipe(
						catchError(err => {
							console.error(err);
							return of([]);
						})
					);
				}
				return of([]);
			})
		);
	}

	public applyCssRules(brandingSettings: BrandingSettings) {
		const accentColor = brandingSettings?.themeSettings.accentColor;
		const accentFontColor = brandingSettings?.themeSettings.accentFontColor;
		this.themeStyleOverrides = {
			[`.${this.styles.inputWrap} .dropdown-menu .typeahead-match-active > a`]: {
				'background-color': `${accentColor} !important`,
				'color': `${accentFontColor} !important`
			},
			[`.themedSearchButton`]: {
				'background-color': `${accentColor} !important`,
				'color': `${accentFontColor} !important`,
				'display': `inline-block`,
				'margin-bottom': `0`,
				'font-weight': `400`,
				'white-space': `nowrap`,
				'text-align': `center`,
				'vertical-align': `middle`,
				'cursor': `pointer`,
				'border': `1px solid ${accentFontColor}`,
				'padding': `8px 12px`,
				'position': `relative`,
				'border-radius': `3px`
			}
		};
	}

	public onTypeaheadLoading(event): void {
		this.typeaheadLoading = event;
	}

	public onTypeaheadNoResults(event): void {
		this.typeaheadNoResults = event;
	}

	public onSelect($event): void {
		if (!isString($event.item)) {
			this.value = $event.item;
		}

		if (!this.typeaheadCfg.submitOnSelect) {
			return;
		}
		this.throttledSubmit();
	}

	public onTypeaheadSubmit(): void {
		this.submit();
	}

	public onInputBlur(): void {
		this.typeaheadNoResults = false;
		if (this.typeaheadCfg.notifyWhenNoValue && !this.internalValue) {
			this.submit();
		}
	}

	private endEdit(): void {
		super.cancel();
	}

	public submit(): void {
		//making sure either return object or string.
		const value = this.value?.[this.typeaheadCfg.searchField] === this.internalValue ? this.value : this.internalValue;
		this.onSubmit.emit({ value });
	}

	public onEnterKeyPressed(): void {
		this.throttledSubmit();
	}

	private assignInternalValue(): void {
		this.internalValue = !this.value || isString(this.value) ? this.value : this.value[this.typeaheadCfg.searchField];
	}
}
