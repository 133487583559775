
import { Observable, EmptyError } from 'rxjs';

//toPromise is deprecated in the next release of rxjs. It is now recommended to use the lastValueFrom method.
//This patches the method to allow easier upgrading later;
export function lastValueFrom<T>(source: Observable<T>) {
	return new Promise<T>((resolve, reject) => {
		let _hasValue = false;
		let _value: T;
		source.subscribe({
			next: value => {
				_value = value;
				_hasValue = true;
			},
			error: reject,
			complete: () => {
				if (_hasValue) {
					resolve(_value);
				} else {
					reject(new EmptyError());
				}
			},
		});
	});
}
