import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FileUploadAngularModule } from 'rev-shared/ui/fileUpload/FileUpload.AngularModule';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';

import { ImageSelectorComponent } from './ImageSelector.AngularComponent';

const exports = [ImageSelectorComponent];

@NgModule({
	declarations: exports,
	exports,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormsModule,
		FormGroupAngularModule,
		FileUploadAngularModule,
		PipesAngularModule,
		TranslateModule,
		ValidationAngularModule
	]
})
export class ImageSelectorAngularModule {}
