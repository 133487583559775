import { Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

import type { Column, ColumnApi } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';

import { sortBy } from 'underscore';

import { IVbUiCheckboxGroupOption, VbUiCheckboxGroupComponent } from 'rev-shared/ui/checkbox/VbUiCheckboxGroup.Component';

import { VbUiDataGridColumnPickerGroup } from './VbUiDataGridColumnPickerGroup.Component';
import { IVbUiDataGridColDef } from '../columns/VbUiDataGridColumn.Component';

import styles from './VbUiDataGridColumnPicker.Component.module.less';

interface IColumnPickerGroup {
	columnEntries: IVbUiCheckboxGroupOption[];
	headerName: string;
	isDefaultExpanded: boolean;
	disableColumnEntrySort?: boolean;
	selectedColIds: string[];
}

interface IColumPickerGroupMap {
	[id: string]: IColumnPickerGroup;
}

const DEFAULT_GROUP_ID: string = '$$';

/**
 * Displays a list of checkbox groups generated from the supplied inputs of the column and group configurations.
 * Supports filtering across the groups based on a VbUiSearchInput.
 */
@Component({
	selector: 'vb-ui-data-grid-column-picker',
	templateUrl: './VbUiDataGridColumnPicker.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VbUiDataGridColumnPicker implements OnChanges {
	@Input() public columns: Column[];
	@Input() public columnApi: ColumnApi;
	@Input() public groups: QueryList<VbUiDataGridColumnPickerGroup>;

	@ViewChildren(VbUiCheckboxGroupComponent) public checkboxGroups: QueryList<VbUiCheckboxGroupComponent>;

	public columnFilter: string;
	public columnPickerGroups: IColumnPickerGroup[];
	public readonly styles = styles;

	constructor(
		private translateService: TranslateService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.columns && this.columns) {
			this.updateColumnsModel();
		}
	}

	public get isAllGroupOptionsHidden(): boolean {
		return this.checkboxGroups
			?.toArray()
			.every(checkboxGroup => checkboxGroup.isAllOptionsHidden);
	}

	public onCheckboxGroupSelectedChanged(group: IColumnPickerGroup): void {
		const selectedColIdsSet = new Set(group.selectedColIds);
		const unselectedColIds: string[] = group.columnEntries.reduce((output, { id }) => {
			if (!selectedColIdsSet.has(id)) {
				output.push(id);
			}

			return output;
		}, []);

		if (unselectedColIds.length) {
			this.columnApi.setColumnsVisible(unselectedColIds, false);
		}

		if (selectedColIdsSet.size) {
			this.columnApi.setColumnsVisible([...selectedColIdsSet], true);
		}
	}

	private updateColumnsModel(): void {
		const groupsMap: IColumPickerGroupMap = this.getGroupsMap();

		this.columns.forEach(col => {
			const colDef: IVbUiDataGridColDef = col.getColDef();
			const groupId: string = colDef.columnPickerGroup ?? DEFAULT_GROUP_ID;

			// short-circuit to prevent hiding of cols with essential functionality
			if (colDef.checkboxSelection || colDef.showRowGroup || colDef.rowDrag) {
				return;
			}

			groupsMap[groupId].columnEntries.push({
				label: colDef.headerName,
				id: col.getColId()
			});

			if (col.isVisible()) {
				groupsMap[groupId].selectedColIds.push(col.getColId());
			}
		});

		const groups: IColumnPickerGroup[] = Object.values(groupsMap)
			.filter(group => group.columnEntries.length);

		const sortedGroups = sortBy(groups, 'headerName');
		sortedGroups.forEach(group => {
			if (!group.disableColumnEntrySort) {
				group.columnEntries = sortBy(group.columnEntries, 'headerName');
			}
		});

		this.columnPickerGroups = sortedGroups;
	}

	private getDefaultGroupHeaderName(): string {
		const translationKey: string = this.groups.length ?
			'UI_DataGrid_ColumnPicker_DefaultGroupWithOthers' :
			'UI_DataGrid_ColumnPicker_DefaultGroupNoOthers';

		return this.translateService.instant(translationKey);
	}

	private getGroupsMap(): IColumPickerGroupMap {
		return this.groups.reduce((output, group) => {
			output[group.id] = {
				columnEntries: [],
				headerName: group.headerName,
				isDefaultExpanded: group.isExpanded,
				disableColumnEntrySort: group.disableColumnEntrySort,
				selectedColIds: []
			};

			return output;
		}, {
			[DEFAULT_GROUP_ID]: {
				columnEntries: [],
				headerName: this.getDefaultGroupHeaderName(),
				isDefaultExpanded: !this.groups.length,
				disableColumnEntrySort: !this.groups.length,
				selectedColIds: []
			}
		});
	}
}
