<div class="rev-create-download-dialog not-supported">
	<vb-toolbar edge-padding class="section-header section-header-warning">
		<div flex="fill">
			<h1 [title]="'Uploads_Import_RevCreate_NotSupported' | translate ">
				<span class="glyphicons circle_exclamation_mark"></span> {{ 'Uploads_Import_RevCreate_NotSupported' | translate }}
			</h1>
		</div>
	</vb-toolbar>

	<div class="modal-body upload-media">
		<div class="btn-transparent upload-import-button">
			<span class="upload-import-button-logo upload-import-button-rev-create"></span>
		</div>

		<div class="rev-create-download-dialog-body-text">
			<h2>{{ 'Uploads_Import_RevCreate_DownloadRevCreate' | translate }}</h2>
			<p>{{ 'Uploads_Import_RevCreate_Info' | translate }}</p>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-admin btn-white" (click)="close()">{{ 'Dismiss' | translate }}</button>
	</div>
</div>
