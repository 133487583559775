import { ModuleType } from 'rev-shared/security/LicenseType';

export interface IRevDisplayContext {
	admin: IAdminDisplay;
}

export interface IAdminDisplay {
	mediaSettings: IMediaSettingsDisplay;
	securitySettings: ISecuritySettingsDisplay;
	integrationSettiings: IIntegrationDisplay;
}

export interface IMediaSettingsDisplay {
	hideEnableCategories: boolean;
	hideEnableTags: boolean;
	hideAllowPolls: boolean;
	hideAllowQA: boolean;
	hideAllowChat: boolean;
}

export interface ISecuritySettingsDisplay {
	hideWebcastRecording: boolean;
}


export interface IIntegrationDisplay {
	hideAdditionalWebcastSources: boolean;
}

function getDisplayContext(hide: boolean): IRevDisplayContext {
	return {
		admin: {
			mediaSettings: {
				hideEnableCategories: hide,
				hideEnableTags: hide,
				hideAllowPolls: hide,
				hideAllowQA: hide,
				hideAllowChat: hide
			},
			securitySettings: {
				hideWebcastRecording: hide
			},
			integrationSettiings: {
				hideAdditionalWebcastSources: hide
			}
		}
	};
}


function getDistributionDisplayContext(): IRevDisplayContext {
	return getDisplayContext(true);
}

function getPortalDisplayContext(): IRevDisplayContext {
	return getDisplayContext(false);
}

const RevDisplayContextMap = new Map<ModuleType, IRevDisplayContext>([
	[ModuleType.Distribution, getDistributionDisplayContext()],
	[ModuleType.FullRev, getPortalDisplayContext()],
]);

export function getRevDisplayContext(moduleType: ModuleType): IRevDisplayContext {
	return RevDisplayContextMap.get(moduleType);
}
