import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagData } from 'ngx-tagify';

import { getParentPath } from 'rev-shared/media/Category.Service';

import styles from './VbTagsInput.Component.module.less';
import { autoCompleteSettings, TEMPLATE, VbTagsInputComponent } from './VbTagsInput.Component';

export interface ICategorySettings {
	categoryId: string;
	fullPath: string;
	name: string;
	isAssignable: boolean;
}

@Component({
	selector: 'vb-categories-tags-input',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbCategoriesTagsInputComponent),
			multi: true
		}
	],
	template: TEMPLATE
})
export class VbCategoriesTagsInputComponent extends VbTagsInputComponent {
	@Input() public categories: ICategorySettings[];

	public ngOnInit(): void {
		super.ngOnInit();
		this.settings = {
			...this.settings,
			...autoCompleteSettings,
			dropdown: {
				...autoCompleteSettings.dropdown,
				mapValueTo: 'name',
				searchKeys: ['name']
			},
			whitelist: this.categories.map(cat => ({ ...cat, value: cat.categoryId })),
			templates: {
				tag(tagData: TagData){
					const parentPath = getParentPath({ name: tagData.name, fullPath: tagData.fullPath });
					return `
						<tag title="${(tagData.title || tagData.name || tagData.value)}"
							contenteditable='false'
							spellcheck='false'
							tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
							class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ''}"
							${this.getAttributes(tagData)}>
								<x title='' class="${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></x>
								<div class="${styles.tag}">
									<span title="${tagData.name || tagData.value}">${tagData.name || tagData.value}</span>
									<small title="${parentPath}">${parentPath}</small>
								</div>
						</tag>
					`;
				},
				dropdownItem(item: TagData) {
					const parentPath = getParentPath({ name: item.name, fullPath: item.fullPath });
					return `
						<div class="${styles.dropdownItem} ${this.settings.classNames.dropdownItem}"
							tabindex="0"  role="option">
							<span title="${item.value}">${item.value}</span>
							<small title="${parentPath}">${parentPath}</small>
						</div>
					`;
				}
			}
		};
	}

	public writeValue(data: any[]): void {
		if (data) {
			const values = data?.map(val => {
				return { ...val, value: val.categoryId };
			});
			this.tagifyTags = values;
		}
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public onTagsChange($event: any[]): void {
		const tags = $event?.map(item => ({
			categoryId: item.categoryId,
			fullPath: item.fullPath,
			name: item.name,
			isAssignable: item.isAssignable
		}));
		this.notifyChange(tags);
	}
}
