<form name="form" #form="ngForm" (submit)="submit()" class="form-horizontal theme-primary">

	<div class="modal-header theme-accent-bg theme-accent-border">
		<h4 class="theme-accent-txt">{{ 'Media_Videos_Sharing_Spark_Title' | translate }}
			<button class="close pull-right btn btn-transparent" (click)="close()" [attr.aria-label]="'Close' | translate">
				<span class="glyphicons remove_2"></span>
			</button>
		</h4>
	</div>

	<div class="modal-body">

		<vb-loading-spinner [hidden]="!status.processing" [size]="'large'"></vb-loading-spinner>

		<div class="centered-copy" [hidden]="!status.saveError">
			<div class="alert alert-danger">
				<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'Media_Videos_Sharing_Spark_SaveError' | translate }}
			</div>
		</div>

		<div class="centered-copy" [hidden]="!status.loadRoomsError">
			<div class="alert alert-danger">
				<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'Media_Videos_Sharing_Spark_LoadError' | translate }}
			</div>
		</div>

		<div [hidden]="!status.active">

			<div *ngIf="rooms">
				<vb-ui-form-group>
					<label #uiLabel for="sparkMessage">{{ 'Media_Videos_Sharing_Spark_YourMessage' | translate }}</label>
					<textarea name="sparkMessage" id="sparkMessage" class="form-control" uiControl #messageInput="ngModel" [(ngModel)]="message" vbMedium></textarea>
					<div *ngIf="messageInput.invalid" role="alert" vbUiErrorMessage uiValidation>
						<div *ngIf="messageInput.errors.vbMedium">{{ 'MediumTextValidationError' | translate }}</div>
					</div>
				</vb-ui-form-group>

				<vb-ui-form-group>
					<label for="rooms" #uiLabel>
						{{ 'Media_Videos_Sharing_Spark_SelectRoom' | translate }}
					</label>
					<div id="rooms" uiControl>
						<vb-tags-input name="selectedRooms" #roomsInput="ngModel" [(ngModel)]="selectedRooms" [placeholder]="'Media_Videos_Sharing_Spark_SelectRoom' | translate" [whitelistCfg]="whitelistCfg" required>
						</vb-tags-input>
					</div>
				</vb-ui-form-group>
				<span class="required-message-small">{{ 'WebexSpace_RequireOne' | translate }}</span>
			</div>

			<p class="help-block" [hidden]="!sparkAuthenticationRequired">
				{{ 'Media_Videos_Sharing_Spark_LogIn' | translate }}
				<a class="theme-primary-txt util-underline" href="/spark/oauth/authorize" target="_blank">
					{{ 'Media_Videos_Sharing_Spark_LogInLink' | translate }}
				</a>
			</p>

			<p class="help-block" [hidden]="!(rooms && !rooms.length)">
				{{ 'Media_Videos_Sharing_Spark_NoRooms' | translate }}
			</p>
		</div>

	</div>

	<div class="modal-footer" [hidden]="!status.active">
		<button type="button" class="btn" (click)="close()" vbUiBtnSecondaryNgx>{{ 'Cancel' | translate }}</button>
		<button type="submit" class="btn" [disabled]="form.invalid || !rooms?.length" vbUiBtnPrimaryNgx>{{ 'Media_Videos_Sharing_Spark_Send' | translate }}</button>
	</div>

	<div class="modal-footer" [hidden]="!status.error">
		<button type="button" class="btn" (click)="close()" vbUiBtnPrimaryNgx>{{ 'Ok' | translate }}</button>
	</div>

</form>
