import { Component, ElementRef, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { debounce, isFunction, noop, isArray } from 'underscore';

import { IInsightOptions, IInsightRecord, IInfiniteScrollPage, IQueryPage } from './Insight.Contract';
import { OptionsDataSource } from './OptionsDataSource';
import { defaultStrings } from './DefaultStrings';

import './insight.less';
import { AssignedOptionsDataSource } from './AssignedOptionsDataSource';

@Component({
	selector: 'insight',
	templateUrl: './Insight.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => InsightComponent),
		multi: true
	}],
	host: {
		'[attr.hidden]': 'hidden ? true : undefined'
	}
})
export class InsightComponent implements ControlValueAccessor, OnDestroy, OnInit, OnChanges {
	@Input() public insightOptions: IInsightOptions;
	@Input() public optionTemplate: TemplateRef<any>;
	@Input() public assignedTemplate: TemplateRef<any>;
	@Input() public hidden: boolean;

	public assignedItems: IInsightRecord[];
	//allows virtual scrolling for the list of assigned options
	public assignedOptionsDataSource: AssignedOptionsDataSource;
	public showOptions: boolean;
	public focus: boolean;
	public query: string = '';
	public optionsDataSource: OptionsDataSource;
	private staticOptions: boolean;

	public onChange: (_: any) => void = noop;
	public onTouched: () => void = noop;
	public registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
	public registerOnTouched(fn: () => void): void { this.onTouched = fn; }

	@ViewChild('assignedViewport', { read: CdkVirtualScrollViewport }) public assignedViewport: CdkVirtualScrollViewport;

	constructor(
		private el: ElementRef
	) {}

	@HostListener('document:click', ['$event'])
	public onGlobalClick(event: MouseEvent): void {
		if (!this.el.nativeElement.contains(event.target)) {
			this.closeOptions();
		}
	}

	public ngOnInit(): void {
		if(!this.insightOptions.fieldDefs){
			throw new Error('Insight fieldDefs is required');
		}
		if(!this.insightOptions.filter && !this.insightOptions.loadQueryPage) {
			throw new Error('Insight: Either provide filter or loadQueryPage in options');
		}

		this.insightOptions.data = this.insightOptions.data || [];
		this.insightOptions.data.forEach(item => this.initItem(item));

		this.insightOptions.strings = Object.assign({}, defaultStrings, this.insightOptions.strings);
		this.insightOptions.fieldDefs.orderBy = this.insightOptions.fieldDefs.orderBy || this.insightOptions.fieldDefs.display;
		this.optionsDataSource = new OptionsDataSource(this.insightOptions);
		this.staticOptions = !isFunction(this.insightOptions.loadQueryPage);

		this.assignedItems = [];
		this.assignedOptionsDataSource = new AssignedOptionsDataSource(this.assignedItems, this.insightOptions, () => this.loadAssignedPage());
	}

	public ngAfterViewInit(): void {
		setTimeout(() => this.assignedViewport?.checkViewportSize(), 1000);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(!this.hidden && changes.hidden?.previousValue) {
			setTimeout(() => this.assignedViewport?.checkViewportSize(), 500);
		}
	}


	public ngOnDestroy(): void {
		this.optionsDataSource.teardown();
	}

	public writeValue(value: IInsightRecord[]): void {
		this.assignedItems = [];

		this.insightOptions.data.forEach(item => item.assigned = false);

		if(isArray(value)){
			const items = value
				.map(item => this.find(this.insightOptions.data, item) || item);

			this.assignedItems = [...this.assignedItems, ...items.filter(i => !this.find(this.assignedItems, i))];

			items.forEach(item => {
				item.assigned = true;
				if (this.findIndex(this.insightOptions.data, item) === -1) {
					this.insightOptions.data.push(item);
				}
			});
		}

		this.assignedOptionsDataSource.updateAssignedItems(this.assignedItems);
	}

	public onQueryInput = debounce(() => this.onQueryInternal(), 300);

	private onQueryInternal(): void {
		if(!this.query) {
			return;
		}

		if(this.staticOptions){
			this.optionsDataSource.updateStaticOptions(this.query);
		}
		else {
			this.loadQueryPage()
				.then(page => this.optionsDataSource.setQueryResults(this.query, page));
		}
	}

	public preventEnter($event: KeyboardEvent): void {
		if ($event.code === 'Enter') {
			$event.preventDefault();
		}
	}

	private loadAssignedPage(): Promise<any> {
		return Promise.resolve(this.insightOptions.onAssignedInfiniteScroll())
			.then((resultPage: IInfiniteScrollPage) => {
				const results = isArray(resultPage) ? resultPage : resultPage.results;
				const complete = !isArray(resultPage) && resultPage.complete;

				const loadedItems: IInsightRecord[] = [];
				const newItems = (results || []).map(item => {
					const existing = this.find(this.insightOptions.data, item);
					const loaded = existing?._loaded;
					const updated = Object.assign(
						existing || {},
						item,
						{ _loaded: true });

					if(!loaded) {
						loadedItems.push(updated);
					}
					return existing ? null : updated;
				})
					.filter(Boolean);

				this.insightOptions.data.push(...newItems);
				this.assignedItems.push(...newItems);

				return {
					complete,
					loadedItems,
					assignedItems: this.assignedItems
				};
			});
	}

	private loadQueryPage(): Promise<IQueryPage> {

		return Promise.resolve(this.insightOptions.loadQueryPage(this.query))
			.then(data => {
				let items;
				let count;

				if (isArray(data)) {
					items = data;
					count = data.length;
				} else if (data?.items) {
					items = data.items;
					count = data.count;
				}

				return {
					items: items?.map(item => {
						item._loaded = true;
						const existing = this.find(this.insightOptions.data, item);
						return existing ? Object.assign(existing, item) : item;
					}) || [],
					count
				};
			});
	}

	private assignItem(item: IInsightRecord): void {
		if(this.findIndex(this.assignedItems, item) === -1) {
			item.assigned = true;
			this.assignedItems.push(item);
			this.assignedOptionsDataSource.appendItem(item);
			if (!this.find(this.insightOptions.data, item)) {
				this.insightOptions.data.push(item);
			}
			this.onChange(this.assignedItems);
			this.onTouched();
		}
	}

	public removeItem(item: IInsightRecord): void {
		item.assigned = false;

		const id = this.getId(item);
		const updated = this.assignedItems.filter(i => this.getId(i) !== id);

		if (updated.length !== this.assignedItems.length) {
			this.assignedOptionsDataSource.removeItem(item);
			this.assignedItems = updated;
			this.onChange(this.assignedItems);
			this.onTouched();
		}
	}

	public toggleItemAssignment(item: IInsightRecord): void {
		return item.assigned ? this.removeItem(item) : this.assignItem(item);
	}

	public openOptions(): void {
		this.showOptions = true;
		this.onTouched();
	}

	public closeOptions(): void {
		this.showOptions = false;
		this.query = '';
		this.focus = false;
		this.optionsDataSource.reset();
	}

	private getDataType(item: IInsightRecord): string {
		const fieldDef = this.insightOptions.fieldDefs.dataType;
		if(isFunction(fieldDef)){
			return fieldDef(item);
		}
		return item[fieldDef as string];
	}

	public getIconClass(item: IInsightRecord): string {
		const dataType = this.getDataType(item);

		const dataTypeClasses = this.insightOptions.dataTypes || {};
		return dataTypeClasses[dataType] || this.insightOptions.dataType;
	}

	private findIndex(array: IInsightRecord[], item: IInsightRecord): number {
		const itemId = this.getId(item);
		return array.findIndex(item => this.getId(item) === itemId);
	}

	private find(array: IInsightRecord[], item: IInsightRecord): IInsightRecord {
		const itemId = this.getId(item);
		return array.find(item => this.getId(item) === itemId);
	}

	private getId(item: IInsightRecord): string {
		return item[this.insightOptions.fieldDefs.identifier];
	}

	private initItem(item: IInsightRecord) {
		item._loaded = true;
	}

}
