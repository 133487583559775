import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import './rev-create-download-dialog.less';

@Component({
	selector: 'rev-create-download-dialog',
	templateUrl: './RevCreateDownloadDialog.Component.html'
})
export class RevCreateDownloadDialogComponent {

	constructor(
		private modalInstance: BsModalRef
	) {}

	public close(): void {
		this.modalInstance.hide();
	}
}
