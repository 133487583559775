import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';

import { SpinnerState } from 'rev-shared/ui/spinner/VbLoadingSpinner.Component';
import { Stopwatch } from 'rev-shared/util/Stopwatch';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import styles from './RecordingTimer.Component.module.less';
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'recording-timer',
	templateUrl: './RecordingTimer.Component.html'
})
export class RecordingTimerComponent implements OnInit, OnDestroy{
	@Input() public autoStart: boolean;
	@Input() public video: any;

	@Output() public onStop = new EventEmitter<void>();

	public readonly SpinnerState = SpinnerState;
	public readonly styles = styles;
	public recordingTime: string;
	public stopwatch: Stopwatch = new Stopwatch();

	private currentInterval: number;

	constructor(
		private UserContext: UserContextService
	) {}

	public ngOnInit(): void {
		if (this.autoStart) {
			this.startTimer();
		}
	}

	public ngOnDestroy(): void {
		clearInterval(this.currentInterval);
	}

	public get isVideoUploader(): boolean {
		return this.UserContext.getUser().id === this.video.uploader.userId;
	}

	private startTimer(): void {
		const whenStarted = this.video.videoConference.whenRecordingStarted;
		const startDate = whenStarted ? new Date(whenStarted) : new Date();
		this.stopwatch.restart(startDate.getTime());

		if (this.currentInterval) {
			clearInterval(this.currentInterval);
		}

		this.currentInterval = window.setInterval(
			() => this.recordingTime = this.stopwatch.format(),
			1000);
	}

	private stopRecording(): void {
		clearInterval(this.currentInterval);
		this.onStop.emit();
	}
}
