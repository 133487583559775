import {
	Component,
	Input,
	Inject,
	ViewChild
} from '@angular/core';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { noop } from 'rev-shared/util';

import { SparkService } from './Spark.Service';

@Component({
	selector: 'vb-subscribe-spark-category-button',
	templateUrl: './VbSubscribeSparkCategoryButton.Component.html'
})
export class VbSubscribeSparkCategoryButtonComponent {
	@Input() public category: any;

	@ViewChild(VbConfirmationDialogComponent)
	public unsubscribeConfirmation: VbConfirmationDialogComponent;

	constructor(
		private DialogService: DialogService,
		private SparkService: SparkService,
		private UserContext: UserContextService
	) {
	}

	public get showButton(): boolean {
		return this.UserContext.isUserAuthenticated();
	}

	public get subscribed(): boolean {
		return !!this.subscription;
	}

	public get subscription() {
		return this.SparkService.categorySubscriptions[this.category.id];
	}

	public subscribe(): void {
		this.DialogService.getDialog('SubscribeToCategoryInSpark').open({
			initialState: {
				category: this.category
			},
		});
	}

	public unsubscribe(): void {
		this.unsubscribeConfirmation.open()
			.result
			.then(
				() => this.SparkService.cancelCategorySubscription(this.category.id),
				noop
			);
	}
}
