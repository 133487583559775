import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
	selector: '[vbAutoDropup]'
})
export class VbAutoDropupDirective {
	@Input('vbAutoDropup') public scrollParent: HTMLElement;
	@Input('rowParent') public offsetChildSelector: HTMLElement;
	@Input() public scrollContainerMenuHeight: number;

	@HostBinding('class.dropup') public get shouldDropUp():boolean {
		//measurements
		const scrollContainerOffsetTop = this.scrollParent.offsetTop;
		const scrollContainerHeight = this.scrollParent.scrollHeight;
		const rowElementOffsetTop = this.offsetChildSelector.offsetTop;

		//toggle the dropup class appropriately
		return rowElementOffsetTop > (scrollContainerOffsetTop + scrollContainerHeight - this.scrollContainerMenuHeight);
	}
}
