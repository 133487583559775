import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { VbrickPlayerModule } from '@vbrick/vbrick-player';


import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';
import { VbrickSharedMediaModule } from '../media/Media.Module';

import { VbVideoPlayerComponent } from './VbVideoPlayer.Component';
import { VBrickPlayerWmvComponent } from './VbrickPlayerWmv.Component';
import { VbPlaybackMenuButtonLegacyComponent } from './VbPlaybackMenuButtonLegacy.Component';

const components = [
	VbVideoPlayerComponent,
	VBrickPlayerWmvComponent,
	VbPlaybackMenuButtonLegacyComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		BsDropdownModule,
		CommonModule,
		DateAngularModule,
		HttpClientModule,
		TranslateModule,
		UtilAngularModule,
		VbrickPlayerModule,
		VbrickSharedMediaModule
	]
})
export class VideoPlayerAngularModule {}
