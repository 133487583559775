import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { distinctUntilChanged, map, skip, BehaviorSubject, Observable, Subscription } from 'rxjs';

import { IPresenter, StreamType } from 'rev-shared/webrtc/Contract';
import { LayoutDefs, LayoutType } from 'rev-shared/webrtc/Layouts';
import { ListenerProducerService } from 'rev-shared/webrtc/ListenerProducer.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';
import { WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { getLayoutVideo } from 'rev-shared/webrtc/ListenerPresenters';
import { stopStream } from 'rev-shared/webrtc/streamUtil';

import { IProducerOptions, IProducerLayout, IProducerStreamsCfg, IProducerLayoutVideo } from './Contract';

@Injectable({
	providedIn: 'root'
})
export class ProducerService {
	private readonly producerStreamsSubject$ = new BehaviorSubject<IProducerStreamsCfg>({} as any);
	public readonly producerStreams$ = this.producerStreamsSubject$.asObservable();
	private sub = new Subscription();

	private webcast: WebcastModel;

	constructor(
		private readonly http: HttpClient,
		private readonly ListenerProducerService: ListenerProducerService,
		private readonly PushService: PushService,
		private readonly PushBus: PushBus,
		private readonly WebRtcListener: WebRtcListenerConnectionService
	) {}

	public init(webcast: WebcastModel): void {
		this.webcast = webcast;
		this.sub.add(this.subscribePush().subscribe());
	}

	public start(): Promise<void> {
		const isMuted = !!this.producerStreamsSubject$.value.micMuted;
		return this.ListenerProducerService.init(this.webcast.id, this.webcast.webRtcListenerUrl, false, isMuted)
			.then(() => this.updateProducerStreams(this.producerStreamsSubject$.value))
			.then(() => {
				this.sub.add(this.producerStreams$.pipe(
					map(streams => !!streams.micMuted),
					distinctUntilChanged(),
					skip(1)
				).subscribe(micMuted => {
					this.WebRtcListener.toggleMutePresenter(micMuted)
						.catch(e => console.warn('toggleMutePresenter: ', e));
				}));
			});
	}

	public stop(): void {
		console.log('Stopping ProducerService');
		this.webcast = null;
		stopProducerStreams(this.producerStreamsSubject$.value);
		this.ListenerProducerService.stop(false);

		this.sub.unsubscribe();
		this.sub = new Subscription();
		this.producerStreamsSubject$.next({} as any);
	}

	public updateProducerStreams(producerStreams: IProducerStreamsCfg): void {
		this.producerStreamsSubject$.next(producerStreams);
		if(producerStreams.cameraStream !== this.ListenerProducerService.cameraStream) {
			this.ListenerProducerService.setCameraStream(producerStreams.cameraStream, true)
				.catch(e => console.error('ProducerService, setCameraStreams', e));
		}

		if(producerStreams.micStream !== this.ListenerProducerService.micStream) {
			this.ListenerProducerService.setMicStream(producerStreams.micStream)
				.catch(e => console.error('ProducerService, setMicStream', e));
		}

		if(producerStreams.displayCaptureStream !== this.ListenerProducerService.screenCaptureStream) {
			this.ListenerProducerService.setDisplayCaptureStream(producerStreams.displayCaptureStream, true)
				.catch(e => console.error('ProducerService, setDisplayCaptureStream', e));
		}
	}

	private addAudioToPreview(audio: IProducerLayoutVideo): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return this.savePreviewLayout({
			...layout,
			audioOnlyStreams: [...(layout.audioOnlyStreams || []), audio]
		});
	}

	public addPresenterToPreview(video: IProducerLayoutVideo, index?: number): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		if(index === undefined) {
			index = Math.max(0, layout.videos.findIndex(v => !v.userId));
		}

		return this.savePreviewLayout({
			...layout,
			videos: layout.videos.map((v, i) => i !== index ? v : video),
			audioOnlyStreams: layout.audioOnlyStreams?.filter(audio => audio.userId === video.userId)
		});
	}

	public removePresenterFromPreview(presenterId: string, type?: StreamType): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		const filterVideo = v => v.userId !== presenterId ||
			type && v.type !== type;

		return this.savePreviewLayout({
			...layout,
			videos: layout.videos.map(v => filterVideo(v) ? v : {} as any),
			audioOnlyStreams: layout.audioOnlyStreams?.filter(filterVideo)
		});
	}

	public togglePresenterPreview(presenter: IPresenter, type: StreamType, setActive: boolean): Promise<void> {
		if(!setActive) {
			return this.removePresenterFromPreview(presenter.id, type);
		}
		const video = getLayoutVideo(presenter, type);

		if(type === StreamType.Audio) {
			return this.addAudioToPreview(video);
		}
		return this.addPresenterToPreview(getLayoutVideo(presenter, type));
	}

	public clearPreviewLayoutSlot(index: number): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return this.savePreviewLayout({
			...layout,
			videos: layout.videos.map((v, i) => i !== index ? v : {} as any)
		});
	}

	public sendPreviewLive(): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return Promise.all([
			this.saveProducerSettings({
				...settings,
				onStageLayout: layout
			}),
			this.WebRtcListener.sendLive(getListenerLayout(layout))
		]);
	}

	public setBackstageLayout(layoutType: LayoutType): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;

		return this.savePreviewLayout({
			name: layoutType,
			audioOnlyStreams: layout.audioOnlyStreams,
			videos: layout.videos.slice(0, LayoutDefs[layoutType]?.length),
			backgroundId: layout.backgroundId
		});
	}

	private savePreviewLayout(layout: IProducerLayout): Promise<any> {
		const settings = this.webcast.producerOptions;
		const producerIds = layout.videos
			.concat(layout.audioOnlyStreams || [])
			.flatMap(v => ([v.videoProducerId, ...(v?.audioProducerIds || [])]))
			.filter(Boolean);

		return Promise.all([
			this.WebRtcListener.isConnected() && this.WebRtcListener.updatePreviewStreams(producerIds),
			this.saveProducerSettings({
				...settings,
				backstageLayout: layout
			})
		]);
	}

	public saveProducerSettings(producerOptions: IProducerOptions): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:SaveProducerOptions', {
			webcastId: this.webcast.id,
			producerOptions: {
				...producerOptions,
				backstageLayout: getLayoutCmd(producerOptions.backstageLayout),
				onStageLayout: getLayoutCmd(producerOptions.onStageLayout)
			}
		});
	}

	public saveBackgroundSettings(backgroundId: string): Promise<any> {
		const settings = this.webcast.producerOptions;

		return Promise.all([
			this.WebRtcListener.uploadBackgroundImage(backgroundId),
			this.saveProducerSettings({
				...settings,
				backstageLayout: {
					...settings.backstageLayout,
					backgroundId
				}
			})
		]);
	}

	private subscribePush(): Observable<any> {
		return this.PushBus.getObservable(this.webcast.id, 'Webcast.Presenter', {
			ProducerOptionsSaved: ({ producerOptions }) => this.updateProducerOptions(producerOptions)
		});
	}

	private updateProducerOptions(newOptions: IProducerOptions): void {
		this.webcast.update({
			producerOptions: {
				...newOptions,
				backstageLayout: readLayout(newOptions.backstageLayout),
				onStageLayout: readLayout(newOptions.onStageLayout)
			}
		});
	}
}

export function stopProducerStreams(streams: IProducerStreamsCfg): void {
	stopStream(streams.cameraStream);
	stopStream(streams.micStream);
	stopStream(streams.displayCaptureStream);
}

export function readProducerOptions(options?: IProducerOptions): IProducerOptions {
	return {
		...options,
		backstageLayout: readLayout(options?.backstageLayout || {} as any),
		onStageLayout: readLayout(options?.onStageLayout || {} as any)
	};
}

function readLayout(layout: IProducerLayout): IProducerLayout {
	const rects = LayoutDefs[layout?.name] || [];
	return {
		...layout,
		videos: rects.map((rect, i) => {
			return {
				...layout.videos[i],
				rect
			};
		})
	};
}

function getLayoutCmd(layout: IProducerLayout): IProducerLayout {
	return {
		name: layout.name,
		videos: layout.videos?.map(v => ({
			videoProducerId: v.videoProducerId,
			audioProducerIds: v.audioProducerIds,
			type: v.type,
			userId: v.userId
		})),
		audioOnlyStreams: layout.audioOnlyStreams?.map(a => ({
			audioProducerIds: a.audioProducerIds,
			type: a.type,
			userId: a.userId
		})),
		backgroundId: layout.backgroundId
	};
}

function getListenerLayout(layout: IProducerLayout): any {
	const videos = layout.videos;
	const ratio = 720/1080;
	const scale = p => Math.round(ratio * p);
	return {
		backgroundName: layout.backgroundId,
		layout: {
			name: layout.name,
			slots: videos
				.filter(v => v.videoProducerId)
				.map((video, i) => {
					const rect = video.rect;
					return {
						x: scale(rect.x),
						y: scale(rect.y),
						w: scale(rect.w),
						h: scale(rect.h),
						z: i,
						slotNumber: i,
						mode: 'pad',
						producerId: video.videoProducerId,
						userId: video.userId
					};
				}),

			audioProducers: videos
				.concat(layout.audioOnlyStreams || [])
				.flatMap(stream => stream.audioProducerIds?.map(producerId => ({
					userId: stream.userId,
					producerId
				})) || [])
		}
	};
}

export function getBgImgUrl(backgroundId: string): string {
	return backgroundId && `/img/producerBackgrounds/${backgroundId}.png`;
}
