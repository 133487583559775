import { ITimeMarker } from 'vbrick-player-src/videogular/controls/VbTimeMarkers.Component';

import { IChapter } from 'rev-shared/media/videoChapter/IChapter';

export function getChapterMarkers(video: any): ITimeMarker[] {
	const chapters: IChapter[] = video?.chapterInfo?.chapters || [];

	return chapters.map(chapter => ({
		startTime: chapter.time / 1000,
		endTime: chapter.time / 1000,
		cssClassName: 'triangle-on-top'
	}));
}
