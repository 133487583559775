<div [ngClass]="styles.cell" (click)="insight.toggleItemAssignment(item)">
	<button type="button" class="btn btn-link pull-right" [attr.aria-label]="(item.assigned ? 'Remove' : 'Add') | translate">
		<span class="glyphicons" [ngClass]="item.assigned ? 'ok_2' : 'plus'"></span>
	</button>
</div>
<div [ngClass]="styles.cell" role="button" (click)="insight.toggleItemAssignment(item)" class="margin-right-10">
	<vb-profile-picture [profileUrl]="item[insight.insightOptions.fieldDefs.profileImageUri]" [placeholderIconClass]="insight.getIconClass(item)"></vb-profile-picture>
</div>
<div [ngClass]="styles.descriptionCell" role="button" (click)="insight.toggleItemAssignment(item)">
	<span [innerHTML]="item[insight.insightOptions.fieldDefs.display] | insightHilight : insight.query : insight.insightOptions.fieldDefs.edgeHilight"></span>
	<span [ngClass]="styles.subDisplay" *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [innerHTML]="item[insight.insightOptions.fieldDefs.subDisplay] | insightHilight : insight.query : insight.insightOptions.fieldDefs.edgeHilight"></span>
</div>
