import {
	Directive,
	Input
} from '@angular/core';


/**
 * Fork of ng-show. hides using the visibility property instead of display
 */
@Directive({
	selector: '[vbVisible]',
	host: {
		'[class.vb-hidden]': '!vbVisible'
	}
})
export class VbVisibleDirective {
	@Input() public vbVisible: boolean;
}
