<div class="webex-import" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"> <!--Prevents the upload dropdown from being closed while interacting with this dialog-->
	<form [hidden]="!showWebexSitesList" name="webexSitesListForm" #webexSitesListForm>
		<vb-toolbar edge-padding class="section-header">
			<div flex="fill" class="padding-left-32">
				<h1>{{ 'Uploads_Import_WebEx_SiteSelection_Header' | translate }}</h1>
			</div>
		</vb-toolbar>
		<div class="modal-body input-fields">
			<label>{{ 'Uploads_Import_WebEx_SiteSelection_Notes' | translate }}</label>
			<vb-ng-select-wrap uiControl>
				<ng-select [items]="webexSites" [(ngModel)]="selectedSite" bindLabel="name" [placeholder]="'Uploads_Import_WebEx_SiteSelection' | translate" name="uiSelectSite" required #uiSelectSite="ngModel">
					<ng-template ng-option-tmp let-item="item">
						<div class="select-choices-row-items">
							<span>
								{{ item.name }} ({{ item.url }})
							</span>
						</div>
					</ng-template>
				</ng-select>
			</vb-ng-select-wrap>
		</div>
		<div class="modal-footer">
			<button class="btn" type="button" vbUiBtnSecondary (click)="close()">
				{{ 'Cancel' | translate }}
			</button>
			<button class="btn margin-left-5" type="button" vbUiBtnPrimary (click)="onSiteSelect()" [disabled]="webexSitesListForm.invalid">
				{{ 'Next' | translate }}
			</button>
		</div>
	</form>
	<div [hidden]="!(!isLoggedIn && !showWebexSitesList)">
		<form name="webexLoginForm" #webexLoginForm class="form-horizontal" (submit)="login()">

			<vb-toolbar edge-padding class="section-header">
				<div flex="fill" class="padding-left-32">
					<h1>{{ 'Uploads_Import_WebEx_Login' | translate }}</h1>
				</div>
			</vb-toolbar>

			<div class="modal-body" disable-ng-animate [hidden]="isLoading">
				<div [hidden]="!errorWebExLogin" class="system-msg error-msg">
					<span class="glyphicons remove_2"></span>{{ 'Login_BadCredentialsMessage' | translate }}
				</div>

				<div [hidden]="!errorWebExGeneric" class="system-msg error-msg">
					<span class="glyphicons remove_2" [hidden]="noWebexRecordingsFound"></span>{{ 'Uploads_Import_WebEx_Error_UnableToConnect' | translate }}
					<span class="glyphicons remove_2" [hidden]="!noWebexRecordingsFound"></span>{{ 'Uploads_Import_WebEx_NoRecordings' | translate }}
				</div>

				<div [hidden]="!errorWebExSso" class="system-msg error-msg">
					<span class="glyphicons remove_2"></span>{{ 'SsoLogin_InvalidWebexUserMessage' | translate }}
				</div>

				<div class="input-fields" [hidden]="hideLoginView">
					<div class="username">
						<input type="text" class="form-control input-with-icon" id="webExUsername" name="webExUsername" vbAutoFocus [placeholder]="'Username' | translate " [(ngModel)]="webExUsername" required>
						<span class="glyphicons user"></span>
					</div>
					<div class="password">
						<input type="password" class="form-control input-with-icon" id="webExPassword" name="webExPassword" autocomplete="new-password" [placeholder]="'Password' | translate " [(ngModel)]="webExPassword" required>
						<span class="glyphicon glyphicon-lock"></span>
					</div>
				</div>
			</div>

			<div class="modal-body" disable-ng-animate [hidden]="!isLoading">
				<vb-loading-spinner [size]="'medium'" [block]="true"></vb-loading-spinner>
			</div>

			<div class="modal-footer" [hidden]="isLoading">
				<button type="button" vbUiBtnPrimary class="margin-left-16 float-left" *ngIf="webexSites.length &gt; 1" (click)="onBackToWebexSitesList()">
					&lt; {{ 'Back' | translate }}
				</button>

				<button type="button" class="btn" vbUiBtnSecondary (click)="close()">
					{{ 'Cancel' | translate }}</button>
				<button type="submit" class="btn" vbUiBtnPrimary [disabled]="webexLoginForm.invalid">
					{{ 'SignIn' | translate }}</button>
			</div>
		</form>
	</div>

	<div [hidden]="!(isLoggedIn && !isLoading && !showWebexSitesList)">
		<form name="webexImportForm" class="form-horizontal" (submit)="import()" class="padding-full-5">

			<vb-toolbar edge-padding class="section-header">
				<div flex="fill">
					<h1>{{ 'Uploads_Import_SelectVideos' | translate }}</h1>
				</div>
				<div [hidden]="!webExRecordings?.length">
					<small>{{ webExRecordings?.length }} {{ 'Media_Videos' | translate }}</small>
				</div>
			</vb-toolbar>

			<div class="modal-body">
				<vb-grid-list [ngClass]="styles.importWebexGridlist" class="white-items" [emptyMsg]="'Uploads_Import_WebEx_NoRecordings' | translate">

					<header header>
						<vb-ui-checkbox class="selectionColumn" (ngModelChange)="syncSelectionHeaderModel()" [(ngModel)]="isSelectAll" name="selectionHeader">
						</vb-ui-checkbox>
						<div flex="fill">{{ 'Name' | translate }}</div>
						<div align="center">{{ 'Imported' | translate }}</div>
						<div>{{ 'Created' | translate }}</div>
						<div align="right">{{ 'Duration' | translate }}</div>
						<div align="right">{{ 'Size' | translate }}</div>
					</header>

					<vb-grid-row-collection vbGridRowCollection>
						<vb-grid-list-row *ngFor="let recording of webExRecordings; trackBy trackByIndex; let index = index">
							<vb-ui-checkbox (ngModelChange)="onRowSelectionChange(recording)" [(ngModel)]="recording.selected" name="selectedRecording{{index}}">
							</vb-ui-checkbox>
							<vb-grid-list-column flex="fill">{{recording.recordingName}}</vb-grid-list-column>
							<vb-grid-list-column align="center">
								<span class="glyphicons" [ngClass]="{'circle_ok': recording.isImported}"></span>
							</vb-grid-list-column>
							<vb-grid-list-column>{{recording.creationDate|date:'medium'}}</vb-grid-list-column>
							<vb-grid-list-column align="right">{{recording.durationInMs|vbTimespan}}</vb-grid-list-column>
							<vb-grid-list-column align="right">{{recording.size|fileSize:2:'KB'}}</vb-grid-list-column>
						</vb-grid-list-row>
					</vb-grid-row-collection>
				</vb-grid-list>
			</div>

			<div class="modal-footer">
				<button class="btn" type="button" vbUiBtnPrimary class="float-left" *ngIf="webexSites.length &gt; 1" (click)="onBackToWebexSitesList()">
					&lt; {{ 'Back' | translate }}
				</button>
				<button type="button" class="btn" vbUiBtnSecondary (click)="close()">{{ 'Cancel' | translate }}</button>
				<button type="submit" class="btn" vbUiBtnPrimary [disabled]="!selectedWebExImports.length">
					{{ 'Import' | translate }}
					<span [hidden]="!selectedWebExImports.length"> {{ selectedWebExImports.length }} {{ 'Media_Videos' | translate }}</span>
				</button>
			</div>
		</form>
	</div>
</div>
