import { Observable } from 'rxjs';

import { Poll } from './Poll';
import { PollStatus } from './PollStatus';
import { PollsModelService } from './PollsModel.Service';
import { PollsService } from './Polls.Service';
import { WebcastModel } from '../webcast/model/WebcastModel';

export const POLL_ANSWERS_DEFAULT = 4;
export const POLL_ANSWERS_MAX = 15;

export class Polls {
	public $polls: Poll[] = [];
	private enabled: boolean;

	constructor(
		public webcast: WebcastModel,
		private PollsModelService: PollsModelService,
		private PollsService: PollsService
	) {
		this.enabled = webcast.pollsEnabled;
	}

	public get length(): number {
		return this.$polls ? this.$polls.length : 0;
	}

	public onPollsCollectionChanged(): void {
		(this.$polls || []).forEach(poll => {
			poll.canBeEdited = poll.id && poll.isClosed() && !poll.totalResponses;
			poll.canBeDeleted = poll.id && poll.isClosed();
		});
	}

	public add(poll: Poll): Promise<void> {
		poll = new Poll(poll, this.webcast, this.PollsModelService);

		return this.PollsService.addWebcastPolls(this.webcast.id, [poll])
			.then(ids => {
				poll.id = ids[0];
				poll.status = PollStatus.Closed;
			});
	}

	public update(poll: Poll): Promise<void> {
		return this.PollsService.updateWebcastPolls(this.webcast.id, [poll]);
	}

	public delete(poll: Poll): Promise<void> {
		return this.PollsService.deleteWebcastPolls(this.webcast.id, [poll.id]);
	}

	public onWebcastDetailsSaved({ pollsEnabled }): void {
		const enablePolls = !this.enabled && pollsEnabled;
		this.enabled = pollsEnabled;

		if(enablePolls){
			this.load();
		}
	}

	public load(): Promise<void> {
		return (this.webcast.currentUser.canManagePolls ?
			this.PollsService.getPolls(this.webcast.id) :
			this.PollsService.getAttendeePolls(this.webcast.id))

			.then(results => {
				this.$polls = results.map(poll =>
					new Poll(poll, this.webcast, this.PollsModelService));
			});
	}

	public subscribePush(): Observable<void> {
		return this.PollsModelService.subscribePush(this.webcast);
	}
}
