
import { HttpClient } from '@angular/common/http';

import { SearchService } from 'rev-portal/search/Search.Service';
import { ICategory } from './Media.Contract';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

const SHARED_IMG_PATH: string = '/shared/img/';

export function getParentPath(category: any) {
	category = category || '';

	const fullPath: string = category.fullPath || '';
	const name: string = category.name || '';

	if (name.length === fullPath.length) {
		return '';
	}

	return fullPath.substr(0, fullPath.lastIndexOf('/' + name));
}

@Injectable({
	providedIn: 'root'
})
export class CategoryService {
	constructor(
		private http: HttpClient,
		private SearchService: SearchService
	) {}

	public getCategoryActiveSearchContent(accountId: string, categoryId?: string, categoryContentOnly?: boolean, count: number = 4): Promise<any> {
		return this.SearchService.getVideos({
			accountId,
			categoryPathIds: !categoryContentOnly ? categoryId : undefined,
			category: categoryContentOnly ? categoryId : undefined,
			isActive: true,
			sortField: 'ThumbnailUri.sort',
			count,
			noScroll: true,
			start: 0,
			uncategorized: !categoryId
		})
			.then(result => ({
				thumbnails: (result.videos || []).map(video => {
					return {
						uri: video.thumbnailUri ||
							SHARED_IMG_PATH + (video.hasAudioOnly ? 'audio-thumbnail.png' : 'default-thumbnail.png'),
						title: video.title
					};
				}),
				videoCount: result.totalHits
			}));
	}

	public getCategoryContent(categoryId: string): Promise<any> {
		return lastValueFrom<any>(this.http.get(`/media/categories/${categoryId}/content`))
			.then(result => {
				if(result.path.length) {
					const topPath = result.path[result.path.length-1];
					topPath.canEdit = result.canEdit;
				}
				return result;
			});
	}

	public getFlattenedCategories(accountId: string): Promise<ICategory[]> {
		return lastValueFrom<any>(this.http.get(`/media/accounts/${accountId}/flattened-categories`))
			.then(response => response.categories);
	}

	public getAssignableCategories(): Promise<ICategory[]> {
		return lastValueFrom<any>(this.http.get('/media/categories/assignable'))
			.then(response => response.categories);
	}

	/**
	 * Gives you the path of the category's parent. In other words, the path without the category itself at the end.
	 * @param  {Category} category
	 * @return {String}	The path with the category itself excluded. If there is no parent, an empty String is returned.
	 */
	public getParentPath(category: any): string {
		return getParentPath(category);
	}

	public getRootCategories(): Promise<any> {
		return lastValueFrom(this.http.get('/media/categories/root-content'));
	}

	public getUncategorizedActiveSearchContent(accountId: string): Promise<any> {
		return this.getCategoryActiveSearchContent(accountId);
	}
}
