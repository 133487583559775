<div [ngClass]="styles.cell">
	<vb-profile-picture [profileUrl]="item[insight.insightOptions.fieldDefs.profileImageUri]" [placeholderIconClass]="insight.getIconClass(item)"></vb-profile-picture>
</div>

<div [ngClass]="styles.descriptionCell">
	<span>{{ item[insight.insightOptions.fieldDefs.display] }}</span>
	<span *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [ngClass]="styles.subDisplay">
		{{ item[insight.insightOptions.fieldDefs.subDisplay] }}
	</span>
</div>

<div [ngClass]="styles.cell" dropdown #dropdown="bs-dropdown" container="body" *ngIf="insight.insightOptions.teamRoleTypes">

	<button type="button" class="btn theme-accent" [ngClass]="styles.btnBadge" dropdownToggle>
		<span class="glyphicons ok_2"></span>
		{{ itemRole | translate }}
	</button>

	<div [ngClass]="styles.badgePopup" *dropdownMenu>
		<ul>
			<li *ngFor="let roleType of insight.insightOptions.teamRoleTypes" [class.active]="itemRole === roleType" (click)="itemRole = roleType; dropdown.hide()">
				<span class="glyphicons" [ngClass]="itemRole === roleType ? 'ok_2' : 'minus'">
				</span>
				{{ roleType | translate }}
			</li>
		</ul>
	</div>
</div>

<div>
	<button type="button" [attr.aria-label]="'Remove' | translate " class="btn btn-link pull-right remove-assigned-item" (click)="insight.removeItem(item)">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
