import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { InsightComponent } from './Insight.Component';
import { InsightHilightPipe } from './InsightHilight.Pipe';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ScrollingModule
	],
	exports: [
		InsightComponent,
		InsightHilightPipe
	],

	declarations: [
		InsightComponent,
		InsightHilightPipe
	]
})
export class InsightModule {
}
