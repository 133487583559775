import { Component, HostListener, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl, NgForm } from '@angular/forms';
import { TagifySettings, TagifyService, TagData } from 'ngx-tagify';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import styles from './VbTagsInput.Component.module.less';
import '@yaireo/tagify/dist/tagify.css';

let tagifyIndex = 0;

export const TEMPLATE = `
	<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>
	<div #form="ngForm" ngForm [ngClass]="styles.wrapper" class="position-relative">
		<tagify
			name="{{tagifyName}}"
			[(ngModel)]="tagifyTags"
			(ngModelChange)="onTagsChange($event)"
			(click)="onTouched()"
			[settings]="settings"
			[readonly]="readonly">
		</tagify>
		<span [ngClass]="styles.tagsIconPlus" class="glyphicons circle_plus theme-accent-inverted"></span>
	</div>
`;

export const autoCompleteSettings = {
	autoComplete: {
		enabled: true
	},
	a11y: {
		focusableTags: true
	},
	dropdown: {
		maxItems: 100,
		classname: styles.dropdown,
		enabled: 0,
		closeOnSelect: false,
		placeAbove: false,
		highlightFirst: true
	},
	skipInvalid: false,
	enforceWhitelist: true
};

export interface VbTagsInputWhitelistCfg {
	items: any[],
	itemTitleKey: string,
	itemIdKey: string
}

@Component({
	selector: 'vb-tags-input',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbTagsInputComponent),
		multi: true
	}],
	template: TEMPLATE
})
export class VbTagsInputComponent implements ControlValueAccessor {
	@Input() public placeholder: string;
	@Input() public readonly: boolean;
	@Input() public whitelistCfg: VbTagsInputWhitelistCfg;

	constructor(
		protected translateService: TranslateService,
		private ThemeService: ThemeService,
		private TagifyService: TagifyService
	) {}

	public onTouched: () => void;
	public onChange: (tags: any[]) => void;
	public subscription: Subscription = new Subscription();
	public themeStyleOverrides: IRules;
	public settings: TagifySettings;
	public tagifyTags: any[];

	public readonly styles = styles;
	public readonly tagifyName = `vbTagify${++tagifyIndex}`;

	public ngOnInit(): void {
		this.settings = {
			placeholder: this.placeholder,
			trim: true
		};

		if (this.whitelistCfg) {
			this.settings = {
				...this.settings,
				...autoCompleteSettings,
				whitelist: this.whitelistCfg.items.map(item => ({ ...item, value: item[this.whitelistCfg.itemTitleKey] })),
				templates: {
					tag(tagData: TagData) {
						return `
							<tag title="${(tagData.title || tagData.value)}"
								contenteditable='false'
								spellcheck='false'
								tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
								class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ''}"
								${this.getAttributes(tagData)}>
									<x title='' class="${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></x>
									<div class="${styles.tag}">
										<span title="${tagData.value}">${tagData.value}</span>
									</div>
							</tag>
						`;
					},
					dropdownItem(item: TagData) {
						return `
							<div class="${styles.dropdownItem} ${this.settings.classNames.dropdownItem}"
								tabindex="0"  role="option">
								<span title="${item.value}">${item.value}</span>
							</div>
						`;
					}
				}
			};
		}

		this.subscription.add(this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings)));
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public applyCssRules(brandingSettings: BrandingSettings) {
		const accentColor = brandingSettings?.themeSettings.accentColor;
		const accentFontColor = brandingSettings?.themeSettings.accentFontColor;
		this.themeStyleOverrides = {
			[`.${styles.wrapper} tags.tagify--focus`]: {
				'border-color': ` ${accentColor} !important`
			},
			[`.${styles.wrapper} tags tag > div`]: {
				color: accentFontColor
			},
			[`.${styles.wrapper} tags tag .tagify__tag__removeBtn`]: {
				color: accentFontColor,
				font: '20px/1 Arial'
			},
			[`.${styles.wrapper} tags tag .tagify__tag__removeBtn:hover`]: {
				background: accentColor,
				color: accentFontColor
			},
			[`.${styles.wrapper} tags tag .tagify__tag__removeBtn:hover + div > span`]: {
				opacity: '1'
			},
			[`
				.${styles.wrapper} tags tag > div:before,
				.${styles.wrapper} tags tag:hover:not([readonly]):not(.tagify--notAllowed) > div:before,
				.${styles.wrapper} tags tag:focus > div:before
			`]: {
				'background-color': accentColor,
				'box-shadow': `0 0 0 1.1em ${accentColor} inset !important`
			},
			[`.${styles.dropdown} .tagify__dropdown__item--active`]: {
				'background': `${accentColor} !important`,
				color: `${accentFontColor} !important`
			}
		};
	}

	public writeValue(data: any[]): void {
		if (data) {
			const values = data?.map(val => {
				if (typeof val === 'string') {
					return { value: val };
				}
				return { ...val, value: val.name };
			});
			this.tagifyTags = values;
		}
	}

	@HostListener('blur')
	public onBlur() {
		this.onTouched();
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public onTagsChange($event: any[]): void {
		const tags = this.whitelistCfg ?
			$event?.map((item: any) => ({ title: item[this.whitelistCfg.itemTitleKey], id: item[this.whitelistCfg.itemIdKey] })) :
			$event?.map(item => item.value);
		this.notifyChange(tags);
	}

	public notifyChange(tags: any): void {
		this.onChange(tags);
		this.resurfaceDropdown();
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		// TODO implementation
	}

	private resurfaceDropdown(): void {
		const tagify = this.TagifyService.get(this.tagifyName);

		if (tagify.dropdown && !!tagify.DOM.dropdown.clientWidth) {
			tagify.dropdown.hide(true);

			window.setTimeout(() => {
				tagify.DOM.input.click();
				tagify.DOM.input.focus();
			}, 100);
		}
	}
}
