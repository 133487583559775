import { Component, Input, OnInit } from '@angular/core';

import { isBoolean } from 'rev-shared/util';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import './VbLoadingSpinner.Component.less';
import styles from './VbLoadingSpinner.module.less';

export enum SpinnerSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large'
}

export enum SpinnerState {
	ACTIVE = 'active',
	COMPLETE = 'complete',
	INACTIVE = 'inactive'
}

/**
 * @fileOverview Represent a loading spinner while state is being changed
 * @module directive/vbLoadingSpinner
 * @requires none
 * @example
 * <vb-loading-spinner
 *   [block]="true"
 *   [size]="'large'">
 * </vb-loading-spinner>
 * @returns {string}
 */
@Component({
	selector: 'vb-loading-spinner',
	templateUrl: './VbLoadingSpinner.Component.html'
})
export class VbLoadingSpinnerComponent implements OnInit {
	@Input() public block: boolean;
	@Input() public iconClass: string;
	@Input() public msg: string;
	@Input() public msgDisplayBelowSpinner: boolean;
	@Input() public opt: string;
	@Input() public size: SpinnerSize;
	@Input() public state: SpinnerState;

	public readonly SpinnerState = SpinnerState;
	public readonly styles = styles;

	constructor(
		private ThemeService: ThemeService
	) {}

	public ngOnInit(): void {
		// For MEDIUM/LARGE, default to block=true
		if (!isBoolean(this.block)) {
			this.block = [SpinnerSize.MEDIUM, SpinnerSize.LARGE].includes(this.size);
		}
	}

	public get accentColor(): string {
		return this.ThemeService.brandingSettings.themeSettings.accentColor;
	}

	public get iconNgClass(): string[] {
		return [
			styles.icon,
			this.iconClass,
			this.size
		].filter(Boolean);
	}

	public get loaderNgClass(): string[] {
		return [
			styles.loader,
			this.size,
			this.opt
		].filter(Boolean);
	}
}
