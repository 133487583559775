<form #inlineTextEditForm="ngForm" [attr.aria-label]="'UI_InlineEditOfValue' | translate : { '0': text }" layout="row" layout-wrap="false" name="inlineTextEditForm" (submit)="submit()">

	<input [attr.aria-label]="'UI_Input_NewValue' | translate " autocomplete="off" type="text" name="txtInput" [(ngModel)]="internalValue" required vbUiTextInput/>

	<button class="margin-left-5" type="button" (click)="submit()" [title]="'Apply' | translate" [disabled]="inlineTextEditForm.invalid" vbUiBtnPrimary>
		<span class="glyphicons ok_2"></span>
	</button>

	<button class="margin-left-5" type="button" [title]="'Discard' | translate" (click)="cancel()" vbUiBtnSecondary>
		<span class="glyphicons remove_2"></span>
	</button>
</form>
