<div [ngClass]="styles.cell">
	<vb-profile-picture [profileUrl]="item[insight.insightOptions.fieldDefs.profileImageUri]" [placeholderIconClass]="insight.getIconClass(item)"></vb-profile-picture>
</div>

<div [ngClass]="styles.descriptionCell">
	<span>{{ item[insight.insightOptions.fieldDefs.display] }}</span>
	<span *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [ngClass]="styles.subDisplay">
		{{ item[insight.insightOptions.fieldDefs.subDisplay] }}
	</span>
</div>

<div *ngIf="insight.insightOptions.showEditAccessInput && insight.insightOptions.showEditAccessInput(item)" [ngClass]="styles.cell">
	<button type="button" class="btn badge btn-edit-access" [(ngModel)]="item.canEdit" [hidden]="insight.insightOptions.forceEditAccess" btnCheckbox [btnCheckboxFalse]="false" [btnCheckboxTrue]="true">

		<span [ngClass]="item.canEdit ? 'ok_2' : 'minus'" class="glyphicons"></span>
		{{ 'Media_Videos_Settings_EditAccess' | translate }}
	</button>

	<span class="btn btn-link btn-edit-access badge" [hidden]="!insight.insightOptions.forceEditAccess">
		<span class="glyphicons ok_2"></span>
		{{ 'Media_Videos_Settings_EditAccess' | translate }}
	</span>
</div>

<div>
	<button type="button" [attr.aria-label]="'Remove' | translate " class="btn btn-link pull-right remove-assigned-item" (click)="insight.removeItem(item)">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
