import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import styles from './VbUiThumbnailSheetPreview.Component.module.less';

export interface IThumbnailSheetConfig {
	horizontalTiles: number,
	verticalTiles: number,
	spf: number,
	totalThumbnails: number,
	sheetWidth: number,
	sheetHeight: number,
	thumbnailSheetsUri: string,
	numSheets: number
}

@Component({
	selector: 'vb-ui-thumbnail-sheet-preview',
	templateUrl: './VbUiThumbnailSheetPreview.Component.html'
})
export class VbUiThumbnailSheetPreviewComponent implements OnChanges, OnDestroy {
	public readonly styles = styles;

	@Input() public thumbnailUri: string;
	@Input() public thumbnailSheetCfg: IThumbnailSheetConfig;
	@Input() public width: number;
	@Input() public height: number;
	@Input() public msPerFrame: number;

	public backgroundPositionX: string = '0px';
	public backgroundPositionY: string = '0px';
	public thumbnailPageOne: string;
	public sheetBackgroundSize: string;
	public thumbnailBackgroundSize: string;
	public isSlideshowActive: boolean = false;
	public widthStyle: string;
	public heightStyle: string;

	private slideshowTimer: number;
	private thumbnailNextIndex: number = 0;
	private thumbnailSheetCache: HTMLImageElement;

	public ngOnChanges(changes: SimpleChanges): void {

		if(changes.width || changes.height) {
			this.widthStyle = `${this.width}px`;
			this.heightStyle = `${this.height}px`;
			this.thumbnailBackgroundSize = `${this.widthStyle} ${this.heightStyle}`;
		}

		if (changes.thumbnailSheetCfg && this.thumbnailSheetCfg) {
			//pre-cache thumbnailSheetUri because they are not loaded on the first couple of frames of the preview if not
			this.thumbnailPageOne = this.thumbnailSheetCfg.thumbnailSheetsUri + '/1';
			this.thumbnailSheetCache = new Image();
			this.thumbnailSheetCache.src = this.thumbnailPageOne;
		}
		if (this.thumbnailSheetCfg) {
			const { horizontalTiles, verticalTiles } = this.thumbnailSheetCfg;
			const x = horizontalTiles * this.width;
			const y = verticalTiles * this.height;
			this.sheetBackgroundSize = `${x}px ${y}px`;
		}
	}

	public ngOnDestroy(): void {
		this.stopSlideshow();
	}

	public get computedUri(): string {
		return `url(${(this.isSlideshowActive && this.thumbnailSheetCfg) ? this.thumbnailPageOne : this.thumbnailUri})`;
	}

	public startSlideshow(): void {
		if (this.thumbnailSheetCfg && !this.slideshowTimer) {
			this.isSlideshowActive = true;
			this.slideshowTimer = window.setInterval(() => {
				if (!this.thumbnailSheetCfg) {
					return this.stopSlideshow();
				}
				if (this.thumbnailNextIndex === this.thumbnailSheetCfg?.totalThumbnails) {
					this.thumbnailNextIndex = 0;
				}
				this.computeState();
				this.thumbnailNextIndex++;
			}, this.msPerFrame || 100);
		}
	}

	public computeState(): void {
		const x = (this.thumbnailNextIndex % this.thumbnailSheetCfg.horizontalTiles) * this.width;
		const y = Math.floor(this.thumbnailNextIndex / this.thumbnailSheetCfg.verticalTiles) * this.height;
		this.backgroundPositionX = `-${x}px`;
		this.backgroundPositionY = `-${y}px`;
	}

	public stopSlideshow(): void {
		if (this.thumbnailPageOne) {
			clearInterval(this.slideshowTimer);
			this.isSlideshowActive = false;
			this.slideshowTimer = null;
			this.backgroundPositionX = '0px';
			this.backgroundPositionY = '0px';
			this.thumbnailNextIndex = 0;
		}
	}
}
