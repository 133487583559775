import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { forwardRef, Directive, ElementRef, Input } from '@angular/core';

/**
 * Validation directive that trims the input string.
 * adds required attr for usability
 */
@Directive({
	selector: '[vbRequired]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbRequiredDirective),
		multi: true
	}],

	host: {
		'required': 'required',
	}
})
export class VbRequiredDirective {
	@Input() public vbRequired: boolean;

	constructor(
		$el: ElementRef
	) {
		const el = $el.nativeElement as HTMLElement;
		if(!el.matches('input[type=text],input[type=password],textarea')) {
			throw new Error('vbRequired can only be used on input[type=text], input[type=password], or textarea');
		}
	}

	public validate(control: AbstractControl): ValidationErrors {
		if (this.vbRequired !== false && !control?.value?.trim()) {
			return { required: true };
		}
	}
}
