<div [ngClass]="styles.cell">
	<vb-profile-picture [profileUrl]="item[insight.insightOptions.fieldDefs.profileImageUri]" [placeholderIconClass]="insight.getIconClass(item)"></vb-profile-picture>
</div>

<div [ngClass]="styles.descriptionCell">
	<span>{{ item[insight.insightOptions.fieldDefs.display] }}</span>
	<span *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [ngClass]="styles.subDisplay">
		{{ item[insight.insightOptions.fieldDefs.subDisplay] }}
	</span>
</div>

<div>
	<button type="button" [ngClass]="styles.btnBadge" class="btn theme-accent" [(ngModel)]="item.categoryManager" btnCheckbox [btnCheckboxFalse]="false" [btnCheckboxTrue]="true">
		<span [ngClass]="item.categoryManager ? 'ok_2' : 'minus'" class="glyphicons">
		</span>
		{{ (item.categoryManager ? 'Media_CategoryManager' : 'Media_CategoryContributor') | translate }}
	</button>
</div>


<div>
	<button type="button" [attr.aria-label]="'Remove' | translate " class="btn btn-link pull-right remove-assigned-item" (click)="insight.removeItem(item)">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
