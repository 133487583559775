const ip4Regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
const ip6Regex = /^((?=.*::)(?!.*::.+::)(::)?([\dA-F]{1,4}:(:|\b)|){5}|([\dA-F]{1,4}:){6})((([\dA-F]{1,4}((?!\3)::|:\b|$))|(?!\2\3)){2}|(((2[0-4]|1\d|[1-9])?\d|25[0-5])\.?\b){4})$/i;
const IPV6_NUM_GROUPS: number = 8;
export const IP_V4_MULTICAST_MIN: string = '224.0.0.0';
export const IP_V4_MULTICAST_MAX: string = '239.255.255.255';

export interface IPRange {
	start: string;
	end: string;
}

/**
 * Validates an IP address (v4 or v6)
 */
export function validateIP(ip: string): boolean {
	return !ip || validateIPv4(ip) || validateIPv6(ip);
}

export function validateIPv4(value: string): boolean {
	if(!value) {
		return true;
	}

	const match = value.match(ip4Regex);
	if(!match) {
		return false;
	}

	for(let i=1, len=match.length; i < len; i++) {
		const octet = +match[i];
		if(octet < 0 || octet > 255) {
			return false;
		}
	}

	return true;
}

export function validateIPv4Multicast(ip: string): boolean {
	return ip
		&& validateIPv4(ip)
		&& validateIPRange({ start: IP_V4_MULTICAST_MIN, end: ip })
		&& validateIPRange({ start: ip, end: IP_V4_MULTICAST_MAX });

}

export function validateIPv4RangeMulticast(ipRange: IPRange): boolean {
	if (!ipRange || !(ipRange.start && ipRange.end)) {
		return false;
	}

	return validateIPRange({ start: ipRange.start, end: ipRange.end })
		&& validateIPv4Multicast(ipRange.start)
		&& validateIPv4Multicast(ipRange.end);
}

export function validateIPv6(ip: string): boolean {
	return ip6Regex.test(ip);
}

export function validateIPRange(range: IPRange): boolean {
	let start: string[];
	let end: string[];
	let base: number;

	if(validateIPv4(range.start) && validateIPv4(range.end)) {
		start = range.start.split('.');
		end = range.end.split('.');
		base = 10;
	}
	else if(validateIPv6(range.start) && validateIPv6(range.end)) {
		start = normalizeIPv6(range.start.split(':'));
		end = normalizeIPv6(range.end.split(':'));
		base = 16;
	}
	else {
		return false;
	}

	for(let i=0, len=start.length; i < len; i++) {
		const a = parseInt(start[i], base);
		const b = parseInt(end[i], base);

		if(a > b) {
			//end ip is before start
			return false;
		}

		if(a < b) {
			//end ip is after start
			return true;
		}
	}

	//start == end, invalid
	return false;
}

/**
 * Normalizes an IPv6 address that has been split by ':' to compensate for the '::' shorthand.
 * The split will produce an empty string entry in the array, which removed. Then 0 entries are filled in at that position to meet the expected array length of a full address.
 * @param splitAddress IPv6 address split by ':'
 */
function normalizeIPv6(splitAddress: string[]): string[] {
	const shortHandIndex: number = splitAddress.indexOf('');

	if (shortHandIndex < 0) {
		return splitAddress;
	}

	const numAdditional: number = IPV6_NUM_GROUPS - (splitAddress.length - 1);
	const additionalGroups: string[] = new Array(numAdditional).fill('0');
	const output = [...splitAddress]; // keeping it pure

	output.splice(shortHandIndex, 1, ...additionalGroups);

	return output;
}
