import { Directive, Input, forwardRef, OnChanges } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[vbMax]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMaxDirective),
			multi: true
		}
	]
})
export class VbMaxDirective implements OnChanges {
	@Input() public vbMax: any;
	private control: AbstractControl;

	public ngOnChanges(): void {
		this.control?.updateValueAndValidity();
	}


	public validate(control: AbstractControl): ValidationErrors {
		if(!this.control && !(control as any).dummy) {
			this.control = control;
		}

		if (this.vbMax == null || !control?.value) {
			return null;
		}

		return control.value <= this.vbMax ? null : { vbMax: true };
	}
}
