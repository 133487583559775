export enum VideoStatus {
	DEVICE_NOT_SUPPORTED = 'DeviceNotSupported',
	DOWNLOADING_FAILED = 'DownloadFailed',
	INGESTING = 'Ingesting',
	NOT_UPLOADED = 'NotUploaded',
	PROCESSING = 'Processing',
	PROCESSING_FAILED = 'ProcessingFailed',
	READY = 'Ready',
	READY_BUT_PROCESSING_FAILED = 'ReadyButProcessingFailed',
	RECORDING = 'Recording',
	RECORDING_CONNECTING = 'Connecting',
	RECORDING_CONNECTION_FAILED = 'ConnectingFailed',
	RECORDING_FAILED = 'RecordingFailed',
	RECORDING_FINISHED = 'RecordingFinished',
	RECORDING_INGESTING = 'Injesting',
	RECORDING_INGESTING_NOTIFIED = 'IngestingNotified',
	RECORDING_INITIALIZING = 'RecordingInitializing',
	RECORDING_START = 'StartRecording',
	RECORDING_STOP_RECORDING = 'StopRecording',
	RECORDING_UPLOADING = 'Uploading',
	RECORDING_UPLOADING_FAILED = 'UploadingFailed',
	RECORDING_UPLOADING_FINISHED = 'UploadingFinished',
	STREAMING_FAILED = 'StreamingFailed',
	STREAMING_RECORDING = 'RecordingStream',
	STREAMING_WAITING = 'WaitingForStream',
	UPLOADING = 'Uploading',
	UPLOADING_FINISHED = 'UploadingFinished',
	UPLOAD_FAILED = 'UploadFailed',
	VIEWING_HOURS_NOT_AVAILABLE = 'ViewingHoursNotAvailable'
}
