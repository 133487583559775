import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges
} from '@angular/core';

import { VgStates } from 'vbrick-player-src/videogular/VgStates';
import { VideogularComponent } from 'vbrick-player-src/videogular/Videogular.Component';

import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { ISessionKeepalive } from 'rev-shared/security/Session.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaybackUpdatedSubType } from 'rev-shared/videoPlayer/analytics/PlaybackUpdatedSubType';
import { ResourceType } from 'rev-shared/videoPlayer/ResourceType';
import { SecondMs } from 'rev-shared/date/Time.Constant';
import { SessionService } from 'rev-shared/security/Session.Service';
import { SpinnerType } from 'rev-shared/videoPlayer/analytics/SpinnerType';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoHeartbeatErrorSubType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatErrorSubType';
import { VideoHeartbeatPlayerType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatPlayerType';
import { VideoPlayerAdapterService } from 'rev-shared/videoPlayer/VideoPlayerAdapter.Service';
import { VideoSourceType } from 'rev-shared/media/VideoSourceType';
import { VideoType } from 'rev-shared/media/VideoType';
import { VodPlayerLoggingClient } from 'rev-shared/media/VodPlayerLoggingClient';
import { VodPlayerLoggingService } from 'rev-shared/media/VodPlayerLogging.Service';
import { equals } from 'rev-shared/util';

import { IVbVideoPlayerConfig } from './IVbVideoPlayerConfig';
import './vb-video-player.less';
import { VBrickPlayerComponent } from '@vbrick/vbrick-player/app/player/VbrickPlayer.Component';

@Component({
	selector: 'vb-video-player',
	host: {
		class: 'vb-player'
	},
	template: `
		<vbrick-player-wmv
			*ngIf="legacyPlaybackUrl"
			[canShowThumbnail]="canShowThumbnailLegacy()"
			[playbackOptions]="playbacks"
			[thumbnailUri]="thumbnailUri"
			[videoUrl]="legacyPlaybackUrl"
			(onPlaybackPositionUpdated)="playbackPositionUpdatedLegacy($event.time)"
			(onPlay)="onPlayLegacy()"
			(onPause)="onPauseLegacy()"
			(onStop)="onStopLegacy()"
			(onComplete)="onCompleteLegacy()">
		</vbrick-player-wmv>

		<vbrick-player
			*ngIf="modernPlaybacks"

			[autoLoad]="autoLoad"
			[autoPlay]="autoPlay"
			[config]="playerConfig"
			[disableControls]="disableControls"
			[durationFallback]="+durationMs / 1000"
			[experiencedBufferingThresholdInMs]="mediaFeatures.bufferingThreshold"
			[flashDisabled]="!mediaFeatures.enableFlashPlayback"
			[forceEndTimeInSec]="forceEndTimeInSec"
			[forceMute]="forceMute"
			[hasAudioOnly]="hasAudioOnly"
			[heartbeatInterval]="heartbeatIntervalSecs"
			[isCaptionsAvailable]="isCaptionsAvailable"
			[isLive]="live"
			[playbackOptions]="modernPlaybacks"
			[posterUrl]="thumbnailUri"
			[startAtResume]="startAtResume"
			[startTime]="startTimeSecs"
			[subtitleOptions]="modernSubtitles"
			[timeMarkers]="timeMarkers"
			[translations]="vbrickPlayerTranslations"
			[thumbnailCfg]="thumbnailCfg"

			(onBufferingStart)="onBufferingStart($event.currentTime, $event.isInitial)"
			(onBufferingStop)="onBufferingStop($event.duration, $event.isInitial)"
			(onBufferingSpinner)="onBufferingSpinner($event.type, $event.duration)"
			(onComplete)="onComplete()"
			(onCrossOriginError)="onCrossOriginError()"
			(onFailover)="onFailover($event.failoverDetails)"
			(onHeartbeat)="onHeartbeat($event.data)"
			(onInitialPlayback)="onInitialPlayback($event.data)"
			(onManualStreamSwitch)="onManualStreamSwitch($event.data)"
			(onMulticastError)="onMulticastError($event.data)"
			(onPause)="onPauseInternal($event.currentTime)"
			(onPlay)="onPlayInternal($event.currentTime, $event.isInitial)"
			(onPlaybackError)="onPlaybackError($event.data)"
			(onPlayerReady)="onPlayerReadyInternal($event.vgAPI, $event.player)"
			(onSeek)="onSeek($event.startTime, $event.endTime)"
			(onStop)="onStop()">
		</vbrick-player>
	`
})
export class VbVideoPlayerComponent implements AfterViewInit, OnDestroy, OnChanges {
	@Input() public autoLoad: boolean;
	@Input() public autoPlay: boolean;
	@Input() public disableControls: boolean;
	@Input() public disableDualPlayback: boolean;
	@Input() public disableLogHeartbeat: boolean = false;
	@Input() public durationMs: number;
	@Input() public forceEndTimeInSec: number;
	@Input() public forceMute: boolean;
	@Input() public hasAudioOnly: boolean;
	@Input() public heartbeatIntervalSecs: number;
	@Input() public is360: boolean;
	@Input() public isCaptionsAvailable: boolean;
	@Input() public live: boolean;
	@Input() public playbacks: any[];
	@Input() public playerConfig: IVbVideoPlayerConfig;
	@Input() public resumeSessionId: string;
	@Input() public sourceType: VideoSourceType;
	@Input() public startAt: number;
	@Input() public startAtResume: boolean;
	@Input() public subtitles: any[];
	@Input() public thumbnailUri: string;
	@Input() public timeMarkers: any [];
	@Input() public videoId: string;
	@Input() public videoTitle: string;
	@Input() public thumbnailCfg: any;

	@Output() public onPause: EventEmitter<any> = new EventEmitter();
	@Output() public onPlay: EventEmitter<any> = new EventEmitter();
	@Output() public onPlaybackEnded: EventEmitter<any> = new EventEmitter();
	@Output() public onPlayerReady: EventEmitter<{ vgAPI: VideogularComponent, player: VBrickPlayerComponent }> = new EventEmitter();

	private isPlayingLegacy: boolean;
	private lastPlayback: any;
	public legacyPlaybackUrl: string;
	private loggingClient: VodPlayerLoggingClient;
	public mediaFeatures: IMediaFeatures;
	public modernPlaybacks: any[];
	public modernSubtitles: any[];
	private playback: any;
	private sessionId: string;
	private sessionKeepAlive: ISessionKeepalive;
	private showThumbnail: boolean;
	public vbrickPlayerTranslations: any;
	private vgAPI: VideogularComponent;
	private videoPlayer: VideoHeartbeatPlayerType;

	constructor(
		private element: ElementRef,
		private MediaFeatures: MediaFeaturesService,
		private Session: SessionService,
		private UserContext: UserContextService,
		private VideoPlayerAdapter: VideoPlayerAdapterService,
		private VodPlayerLogging: VodPlayerLoggingService
	) {}

	public ngAfterViewInit(): void {
		this.isPlayingLegacy = false;
		this.sessionKeepAlive = this.Session.createKeepalive();
		this.showThumbnail = true;

		this.attemptToRenderPlayer(this.playbacks);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.playbacks && !changes.playbacks.isFirstChange()) {
			this.attemptToRenderPlayer(this.playbacks);
		}

		if (changes.subtitles && !changes.subtitles.isFirstChange()) {
			if (!equals(this.subtitles, changes.subtitles.previousValue)) {
				this.modernSubtitles = this.VideoPlayerAdapter.convertToModernSubtitles(this.subtitles);
			}
		}
	}

	public ngOnDestroy(): void {
		this.sessionKeepAlive.end();

		this.tryTerminatePlayback();

		this.destroyLoggingClient();
		this.VideoPlayerAdapter.cleanup();
	}

	public canShowThumbnailLegacy(): boolean {
		return this.showThumbnail;
	}

	public onComplete() {
		if(this.onPlaybackEnded){ //used by playlist and dashboard
			this.onPlaybackEnded.emit({});
		}

		this.sessionKeepAlive.end();

		this.loggingClient.onComplete(this.vgAPI.totalTime);

		this.establishNewSession();
	}

	public onCompleteLegacy(): void {
		this.showThumbnail = !this.autoPlay;
		this.isPlayingLegacy = false;

		if(this.onPlaybackEnded){ //used by playlist and dashboard
			this.onPlaybackEnded.emit({});
		}

		this.sessionKeepAlive.end();
	}

	public onBufferingStart(currentTimeMs: number, isInitial: boolean): void {
		this.loggingClient.onBufferingStart(this.live ? null : currentTimeMs, isInitial);
	}

	public onBufferingStop(durationMs: number, isInitial: boolean): void {
		this.loggingClient.onBufferingStop(durationMs, isInitial);
	}

	public onBufferingSpinner(type: SpinnerType, durationMs: number): void {
		this.loggingClient.onBufferingSpinner(type, durationMs);
	}

	public onCrossOriginError(): void {
		this.sessionKeepAlive.end();

		this.loggingClient.onError({
			isFatal: false, // presently fired to indicate a retry with Flash
			subType: VideoHeartbeatErrorSubType.CROSS_ORIGIN,
			timeInVideo: this.currentTimeInVideo
		});
	}

	public onFailover(failoverDetails: { isToFlash: boolean; fromUrl: string; toUrl: string }): void {
		this.playback = this.VideoPlayerAdapter.getPlaybackForUrl(failoverDetails.toUrl, this.modernPlaybacks, this.playbacks);
		this.videoPlayer = this.getVideoPlayer(failoverDetails.isToFlash);
		const fromPlayback = this.VideoPlayerAdapter.getPlaybackForUrl(failoverDetails.fromUrl, this.modernPlaybacks, this.playbacks);

		this.logPlaybackUpdated(PlaybackUpdatedSubType.AUTO_SWITCH, fromPlayback.url);
	}

	public onHeartbeat(data: any): void {
		this.loggingClient.onHeartbeat(data);
	}

	public onInitialPlayback(data: { isFlash: boolean; src: string }): void {
		this.playback = this.VideoPlayerAdapter.getPlaybackForUrl(data.src, this.modernPlaybacks, this.playbacks);
		this.videoPlayer = this.getVideoPlayer(data.isFlash);

		this.logPlaybackUpdated(PlaybackUpdatedSubType.INITIAL);
	}

	public onManualStreamSwitch(data: { isFlash: boolean; src: string }): void {
		this.playback = this.VideoPlayerAdapter.getPlaybackForUrl(data.src, this.modernPlaybacks, this.playbacks);
		this.videoPlayer = this.getVideoPlayer(data.isFlash);

		this.logPlaybackUpdated(PlaybackUpdatedSubType.MANUAL_SWITCH);
	}

	public onMulticastError(data: any): void {
		this.sessionKeepAlive.end();

		this.loggingClient.onError({
			isFatal: data.isFatal,
			subType: VideoHeartbeatErrorSubType.MULTICAST,
			timeInVideo: this.currentTimeInVideo
		});
	}

	public onPauseInternal(currentTimeMs: number): void {
		this.sessionKeepAlive.end();

		this.loggingClient.onPause(this.live ? null : currentTimeMs);

		this.onPause.emit({});
	}

	public onPauseLegacy(): void {
		this.isPlayingLegacy = false;

		this.sessionKeepAlive.end();
	}

	public onPlaybackError(data: any): void {
		this.sessionKeepAlive.end();

		this.loggingClient.onError( {
			isFatal: data.isFatal,
			subType: VideoHeartbeatErrorSubType.PLAYBACK,
			timeInVideo: this.currentTimeInVideo
		});
	}

	public onPlayerReadyInternal(vgAPI: VideogularComponent, player: VBrickPlayerComponent): void {
		this.vgAPI = vgAPI;

		this.onPlayerReady.emit({ vgAPI, player });
	}

	public onPlayInternal(currentTimeMs: number, isInitial: boolean): void {
		this.sessionKeepAlive.begin();

		this.loggingClient.onPlay(this.live ? null : currentTimeMs, isInitial);

		this.onPlay.emit({});
	}

	public onPlayLegacy(): void {
		this.showThumbnail = false;
		this.isPlayingLegacy = true;

		this.sessionKeepAlive.begin();
	}

	public onSeek(seekFrom: number, seekTo: number): void {
		if (this.playback) {
			this.loggingClient.onSeek({
				seekFrom,
				seekTo
			});
		}
	}

	public onStop(): void {
		this.sessionKeepAlive.end();

		this.loggingClient.onStop();
	}

	public onStopLegacy(): void {
		this.isPlayingLegacy = false;

		this.sessionKeepAlive.end();
	}

	public playbackPositionUpdatedLegacy(timeSecs: number): void { //for legacy players
		this.vgAPI.currentTime = timeSecs;
	}

	public get startTimeSecs(): number {
		return this.startAt / SecondMs; // ms to sec
	}

	private attemptToRenderPlayer(playbacks: any[]): void {
		console.log('enter attemptToRenderPlayer'); //TODO: check this in qa under different video types.
		const playback = this.getSelectedPlayback(playbacks);

		if (playback && playback !== this.lastPlayback) {
			this.lastPlayback = playback;
			this.sessionId = this.getSessionId();

			this.destroyLoggingClient();
			this.loggingClient = this.VodPlayerLogging.getClient(this.videoId, this.disableLogHeartbeat);

			this.renderPlayer(playback, {
				thumbnailUri: this.thumbnailUri,
				hasAudioOnly: this.hasAudioOnly
			});
		}
	}

	private destroyLoggingClient(): void {
		if (this.loggingClient) {
			this.loggingClient.destroy();
		}
	}

	private establishNewSession() {
		//create a new session id
		this.sessionId = this.getSessionId();

		//convey the new id by establishing a new playback session
		this.logPlaybackUpdated(PlaybackUpdatedSubType.INITIAL);
	}

	private getSelectedPlayback(playbacks: any[]): any {
		if (playbacks && playbacks.length) {
			return playbacks.find(option => option.selected === true) || playbacks[0];
		}

		return null;
	}

	private getSessionId(): string {
		if(this.resumeSessionId){
			return this.resumeSessionId;
		}

		const userId: string = this.UserContext.getUser().id || (Math.random() + '').substr(2);
		return `${this.videoId}_${userId}_${Date.now()}`;
	}

	private getVideoPlayer(isFlash: boolean): VideoHeartbeatPlayerType {
		return isFlash ? VideoHeartbeatPlayerType.FLASH : VideoHeartbeatPlayerType.HTML5;
	}

	private logPlaybackUpdated(subType: PlaybackUpdatedSubType, failoverFromUrl?: string): void {
		this.loggingClient.onPlaybackUpdated({
			streamDeliveryType: this.playback.streamDeliveryType,
			deviceId: this.playback.deviceId,
			deviceName: this.playback.deviceName,
			durationSecs: this.durationMs / 1000, // ms to sec
			failoverFromUrl,
			sessionId: this.sessionId,
			sourceType: this.sourceType,
			streamAccessed: this.playback.url,
			subType,
			title: this.videoTitle,
			videoFormat: this.playback.streamType,
			videoPlayer: this.videoPlayer,
			videoType: this.live ? VideoType.LIVE : VideoType.VOD,
			zoneId: this.playback.zoneId,
			zoneName: this.playback.zoneName
		});
	}

	private renderLegacyPlayer(playback: any, options: any): void {
		this.legacyPlaybackUrl = playback.url;
		this.showThumbnail = !this.autoPlay;

		//for simulating the basics of the vgAPI
		this.onPlayerReadyInternal({
			currentTime: 0
		} as VideogularComponent, null);
	}

	private renderModernPlayer(playback, options) {
		return Promise.all([
			this.MediaFeatures.getFeatures(this.UserContext.getAccount().id),
			this.VideoPlayerAdapter.getPlayerTranslations(),
			this.VideoPlayerAdapter.convertToModernPlayerPlaybacks(this.videoId, ResourceType.Video, this.playbacks, this.live, this.sourceType, this.is360, undefined, this.disableDualPlayback)
		])
			.then(([mediaFeatures, vbrickPlayerTranslations, modernPlaybacks]) => {
				Object.assign(this, {
					mediaFeatures,
					modernPlaybacks,
					modernSubtitles: this.VideoPlayerAdapter.convertToModernSubtitles(this.subtitles),
					playerConfig: { ...this.playerConfig, ...mediaFeatures.playerSettings },
					vbrickPlayerTranslations
				});
				this.VideoPlayerAdapter.setPlayerStyle(this.element.nativeElement, this.playerConfig?.accentColor);
			});
	}

	private get currentTimeInVideo(): number {
		return this.live ? null : this.vgAPI?.currentTime;
	}

	private renderPlayer(playback: any, options: any): void {
		this.isPlayingLegacy = false;
		this.legacyPlaybackUrl =
			this.modernPlaybacks =
			undefined;

		switch (playback.player) {
			case 'Vbrick':
				this.renderLegacyPlayer(playback, options);
				break;

			default:
				this.renderModernPlayer(playback, options);
		}
	}

	@HostListener('window:beforeunload')
	private tryTerminatePlayback(): void {
		if (this.isPlayingLegacy) {
			this.live ? this.onStopLegacy() : this.onPauseLegacy();
		} else if (this.vgAPI && this.vgAPI.currentState === VgStates.PLAY) {
			this.live ? this.vgAPI.stop() : this.vgAPI.pause();
		}
	}
}
