import { Component, Input } from '@angular/core';

import { ColDefUtil } from '@ag-grid-community/core';

import { VbUiDataGridColumnComponent } from './VbUiDataGridColumn.Component';

@Component({
	selector: 'vb-ui-data-grid-auto-group-column',
	template: ''
})
export class VbUiDataGridAutoGroupColumnComponent extends VbUiDataGridColumnComponent {
	@Input() public autoGroupColumn: boolean;
}

// Silence ag-grid column property validation warnings
ColDefUtil.FRAMEWORK_PROPERTIES.push('autoGroupColumn');
