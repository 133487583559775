import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';

import { SignInBtnComponent } from './SignInBtn.Component';

const components = [
	SignInBtnComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		SecurityAngularModule,
		ButtonsAngularModule,
		TranslateModule
	]
})
export class SignInBtnModule {} // TODO: create downgrade module (used by media toolbar as well)
