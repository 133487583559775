import { AppType } from 'rev-shared/contract/AppType';

/**
 * The BootstrapContext object is static configuration data that is loaded with the page.
 * use UserContextService, or getLocale(), etc.., instead of BootstrapContext.
 */
export interface IBootstrapContext {
	account: IBootstrapAccount;
	analyticsUrl: string;
	app: AppType;
	baseCdnUrl: string;
	bcp47Locale: string;
	ckEditorLocale: string;
	debug: boolean;
	distUrl: string,
	enableEmbedPopupAuth: boolean;
	environmentGoogleAnalyticsEnabled: boolean;
	fullcalendarLocale: string;
	gainsightControlPanelDisabled: boolean;
	gainsightTagKey: string;
	googleAnalyticsTrackingCode: string;
	language: string;
	languagePreferenceOrder: string[];
	momentLocale: string;
	playlistId?: string;
	roles: string[];
	rootHostName: string;
	ssoEnabled: boolean;
	thumbnailUri: string;
	uniqueId?: string;
	user: any;
	userLocation: string;
	userLocationTimeoutMs: number;
	videoId?: string;
	webcastId?: string;
	tokenParam?: string;
	branding?: IBootstrapBranding
	previewMetadata?: IBootstrapPreviewMetadata;
}

export interface IBootstrapAccount {
	id: string;
	name: string;
	language: any;
	isRootAccount: boolean;
	hostname: string;
	googleAnalyticsDisabled: boolean;
	readonlyUserProfile: boolean;
}

export interface IBootstrapBranding {
	headerColor: string;
	headerFontColor: string;
	primaryFontColor: string;
	accentColor: string;
	accentFontColor: string;
	primaryColor: string;
	logoUri: string;
}

export interface IBootstrapPreviewMetadata {
	backgroundUri: string;
	title: string;
	hostName: string;
	description: string;
}

const { vbrickBootstrapContext, vbrickAuxBootstrapContext } = window as any;

if(!vbrickBootstrapContext || !vbrickAuxBootstrapContext) {
	console.error('Missing boostrap context. Unable to continue.');
	throw new Error('missingBootstrapContext');
}

export const BootstrapContext: IBootstrapContext = {
	...vbrickBootstrapContext,
	...vbrickAuxBootstrapContext
};

export const DistUrl = BootstrapContext.distUrl.endsWith('/') ? BootstrapContext.distUrl : `${BootstrapContext.distUrl}/`;
