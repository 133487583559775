import {
	AfterContentInit,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	Input,
	QueryList
} from '@angular/core';

import styles from './vb-ui-form-group.module.less';
import { NgModel, FormControl } from '@angular/forms';
/**
 * This is an angular component. This will be used to layout the label/control/validation message in a form
 * There are three content projection slots.
 * 1. label - label element for the cotrol labal. use #uiLabel template variable on label
 * 2. uiControl - use this attribute on control. Also, use ngModel directive to show required asterisk.
 * 3. uiValidation- use this attribute on validation wrapper.
 * Please check below example.
  *<vb-ui-form-group>
		<label for="selectEvent" #uiLabel>   - provide template variable #uiLabel. Not mandetory.
 			{{ 'Webex_SelectAnEvent' | translate }}
		</label>
		<vb-ui-select uiControl> - provide uiControl attribute
			<select id="selectEvent"
				name="selectEvent"
				[(ngModel)]="selectedEvent"
				required
				(change)="onEventChange()"
				#selectElement
				#selectEvent="ngModel">
				<option [ngValue]="undefined">{{ 'Webex_SelectWebcast' | translate }}</option>
				<option *ngFor="let webcast of webcastList"  [ngValue]="webcast">{{webcast.title}}</option>
			</select>
		</vb-ui-select>
		<div *ngIf="selectEvent.invalid"
			role="alert"
			vbUiErrorMessage
			uiValidation>
			<div *ngIf="selectEvent.errors.required">
				{{ 'ThisFieldIsRequired' | translate }}
			</div>
		</div>
	</vb-ui-form-group>
 * #uiLabel - template variable on label element. it is not mandatory if label element is not there.
 * uiControl - attribute to be provided on control.
 * uiValidation - attribute to be provided on validation div.
 */
@Component({
	selector: 'vb-ui-form-group',
	host: {
		'[class]': 'styles.root'
	},
	template: `
		<div
			*ngIf="labelSlotPresent"
			[ngClass]="[
				styles.label,
				labelClass || '',
				isRequired ? styles.controlRequired : ''
			]">
			<ng-content></ng-content>
		</div>
		<div class="margin-bottom-10"
			[ngClass]="[
				!labelSlotPresent ? 'padding-top-10' : '',
				!labelSlotPresent && isRequired ? styles.controlRequired : ''
			]">
			<ng-content select="[uiControl]"></ng-content>
		</div>
		<div *ngIf="isTouched">
			<ng-content select="[uiValidation]"></ng-content>
		</div>
	`
})
export class VbUiFormGroupComponent implements AfterContentInit {
	@Input() public labelClass: string;
	@Input() public parentNgModel: FormControl;

	@ContentChild('uiLabel') public labelSlot: ElementRef;
	@ContentChild('uiValidation') public validationSlot: ElementRef;
	@ContentChildren(NgModel, { descendants: true }) public controlNgModels: QueryList<NgModel>;

	public labelSlotPresent: boolean;
	public validationSlotPresent: boolean;
	public readonly styles = styles;

	public ngAfterContentInit(): void {
		this.labelSlotPresent = !!this.labelSlot;
		this.validationSlotPresent = !!this.validationSlot;
	}

	public get isTouched(): boolean {
		return this.ngModels.some(ngModel => ngModel.touched);
	}

	public get isDirty(): boolean {
		return this.ngModels.some(ngModel => ngModel.dirty);
	}

	public get isControlInValid(): boolean {
		return this.ngModels.some(ngModel => ngModel.invalid);
	}

	public get hasRequiredField(): boolean {
		return this.ngModels.some(ngModel => {
			const ctrl = { dummy: true };
			const errors = ngModel.validator?.(ctrl as any);
			return errors?.required;
		});
	}

	public get isRequired(): boolean {
		return this.isControlInValid && this.hasRequiredField;
	}

	private get ngModels(): FormControl[] {
		return !this.parentNgModel ? this.controlNgModels as any :
			[...this.controlNgModels, this.parentNgModel];
	}
}
