import { Component, AfterViewInit, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { formatTimespan } from 'rev-shared/date/DateFormatters';

import { IRules } from '../../css/CssRules.Contract';
import { VbQuerySelectorBaseComponent } from '../VbQuerySelectorBase.Component';
import styles from './VbUiVideoPreviewSelect.Component.module.less';

@Component({
	selector: 'vb-ui-video-preview-select',
	templateUrl: './VbUiVideoPreviewSelect.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbUiVideoPreviewSelectComponent),
		multi: true
	}],
})
export class VbUiVideoPreviewSelectComponent extends VbQuerySelectorBaseComponent implements OnInit, AfterViewInit {
	public readonly styles = styles;
	public themeStyleOverrides: IRules;

	public isAllVideos: boolean = false;

	constructor(
		private ThemeService: ThemeService
	) {
		super();
		this.minLength = 0;
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();
		const themeSettings = this.ThemeService.brandingSettings.themeSettings;
		this.themeStyleOverrides = {
			[`.${styles.videoListItem.split(' ')[0]}.active`]: {
				'color': themeSettings.accentFontColor,
				'background-color': themeSettings.accentColor,
				'border-color': themeSettings.accentColor
			},
			[`.${styles.videoInputContainer}:focus-within`]: {
				'box-shadow': `inset 0 0 0 2px ${themeSettings.accentColor}`,
			}
		};
	}

	public formatDuration(durationMs: number): string {
		return formatTimespan(durationMs, {
			noMilliseconds: true,
			padFields: true
		});
	}

	public getSearchParams(): any {
		return {
			isAllVideos: this.isAllVideos
		};
	}
}
