import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[vbMin]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMinDirective),
			multi: true
		}
	]
})
export class VbMinDirective implements Validator {
	@Input() public vbMin: any;

	public validate(control: AbstractControl): ValidationErrors {
		if (this.vbMin == null || control?.value === undefined || control?.value === null) {
			return null;
		}
		return !(control.value < this.vbMin) ? null : { vbMin: true };
	}
}
