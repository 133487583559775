import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TagifyModule } from 'ngx-tagify';
import { TranslateModule } from '@ngx-translate/core';

import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';

import { VbCategoriesTagsInputComponent } from './VbCategoriesTagsInput.Component';
import { VbLanguagesTagsInputComponent } from './VbLanguagesTagsInput.Component';
import { VbTagsInputComponent } from './VbTagsInput.Component';
import { VbTeamsTagsInputComponent } from './VbTeamsTagsInput.Component';

import '@yaireo/tagify/dist/tagify.polyfills.min.js';

const declarations = [
	VbTagsInputComponent,
	VbCategoriesTagsInputComponent,
	VbLanguagesTagsInputComponent,
	VbTeamsTagsInputComponent
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		CommonModule,
		CssRulesAngularModule,
		FormsModule,
		ReactiveFormsModule,
		TagifyModule,
		TranslateModule
	]
})
export class VbTagsInputModule {}
