import { Injectable } from '@angular/core';

import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';
import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UploadService {
	private videoImportStatuses: Map<string, any[]> = new Map<string, any[]>();

	private readonly accountId: string;
	constructor(
		UserContext: UserContextService,

		private http: HttpClient,
		private PushService: PushService
	) {
		this.accountId = UserContext.getAccount().id;
	}

	public cancelUpload(videoId: string) {
		return this.PushService.dispatchCommand('media:CancelUploadingVideo', { videoId })
			.then(
				() =>	console.log('Upload Canceled'),
				err => console.log('Upload cancel failed: ', err)
			);
	}

	public createImportStatusEntry(title: string, teamId?: string) {
		const importKey: string = teamId || this.accountId;
		let importVal = [];
		const entry = {
			title,
			uploadDate: null,
			id: null,
			status: {
				error: false,
				complete: false
			}
		};

		if (this.videoImportStatuses.has(importKey)) {
			importVal = this.videoImportStatuses.get(importKey);
		}
		importVal.push(entry);
		this.videoImportStatuses.set(importKey, importVal);

		return entry;
	}

	public createVideo(video: { source: string; title: string; name: string; file: any }, teamId?: string) {
		return this.PushService.dispatchCommand('media:AddVideoToAccount', {
			title: video.title,
			videoSource: video.source,
			size: video.file.size,
			name: video.name,
			teamId
		},
		'VideoCreated')
			.then(result => {
				const event = result.message;

				return {
					id: event.videoId,
					videoUploadUri: event.videoUploadUri
				};
			});
	}

	public createVideoByPresentationProfile(presentationProfile: { id: string; name: string }, teamId?: string) {
		return this.PushService.dispatchCommand('media:CreateVideoPresentationProfile', {
			presentationProfileId: presentationProfile.id,
			profileName: presentationProfile.name,
			teamId
		}, 'VideoPresentationProfileCreated')
			.then(result => result.message.videoId);
	}

	public createVideoLink(video: { encodingType: string; type: string; url: string }, teamId?: string) {
		return this.PushService.dispatchCommand('media:CreateVideoLink', {
			url: video.url,
			type: video.type,
			encodingType: video.encodingType,
			teamId
		}, 'VideoLinkCreated')
			.then(result => result.message.videoId);
	}

	public dismissAllImportStatuses(teamId?: string): void {
		const importKey: string = teamId || this.accountId;
		this.videoImportStatuses.delete(importKey);
	}

	public fetchPresentationProfiles(accountId: string, teamId: string = null): Promise<any> {
		return lastValueFrom(this.http.get(`/media/accounts/${accountId}/presentation-profiles`));
	}

	public getImportStatuses(teamId?: string): any[] {
		const importKey: string = teamId || this.accountId;
		return this.videoImportStatuses.get(importKey) ?? [];
	}

	public waitUntilCreated(videoId: string) {
		if (!videoId) {
			throw new Error('VideoId required');
		}

		return retryUntilSuccess(
			() => lastValueFrom(this.http.get(`/media/videos/${videoId}/created`)),
			undefined,
			result => result.success === true
		);
	}
}
