import { Injectable } from '@angular/core';

import { DASHBOARD_STATE } from './AppStateNames';
import { IRevDisplayContext, getRevDisplayContext } from '../contract/RevDisplayContext';
import { ModuleType } from 'rev-shared/security/LicenseType';

@Injectable({
	providedIn: 'root'
})

export class AppBootstrapService {
	private landingState = DASHBOARD_STATE;
	private landingStateParams;

	public revDisplayContext: IRevDisplayContext;
	public appConfig: any;

	constructor(
	) {
		this.loadRevDisplayContext(ModuleType.FullRev);
	}


	public setAppLandingState(state: string, params?: any) {
		this.landingState = state;
		this.landingStateParams = params;
	}

	public getAppLandingStateName(): string {
		return this.landingState;
	}

	public getAppLandingState(): { state: string, params: any} {
		return {
			state: this.landingState,
			params: this.landingStateParams ?? undefined
		};
	}

	public loadRevDisplayContext(revType: ModuleType): void {
		this.revDisplayContext = this.getRevDisplayContext(revType);
	}

	public getRevDisplayContext(revType: ModuleType): IRevDisplayContext {
		return getRevDisplayContext(revType);

	}

	public setAppConfig(config: any) {
		this.appConfig = config;
	}
}
