<form name="form" (submit)="submit()" #subscribeSparkCategoryForm="ngForm" class="form-horizontal">
	<div class="modal-header">
		<h4>
			{{ 'Media_Videos_Sharing_Spark_SelectRoom' | translate }}
			<button class="close pull-right btn btn-transparent" type="button" (click)="close()" [attr.aria-label]="'Close' | translate ">
				<span class="glyphicons remove_2"></span>
			</button>
		</h4>
	</div>
	<div class="modal-body">
		<vb-loading-spinner [hidden]="!status.processing" [size]="'large'"></vb-loading-spinner>

		<div class="centered-copy" [hidden]="!status.saveError">
			<div class="alert alert-danger" role="alert">
				<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'Media_Videos_Sharing_Spark_SaveError' | translate }}
			</div>
		</div>

		<div class="centered-copy" [hidden]="!status.loadRoomsError">
			<div class="alert alert-danger" role="alert">
				<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'Media_Videos_Sharing_Spark_LoadError' | translate }}
			</div>
		</div>

		<div [hidden]="!status.active">

			<div class="form-group required">
				<div class="col-sm-12">
					<select id="roomId" name="roomId" required class="form-control" [(ngModel)]="roomId" [hidden]="status.sparkAuthenticationRequired || !rooms?.length" [attr.aria-label]="'Media_Videos_Sharing_Spark_RoomSelection' | translate " uiControl>
						<option *ngFor="let room of rooms" [ngValue]="room.id">
							{{ room.title }}
						</option>
					</select>

					<p class="help-block" [hidden]="!sparkAuthenticationRequired">
						{{ 'Media_Videos_Sharing_Spark_LogIn' | translate }}
						<a href="/spark/oauth/authorize" target="_blank">
							{{ 'Media_Videos_Sharing_Spark_LogInLink' | translate }}
						</a>
					</p>

					<p class="help-block" [hidden]="rooms?.length">
						{{ 'Media_Videos_Sharing_Spark_NoRooms' | translate }}
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer" [hidden]="!status.active">
		<button type="button" class="btn btn-admin btn-white" (click)="close()">{{ 'Cancel' | translate }}</button>
		<button type="submit" class="btn btn-primary" [disabled]="subscribeSparkCategoryForm.invalid">{{ 'Save' | translate }}</button>
	</div>

	<div class="modal-footer" [hidden]="!status.error">
		<button type="button" class="btn btn-primary" (click)="close()">{{ 'Ok' | translate }}</button>
	</div>
</form>
