import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

import styles from './CheckboxCellRenderer.Component.module.less';

export interface ICheckboxCellRendererParams {
	enabled?: boolean;
	themed?: boolean;
}

interface ICheckboxCellRendererMergedParams extends ICellRendererParams, ICheckboxCellRendererParams {}

@Component({
	selector: 'checkbox-cell-renderer',
	templateUrl: './CheckboxCellRenderer.Component.html',
	host: {
		'[class]': '(themed ? styles.themedHost : "")'
	}
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
	public isChecked: boolean;
	public isDisabled: boolean;
	public themed: boolean;
	public readonly styles = styles;

	public agInit(params: ICheckboxCellRendererMergedParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICheckboxCellRendererMergedParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICheckboxCellRendererMergedParams): void {
		this.isChecked = params.valueFormatted ?? !!params.value;
		this.isDisabled = !params.enabled;
		this.themed = params.themed;
	}
}
