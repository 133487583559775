import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CarouselType } from 'rev-portal/branding/BrandingSettings.Contract';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { BrandingService } from './Branding.Service';
import { BrandingSettings } from './BrandingSettings.Contract';
import { setOpacity } from './ColorUtility';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	private settings: BrandingSettings;
	private customThemeSettings: BrandingSettings;

	public isPreviewActive: boolean;
	public isCustomThemeActive: boolean;
	public initialized: boolean;
	public initializationPromise: Promise<void>;

	public get brandingSettings(): BrandingSettings {
		return (this.isCustomThemeActive || this.isPreviewActive) ?
			this.customThemeSettings :
			this.settings;
	}

	public brandingSettingsSubject$ = new BehaviorSubject<BrandingSettings>(undefined);
	public brandingSettings$: Observable<BrandingSettings> = this.brandingSettingsSubject$.asObservable();

	public get accentFontColor(): string {
		return this.brandingSettings.themeSettings.accentFontColor;
	}

	public get accentColor(): string {
		return this.brandingSettings.themeSettings.accentColor;
	}

	public get headerFontColor(): string {
		return this.brandingSettings.headerSettings.fontColor;
	}

	public get headerBackgroundColor(): string {
		return this.brandingSettings.headerSettings.backgroundColor;
	}

	public get primaryColor(): string {
		return this.brandingSettings.themeSettings.primaryColor;
	}

	public get primaryFontColor(): string {
		return this.brandingSettings.themeSettings.primaryFontColor;
	}

	public get primaryFontFade30(): string {
		return setOpacity(this.primaryFontColor, .3);
	}

	constructor(
		private BrandingService: BrandingService,
		private UserContext: UserContextService,
		private PushBus: PushBus
	){
		this.settings = new BrandingSettings();

		UserContext.userIdChanged$.pipe(
			filter(() => !UserContext.isUserLoggedIn())
		)
			.subscribe(() => this.endCustomThemeMode());
	}

	public initialize(): Promise<void> {
		if(this.initializationPromise) {
			return this.initializationPromise;
		}
		return this.initializationPromise = this.loadSettings()
			.then(() => {
				this.initialized = true;
				return this.subscribePush();
			});
	}

	public loadSettings(): Promise<void> {
		return this.BrandingService.getAppBranding()
			.then(brandingSettings => {
				this.settings = brandingSettings;
				this.brandingSettingsSubject$.next(this.brandingSettings);
			});
	}

	public setPreviewMode(brandingSettings: BrandingSettings): void {
		this.setCustomThemeMode(brandingSettings);
	}

	public endPreviewMode(): BrandingSettings {
		return this.endCustomThemeMode();
	}

	public setCustomThemeMode(brandingSettings: BrandingSettings, partialThemeChange?: boolean): void {
		let settings: BrandingSettings;
		if (partialThemeChange) {
			this.isCustomThemeActive = true;
			const baseSettings = this.brandingSettingsSubject$.value;
			settings = {
				...baseSettings,
				themeSettings: {
					accentColor: brandingSettings.themeSettings.accentColor || baseSettings.themeSettings.accentColor,
					accentFontColor: brandingSettings.themeSettings.accentFontColor || baseSettings.themeSettings.accentFontColor,
					primaryColor: brandingSettings.themeSettings.primaryColor || baseSettings.themeSettings.primaryColor,
					primaryFontColor: brandingSettings.themeSettings.primaryFontColor || baseSettings.themeSettings.primaryFontColor,
					fontId: brandingSettings.themeSettings.fontId || baseSettings.themeSettings.fontId,
					fontUri: brandingSettings.themeSettings.fontUri || baseSettings.themeSettings.fontUri,
					logoFile: brandingSettings.themeSettings.logoFile || baseSettings.themeSettings.logoFile,
					logoImageId: brandingSettings.themeSettings.logoImageId || baseSettings.themeSettings.logoImageId,
					logoUri: brandingSettings.themeSettings.logoUri || baseSettings.themeSettings.logoUri
				},
				headerSettings: {
					...baseSettings.headerSettings,
					backgroundColor: brandingSettings.headerSettings.backgroundColor || baseSettings.headerSettings.backgroundColor,
					fontColor: brandingSettings.headerSettings.fontColor || baseSettings.headerSettings.fontColor
				}
			};
		} else {
			this.isPreviewActive = true;
			settings = brandingSettings;
		}
		this.customThemeSettings = settings;
		this.brandingSettingsSubject$.next(this.brandingSettings);
	}

	public endCustomThemeMode(): BrandingSettings {
		const result = this.customThemeSettings;
		this.isCustomThemeActive = false;
		this.isPreviewActive = false;
		this.customThemeSettings = null;
		this.brandingSettingsSubject$.next(this.brandingSettings);
		return result;
	}

	private subscribePush(): void {
		this.PushBus.subscribe(this.UserContext.getAccount().id, {
			BrandingSettingsSaved: e => {
				if (e.isTeamVideosCarouselAvailable) {
					this.loadSettings();
				}
				else {
					this.settings = this.BrandingService.readBrandingSettings(e.settings);
					this.brandingSettingsSubject$.next(this.brandingSettings);
				}
			},
			CarouselsRemoved: e => {
				e.carousels.forEach((removedCarousel: CarouselType) => {
					this.brandingSettings.homepageSettings.carousels.splice(this.brandingSettings.homepageSettings.carousels.findIndex(c => c.type === removedCarousel), 1);
				});
				this.brandingSettingsSubject$.next(this.brandingSettings);
			}
		});
	}
}
