import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { VbNavigationHandlerDirective } from 'rev-shared/uiRouterUtils/VbNavigationHandler.Directive';
import { VbUiSrefToggleDirective } from 'rev-shared/uiRouterUtils/VbUiSrefToggle.Directive';
import { VbUiSrefActiveDirective } from 'rev-shared/uiRouterUtils/VbUiSrefActive.Directive';

import { AppUtilService } from './AppUtil.Service';
import { LanguageCodes } from './LanguageCodes.Service';

const declarations = [
	VbNavigationHandlerDirective,
	VbUiSrefToggleDirective,
	VbUiSrefActiveDirective
];

@NgModule({
	declarations,
	exports: declarations,

	imports: [
		HttpClientModule
	],
	providers: [
		AppUtilService,
		LanguageCodes
	]
})
export class UtilAngularModule {}
