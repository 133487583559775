import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import styles from './HighlightCellRenderer.module.less';

@Component({
	selector: 'highlight-cell-renderer',
	template:`
		<span *ngIf="highlightMatch; else highlight"
			[innerHtml]="textVal | highlight : highlightMatch : styles.highlight">
		</span>
		<ng-template #highlight>
			<span>{{textVal}}</span>
		</ng-template>
	`
})
export class HighlightCellRendererComponent implements ICellRendererAngularComp {
	public textVal: string;
	public highlightMatch: string;

	public readonly styles = styles;

	public agInit(params: any): void {
		this.updateCell(params);
	}

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);

		return true; // nothing to do on refresh, so signal success
	}

	private updateCell(params: ICellRendererParams): void {
		this.textVal = params.value;
		this.highlightMatch = getFilterText(params);
	}
}


export function getFilterText(params: ICellRendererParams): string {
	return params.context.getQuickFilterText();
}
