import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagData } from 'ngx-tagify';

import { autoCompleteSettings, TEMPLATE, VbTagsInputComponent } from './VbTagsInput.Component';

import styles from './VbTagsInput.Component.module.less';

export interface ITeamSettings {
	id: string;
	name: string;
}

@Component({
	selector: 'vb-teams-tags-input',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbTeamsTagsInputComponent),
			multi: true
		}
	],
	template: TEMPLATE
})
export class VbTeamsTagsInputComponent extends VbTagsInputComponent {
	@Input() public teams: ITeamSettings[];
	@Input() public maxTags: number;

	public ngOnInit(): void {
		super.ngOnInit();
		this.settings = {
			...this.settings,
			...autoCompleteSettings,
			whitelist: this.teams.map(team => ({ ...team, value: team.name })), //Todo: explore more on tagTextProp
			maxTags: this.maxTags ?? Infinity,
			dropdown: {
				...autoCompleteSettings.dropdown,
				closeOnSelect: this.maxTags === 1
			},
			templates: {
				tag(tagData: TagData){
					return `
						<tag title="${(tagData.title || tagData.value)}"
							contenteditable='false'
							spellcheck='false'
							tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
							class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ''}"
							${this.getAttributes(tagData)}>
								<x title='' class="${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></x>
								<div class="${styles.tag}">
									<span title="${tagData.value}">${tagData.value}</span>
								</div>
						</tag>
					`;
				},
				dropdownItem(item: TagData) {
					return `
						<div class="${styles.dropdownItem} ${this.settings.classNames.dropdownItem}"
							tabindex="0"  role="option">
							<span title="${item.value}">${item.value}</span>
						</div>
					`;
				}
			}
		};
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public onTagsChange($event: any[]): void {
		const tags = $event?.map(item => ({ name: item.name, teamId: item.teamId }));
		this.notifyChange(tags);
	}
}
