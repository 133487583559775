import { HttpXsrfCookieExtractor } from 'rev-node-modules/@angular/common/esm2020/http/src/xsrf.mjs';
import { Inject, Injectable, PLATFORM_ID, Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpXsrfTokenExtractor } from '@angular/common/http';

import { getXsrfCookieName } from 'rev-shared/security/Tokens';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

/**
 * Extends the default HttpXsrfCookieExtractor in order to facilitate a dynamic cookie name.
 * Normally this is a static string injectable. However, ours is determined on the fly.
 * This makes it consistent with the AngularJS side.
 */
@Injectable()
export class VbXsrfCookieExtractor extends HttpXsrfCookieExtractor {
	public cookieName: string;

	constructor(
		@Inject(DOCUMENT) private doc: any,
		@Inject(PLATFORM_ID) private platform: string,
		private userContext: UserContextService
	) {
		super(doc, platform, getXsrfCookieName());

		this.userContext.userAuthenticated$.subscribe(() => this.cookieName = getXsrfCookieName());
	}
}

export const VbXsrfCookieExtractorProvider: Provider = {
	provide: HttpXsrfTokenExtractor,
	useClass: VbXsrfCookieExtractor
};
