<ng-container *ngFor="let buttonDef of buttons">
	<button *ngIf="!buttonDef.onlyIconDisplay; else onlyIcon" vbUiBtnSecondary class="margin-right-5" type="button" (click)="onClick(buttonDef)">
		<span [ngClass]="buttonDef.iconClass"></span>
		{{ buttonDef.label }}
	</button>

	<ng-template #onlyIcon>
		<a role="button" href="javascript:" (click)="onClick(buttonDef)">
			<span [ngClass]="buttonDef.iconClass"></span>
		</a>
	</ng-template>
</ng-container>
