import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { AlertDialogAngularComponent } from './AlertDialogAngular.Component';
import { ConfirmationDialogAngularComponent } from './ConfirmationDialogAngular.Component';
import { IConfirmationDialogParams } from './IConfirmationDialogParams';

export interface IDialogServiceOptions extends ModalOptions<any> {
	component?: any;
	resolve?: any;
	modalService?: BsModalService; //Only Needed if modal is lazy-loaded and accessed from angularJs
}

const baseModalCfg: IDialogServiceOptions = {
	keyboard: true,
	ignoreBackdropClick: false
};

@Injectable({
	providedIn: 'root'
})
export class DialogService {
	public static configs: { [key: string]: IDialogServiceOptions } = {};

	constructor(
		private modalService: BsModalService
	) {}

	public static register(dialogName: string, config: IDialogServiceOptions): void {
		if (DialogService.configs[dialogName]) {
			//TODO - Need to discuss. recent lazy load approach is breaking it.
			console.error('Dialog ' + dialogName + ' already in use');
			return;
		}

		DialogService.configs[dialogName] = config;
	}

	public getDialog<T>(name: string) {
		const config: any = DialogService.configs[name];

		if (!config) {
			throw new Error('Unknown Dialog Type: ' + name);
		}
		const modalService = config.modalService || this.modalService;

		return {
			open: (options: IDialogServiceOptions = {}): BsModalRef<T> =>
				modalService.show(config.component, {
					...baseModalCfg,
					...config,
					...options
				})
		};
	}

	public openConfirmationDialog(params: IConfirmationDialogParams): ConfirmationDialogAngularComponent {
		return this.getDialog<ConfirmationDialogAngularComponent>('ConfirmationDialog')
			.open({
				initialState: {
					params
				}
			})
			.content;
	}

	public openAlertDialog(title: string, message: string, okButtonText: string): AlertDialogAngularComponent {
		return this.getDialog<AlertDialogAngularComponent>('AlertDialog')
			.open({
				initialState: {
					title,
					message,
					okButtonText
				}
			})
			.content;
	}
}
