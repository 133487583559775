import { NgModule } from '@angular/core';

import { NgxBoostrapTabOrderDirective } from './NgxBootstrapTabOrderDirective';
import { VbAutoDropupDirective } from './VbAutoDropup.Directive';
import { VbBulkIpInputDirective } from './VbBulkIpInputAngular.Directive';
import { VbImageRetryDirective } from './VbImageRetryAngular.Directive';
import { VbLoadingBarComponent } from './VbLoadingBar.Component';
import { VbRestrictTypeaheadEditableDirective } from './VbRestrictTypeaheadEditable.Directive';
import { VbScheduleChangeDetectionDirective } from './VbScheduleChangeDetection.Directive';
import { VbScrollItemXDirective } from './VbScrollItemX.Directive';
import { VbSelectOnClickDirective } from './VbSelectOnClickAngular.Directive';
import { VbTitleComponent } from './VbTitle.Component';
import { VbTitleService } from './VbTitle.Service';
import { VbUiStretchDirective } from './VbUiStretch.Directive';
import { VbUiTailAngularDirective } from './VbTailAngular.Directive';
import { VbVisibleDirective } from './VbVisibleAngular.Directive';
import { VbWatchDimensionsDirective } from './VbWatchDimensions.Directive';

const entryComponents = [
	VbLoadingBarComponent,
	VbTitleComponent
];

const directives = [
	NgxBoostrapTabOrderDirective,
	VbAutoDropupDirective,
	VbBulkIpInputDirective,
	VbImageRetryDirective,
	VbRestrictTypeaheadEditableDirective,
	VbScheduleChangeDetectionDirective,
	VbScrollItemXDirective,
	VbSelectOnClickDirective,
	VbUiStretchDirective,
	VbUiTailAngularDirective,
	VbVisibleDirective,
	VbWatchDimensionsDirective
];

@NgModule({
	declarations: [...entryComponents, ...directives],
	entryComponents,
	exports: [...entryComponents, ...directives],
	providers: [
		VbTitleService
	]
})
export class DirectivesAngularModule {}
